import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import CryptoJS from 'crypto-js'; // Ensure you have crypto-js installed
import '../css/WalletDetails.css';
import { ConfigContext } from '../config'; // import config.FX_BSV from config
import { dynamicApiCall } from '../services/apiOutCalls';
import OverlayConfirm from './OverlayConfirm';
import UTXOSelectorOverlay from './UTXOSelectorOverlay';
import { initGlobalSignaler } from '../services/globalSignaler';
import ReceiveQRCodeOverlay from './ReceiveQRCodeOverlay';
import { AuthContext } from '../context/AuthProvider'; // added import

const WalletDetails = ({ wallet, onShowHoldings }) => {
  const config = useContext(ConfigContext);
  const [showQR, setShowQR] = useState(false);
  const [balance, setBalance] = useState(0);
  const [pendingBalance, setPendingBalance] = useState(0);
  const [showBackupConfirm, setShowBackupConfirm] = useState(false);

  const [showUtxoOverlay, setShowUtxoOverlay] = useState(false);
  const [showQROverlay, setShowQROverlay] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (wallet && wallet.address) {
      dynamicApiCall(wallet, { action: "getWalletBalance", address: wallet.address })
        .then(result => {
          if (result.success && result.data && result.data.confirmed !== undefined) {
            setBalance(result.data.confirmed);
            setPendingBalance(result.data.unconfirmed);
          }
        })
        .catch(err => console.error("Error fetching balance:", err));
    }
  }, [wallet]);

  const downloadBackup = (passphrase) => {
    if (wallet) {
      const fileData = `Private Key: ${wallet.privateKey}\nPublic Key: ${wallet.pubKeyString}\nAddress: ${wallet.address}`;
      let outputData = fileData;
      if (passphrase && passphrase.trim() !== "") {
        outputData = CryptoJS.AES.encrypt(fileData, passphrase).toString();
      }
      const blob = new Blob([outputData], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'keys_backup_'+wallet.address+'.txt';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert('No backup information available.');
    }
  };

  const onBackup = () => {
    setShowBackupConfirm(true);
  };

  const handleBackupConfirmComplete = ({ status, values }) => {
    setShowBackupConfirm(false);
    if (status && values && values[0]?.trim() !== "") {
      const passphrase = values[0];
      downloadBackup(passphrase);
    }
  };


  const handleThumbnailClick = () => {
    setShowQROverlay(true);
  };

  const closeOverlay = () => {
    setShowQROverlay(false);
  };

  return (
    <div className="wallet-details card p-0 shadow-sm">
      <div className="card-header">
        <b>Address:</b>{" "}
        {wallet && wallet.address
          ? wallet.address.substring(0, 6) + "...." + wallet.address.substring(wallet.address.length - 6)
          : ""}
      </div>
      
      <div className="card-body wallet-card-container">
        <div className="left-column">
          <div
            onClick={handleThumbnailClick}
            style={{ cursor: 'pointer',textAlign: 'center' }}
          >
            <QRCodeCanvas value={'Click me to view Address'} size={100} />
          </div>
          <button  className="action-button logout-button left-logout btn btn-sm btn-light" onClick={onBackup}>
              Backup
          </button>

        </div>
        <div className="right-column">
        <div className="details-section">
  {config.FX_BSV > 0 ? (
    (() => {
      const converted = balance / 100000000;
      const dollarValue = converted * config.FX_BSV;
      const formatted = converted.toFixed(4);
      const formattedUSD = dollarValue.toFixed(4);
      const balanceDisplay = formatted.endsWith("0000")
        ? `${balance} satoshis`
        : `$BSV ${formatted}`;
      const usdBalanceDisplay = formattedUSD.endsWith("0000")
        ? `$US ${formattedUSD}`
        : `$US ${formattedUSD}`;
      return (
        <div>
          Balance:{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowUtxoOverlay(true);
            }}
          >
            {balanceDisplay}
          </a>
          <br />
          {/* New Incoming balance display */}
          Incoming: {pendingBalance} satoshis
          <br />
          {usdBalanceDisplay} @ ${config.FX_BSV}/BSV
        </div>
      );
    })()
  ) : (
    <div
      className="alert alert-light p-2"
      style={{ fontWeight: "bold", fontSize: "0.75rem" }}
    >
      Unable to retrieve BSV conversion rate. If the problem persists, please contact support.
    </div>
  )}
</div>
          <div className="action-buttons">
            
            <button className="btn btn-primary" onClick={onShowHoldings}>
              Token Holdings
            </button>
          </div>
        </div>
      </div>
      {showQR && (
        <div className="qr-overlay" onClick={() => setShowQR(false)}>
          <div className="qr-container" onClick={(e) => e.stopPropagation()}>
            <button className="close-overlay" onClick={() => setShowQR(false)}>×</button>
            <QRCodeCanvas value={wallet.address} size={200} includeMargin={false} />
          </div>
        </div>
      )}
      {showBackupConfirm && (
        <OverlayConfirm 
          confirmation={{
            messages: ["Enter a passphrase to encrypt and download your backup. Make sure to also copy the key and save to a safe place as a second backup."],
            requiresInput: true,
            highlights: [wallet.privateKey]
          }}
          optionsMsg={{ confirm: "Encrypt & Backup", cancel: "Cancel" }}
          onComplete={handleBackupConfirmComplete}
        />
      )}

      {showUtxoOverlay && (
        <UTXOSelectorOverlay 
          wallet={wallet}
          onClose={() => setShowUtxoOverlay(false)}
          onSelectionComplete={() => setShowUtxoOverlay(false)}
          showOnly={true}
        />
      )}
      {showQROverlay && (
        <ReceiveQRCodeOverlay walletAddress={wallet.address} onClose={closeOverlay} />
      )}
    </div>
    
  );
};

export default WalletDetails;
