import React, { useEffect, useContext, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from './context/AuthProvider';
import { initGlobalSignaler } from './services/globalSignaler';
import Authenticate from './pages/Authenticate';
import Profile from './pages/Profile';
import Visitor from './pages/Visitor';
import TransferPage from './pages/TransferPage';
import MintSTAS789 from './pages/MintSTAS789';
import TransferNFT from './pages/TransferNFT';
import Transactions from './pages/Transactions';
import Home from './pages/Home';
import Conversations from './pages/Conversations';
import Conversation from './pages/Conversation';

import Navigation from './components/Navigation';
import Footer from './components/Footer';
import ScrollToTop from './ScrollToTop';
import PostDetailsPage from './pages/PostDetailsPage';

import Developers from './pages-static/Developers';
import FAQ from './pages-static/FAQ';
import ToS from './pages-static/ToS';
import Privacy from './pages-static/Privacy';
import Donate from './pages-static/Donate';

import DevAppSubmit from './pages-apps/DevAppSubmit';
import Apps from './pages-apps/Apps';
import AdminApps from './pages-apps/AdminApps';
import AppDetails from './pages-apps/AppDetails';
import DeveloperApps from './pages-apps/DeveloperApps';
import EditApp from './pages-apps/EditApp';
import OverlayConfirm from './components/OverlayConfirm'; // NEW import for connection confirmations

const App = () => {
  const { wallet } = useContext(AuthContext);
  const [pendingConnectionRequest, setPendingConnectionRequest] = useState(null);
  const [pendingConnectionRequestUsername, setPendingConnectionRequestUsername] = useState(null);

  useEffect(() => {
    if (wallet) {
      const globalSignaler = initGlobalSignaler(wallet);
      window.globalSignaler = globalSignaler;
      
      // Listen for incoming P2P connection requests.
      const handleConnectionRequest = (e) => {
        setPendingConnectionRequest(e.detail.from);
        setPendingConnectionRequestUsername(e.detail.username);
      };
      globalSignaler.addEventListener('p2pConnectionRequest', handleConnectionRequest);

      const interval = setInterval(() => {
        globalSignaler.reattemptPendingMessages();
      }, 10000);
      return () => {
        clearInterval(interval);
        globalSignaler.removeEventListener('p2pConnectionRequest', handleConnectionRequest);
      };
    }
  }, [wallet]);

  // When the user confirms or rejects a connection request.
  const handleP2PConfirmation = ({ status }) => {
    if (status && pendingConnectionRequest) {
      // Approve the connection using the address from the pending request
      window.globalSignaler.approveConnection(pendingConnectionRequest);
    }
    setPendingConnectionRequest(null);
    setPendingConnectionRequestUsername(null);
  };

  return (
    <>
      <Navigation />
      <ScrollToTop />
      <div className="main-container" style={{ marginBottom: '45px' }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/auth" element={<Authenticate />} />
          <Route path="/profile/:profile" element={<Visitor />} />
          <Route path="/post/:postid" element={<PostDetailsPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/pay" element={<TransferPage />} />
          <Route path="/create" element={<MintSTAS789 />} />
          <Route path="/transfer/:tokenId/:symbol" element={<TransferNFT />} />
          <Route path="/conversations" element={<Conversations />} />
          <Route path="/message/:address" element={<Conversation />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/tos" element={<ToS />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/dev-apps/manage" element={<DeveloperApps />} />
          <Route path="/dev-apps/edit/:appId" element={<EditApp />} />
          <Route path="/dev-apps/submit" element={<DevAppSubmit />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/apps/:appId" element={<AppDetails />} />
          <Route path="/admin-apps" element={<AdminApps />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Footer />
      
      {/* Render OverlayConfirm when a connection request is pending */}
      {pendingConnectionRequest && (
        <OverlayConfirm
          confirmation={{
            messages: [
              `You have an incoming P2P connection request from ${pendingConnectionRequestUsername ? ' @' + pendingConnectionRequestUsername : pendingConnectionRequest + '. The user does not have a username on the platform.'}. Only accept connections from trusted users. A P2P connection inherently reveals your IP address. Accept connection?`
            ]
          }}
          optionsMsg={{ confirm: "Accept", cancel: "Reject" }}
          onComplete={handleP2PConfirmation}
        />
      )}
    </>
  );
};

export default App;
