import React, { useState } from 'react';
import '../css/OverlayConfirm.css';

const OverlayConfirm = ({ confirmation, optionsMsg, onComplete }) => {
  // confirmation: { messages: string[], requiresInput: boolean, highlights?: string[] }
  const { messages, requiresInput, highlights } = confirmation;
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState(Array(messages.length).fill(""));

  const handleInputChange = (e) => {
    const newAnswers = [...answers];
    newAnswers[currentStep] = e.target.value;
    setAnswers(newAnswers);
  };

  const handleConfirm = () => {
    if (requiresInput && (!answers[currentStep] || answers[currentStep].trim() === "")) return;
    if (currentStep < messages.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onComplete({ status: true, values: requiresInput ? answers : [] });
    }
  };

  const handleCancel = () => {
    onComplete({ status: false, values: [] });
  };

  return (
    <div className="overlay-confirm">
      <div className="overlay-confirm-content">
        <p className="overlay-prompt">{messages[currentStep]}</p>
        {highlights && typeof highlights[currentStep] === "string" && (
          <p className="overlay-highlight">{highlights[currentStep]}</p>
        )}
        {requiresInput && (
          <input
            type="text"
            value={answers[currentStep]}
            onChange={handleInputChange}
            placeholder="Enter value..."
            style={{ marginBottom: "20px", padding: "7px", width: "100%" }}
          />
        )}
        <div className="overlay-confirm-buttons">
          <button className="btn btn-outline-dark" onClick={handleConfirm}>
            {optionsMsg?.confirm || "Confirm"}
          </button>
          <button className="btn btn-outline-dark" onClick={handleCancel}>
            {optionsMsg?.cancel || "Cancel"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OverlayConfirm;
