import React, { useState, useEffect, useRef } from 'react';
import { searchPosts } from '../services/apiOutCalls';
import NFTCard from '../components/NFTCard';
import '../css/Stas789NFTs.css';
import { P2PKH } from '@bsv/sdk';
import {getPKHash} from '../utils/mintAllocations';

// Predefined list of tags available for search.
const availableTags = [
  "Opinion",
  "News",
  "Art",
  "Music",
  "Travel",
  "Podcast",
  "Technology",
  "Science",
  "Cosmos",
  "History",
  "Politics",
  "Sports",
  "Gaming"
];

const Stas789NFT = ({ wallet, ownProfile, profile }) => {  // Component uses prop ownProfile

  const [posts, setPosts] = useState([]);
  const [searchTags, setSearchTags] = useState([]);
  const [searchTagInput, setSearchTagInput] = useState('');
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);
  const searchInputRef = useRef(null);

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [total, setTotal] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [showPagination, setShowPagination] = useState(false);

  // Effect for when searchTags change (new search)
  useEffect(() => {

    setPage(1);
    setShowPagination(false);
    fetchPosts(1, limit, searchTags).then(() => setShowPagination(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(searchTags)]);

  // Effect for page changes (not due to searchTags change)
  useEffect(() => {
    if (hasSearched) {
      fetchPosts(page, limit, searchTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);
  
  const handleInvitePost = () => {
    // Dispatch an event named `openPostModal`
    window.dispatchEvent(new CustomEvent('openPostModal'));
  };

  const fetchPosts = async (currentPage, currentLimit, tags) => {
    if (!wallet) {
      setErrorMessage("No account found. Please log in to view posts.");
      return;
    }
    // Combine tags into a single query string.
    const fullQuery = tags.join(" ");
    try {
      setErrorMessage(""); // Clear previous error
      setLoading(true);
      const result = await searchPosts(wallet, {search: fullQuery, owner: getPKHash(new P2PKH().lock(profile||wallet.address).toHex())}, currentPage, currentLimit);
      setPosts(result.posts);
      setPage(result.page);
      setTotal(result.total);
      setLoading(false);
      setHasSearched(true);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setErrorMessage(error.message || "Failed to fetch posts. Please try again later.");
      setPosts([]);
      setTotal(0);
      setLoading(false);
      setHasSearched(true);
      setShowPagination(true);
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(total / limit);

  const handleNext = () => {
    if (page < totalPages) {
      const newPage = page + 1;
      setPage(newPage);
      fetchPosts(newPage, limit, searchTags);
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      const newPage = page - 1;
      setPage(newPage);
      fetchPosts(newPage, limit, searchTags);
    }
  };

  if (!wallet) {
    return null; // Or render an alternative component
  }

  return (
    <div className="">
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      {/* Styled Search Box with tags */}
      <div className="card bg-light mb-4 shadow-sm">
        <div className="card-body">
          <div
            className="form-control d-flex flex-wrap align-items-center"
            style={{ minHeight: "38px", cursor: "text", position: "relative" }}
            onClick={() => searchInputRef.current && searchInputRef.current.focus()}
          >
            {searchTags.map((tag, idx) => (
              <span key={idx} className="badge bg-primary me-1">
                {tag}
                <button
                  type="button"
                  className="btn-close btn-close-white btn-sm ms-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSearchTags(searchTags.filter((_, i) => i !== idx));
                  }}
                ></button>
              </span>
            ))}
            <input
              type="text"
              className="border-0 flex-grow-1"
              style={{ outline: "none" }}
              placeholder="Search by tags..."
              value={searchTagInput}
              ref={searchInputRef}
              onChange={(e) => setSearchTagInput(e.target.value)}
              onFocus={() => setShowSearchSuggestions(true)}
              onBlur={() => setTimeout(() => setShowSearchSuggestions(false), 100)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && searchTagInput.trim() !== '') {
                  e.preventDefault();
                  if (!searchTags.includes(searchTagInput.trim())) {
                    setSearchTags([...searchTags, searchTagInput.trim()]);
                  }
                  setSearchTagInput('');
                }
              }}
            />
          </div>
          {showSearchSuggestions && (
            <ul
              className="suggestions-dropdown mt-2"
              style={{
                position: "absolute",
                width: "calc(100% - 2rem)",
                background: "#fff",
                border: "1px solid #ccc",
                borderRadius: ".25rem",
                zIndex: 10,
                listStyle: "none",
                padding: "0.5rem",
                margin: 0,
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              {availableTags
                .filter(tag =>
                  tag.toLowerCase().includes(searchTagInput.toLowerCase()) &&
                  !searchTags.includes(tag)
                )
                .map(tag => (
                  <li
                    key={tag}
                    style={{ padding: "4px 8px", cursor: "pointer" }}
                    onMouseDown={() => {
                      setSearchTags([...searchTags, tag]);
                      setSearchTagInput('');
                    }}
                  >
                    {tag}
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>
      {/* End Styled Search Box */}

      {loading && (
        <div className="text-center my-4">
          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {!loading && posts.length === 0 && hasSearched && (
        <div className="no-nfts">
          <p className="invite-title">No Posts found</p>
          <p className="invite-subtitle">Share your thoughts and let the community know what's on your mind!</p>
          {!profile && (
          <button className="btn btn-outline-primary" onClick={handleInvitePost}>Create an NFT Post</button>
          )}
        </div>
      )}
      {!loading && posts.map((post) => (
        <NFTCard ownProfile={ownProfile} wallet={wallet} key={post._id} post={post} />
      ))}
      {showPagination && posts.length > 0 && (
        <div className="d-flex justify-content-between align-items-center mt-4">
          <button className="btn btn-secondary" onClick={handlePrev} disabled={page === 1}>
            &laquo; Previous
          </button>
          <span>Page {page} of {totalPages}</span>
          <button className="btn btn-secondary" onClick={handleNext} disabled={page === totalPages}>
            Next &raquo;
          </button>
        </div>
      )}
    </div>
  );
};

export default Stas789NFT;