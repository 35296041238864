import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import devApiService from '../services/devApiService';
import '../css/AppDetails.css';
import { AuthContext } from '../context/AuthProvider';
import BSVTransferForm from '../components/BSVTransferForm'; // For in-app payment overlay
import AppSignaler from '../services/appSignaler';
import { createPost } from '../services/apiOutCalls'; // API to create post on index_post command
import OverlayConfirm from '../components/OverlayConfirm'; // For user confirmation overlays
import {ConfigContext} from '../config'; // For configuration context
const AppDetails = () => {
  // Retrieve appId from URL & initialize navigation and global context
  const { appId } = useParams();
  const navigate = useNavigate();
  const [appData, setAppData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { wallet } = useContext(AuthContext);
  const config = useContext(ConfigContext);
  // State for payment (AppPay) overlay and its parameters:
  const [showAppPayOverlay, setShowAppPayOverlay] = useState(false);
  const [AppPayTransferParams, setAppPayTransferParams] = useState({});
  const [AppPayRef, setAppPayRef] = useState(null);
  const [appSignaler, setAppSignaler] = useState(null);
  
  // State for create-post confirmation overlay:
  const [showIndexPostConfirm, setShowIndexPostConfirm] = useState(false);
  const [indexPostDetail, setIndexPostDetail] = useState(null);
  
  // Function to adjust the iframe height dynamically.
  const adjustIframeHeight = () => {
    const nav = document.querySelector('.navigation-bar');
    const footer = document.querySelector('.app-footer');
    const browserBar = document.querySelector('.app-browser-bar');

    const navBottom = nav ? nav.getBoundingClientRect().bottom : 0;
    const footerTop = footer ? footer.getBoundingClientRect().top : window.innerHeight;
    const browserHeight = browserBar ? browserBar.getBoundingClientRect().height : 0;

    const availableHeight = footerTop - navBottom - browserHeight;
    const iframe = document.querySelector('.app-iframe');
    if (iframe) {
      iframe.style.height = availableHeight > 500 ? `${availableHeight-10}px` : '500px';
    }
  };

  window.addEventListener('resize', adjustIframeHeight);
  adjustIframeHeight(); // initial calculation

  // Run once on mount and then whenever the window resizes.
  useEffect(() => {
    adjustIframeHeight(); // initial run
    window.addEventListener('resize', adjustIframeHeight);
    return () => window.removeEventListener('resize', adjustIframeHeight);
  }, []);


  
  // Listen using our AppSignaler instance from the iframe.
  useEffect(() => {
    let cleanup;
    if (appSignaler) {
      cleanup = appSignaler.listen(async (data) => {
        console.log("Platform received command from iframe:", data.detail);
        // For payment requests:
        if (data.detail && data.detail.type === "pay") {
          
          let feeValue = 0;
          let transfer_params = {
            autoutxo: true,
            recipients: []
          }

          if (config?.FEE_ADDRESS !== "") {
            if (data.detail.reason=='AI_IMG'){
              feeValue = 150000;
            } else if (data.detail.reason=='NFT_POST'){
              feeValue = 100000;
            } else {
              feeValue = 50000;
            }
            

            transfer_params.recipients.push({
              address: config.FEE_ADDRESS,
              value: feeValue, 
              fiatvalue: feeValue * config.FX_BSV / 100000000,
              reason: data.detail.reason || "IN_APP"
            });
          }
          // Finally add the app requested transfer
          transfer_params.recipients.push({ address: data.detail.address, value: data.detail.value || 0, fiatvalue: data.detail.value * config.FX_BSV / 100000000 });

          setAppPayTransferParams(transfer_params);
          setAppPayRef(data.detail.ref);
          setShowAppPayOverlay(true);
        }
        // For create-post requests, show the confirmation overlay.
        else if (data.detail && data.detail.type === "create-post") {
          setIndexPostDetail(data.detail);
          setShowIndexPostConfirm(true);
        }
      });
    }
    return () => {
      if (cleanup) cleanup(); // Clean up listener on unmount
    };
  }, [appSignaler]);
  
  // Updated: Handler for create-post confirmation overlay.
  const handleIndexPostConfirmComplete = async (result) => {
    setShowIndexPostConfirm(false);
    if (result.status && wallet && indexPostDetail) {
      try {
        const postResult = await createPost(wallet, {
          contractTx: indexPostDetail.contractTx,
          issueTx: indexPostDetail.issueTx
        });
        if (appSignaler) {
          appSignaler.postMessage({ 
            type: " create-post-response", 
            payload: { 
              contractTx: indexPostDetail.contractTx, 
              success: postResult.success, 
              error: postResult.error || null, 
              responseCode: postResult.success ? "OK_SUCCESS" : "ERR_POST_FAIL"
            } 
          });
        }
      } catch (error) {
        console.error("Error creating post:", error);
        if (appSignaler) {
          appSignaler.postMessage({ 
            type: " create-post-response", 
            payload: { 
              contractTx: indexPostDetail.contractTx, 
              success: false, 
              error: error.message || error,
              responseCode: "ERR_POST_EXCEPTION"
            } 
          });
        }
      }
    } else {
      // User cancelled confirmation; send failure response.
      if (appSignaler && indexPostDetail) {
        appSignaler.postMessage({ 
          type: " create-post-response", 
          payload: { 
            contractTx: indexPostDetail.contractTx, 
            success: false, 
            error: "User rejected index post confirmation",
            responseCode: "ERR_REJECTED"
          } 
        });
      }
    }
    setIndexPostDetail(null);
  };
  
  // Updated: Handler for a successful payment transaction.
  const handleAppPayTransactionComplete = (outcome) => {
    setShowAppPayOverlay(false);
    const payload = {
      ref: AppPayRef,
      success: outcome && outcome.txid ? true : false,
      txid: outcome.txid || null,
      signature: "dummy_signature",
      responseCode: outcome && outcome.txid ? "OK_SUCCESS" : "ERR_UNKNOWN"
    };
    if (appSignaler) {
      appSignaler.postMessage({ type: "pay-response", payload });
    } else {
      console.error("No appSignaler available to post message");
    }
  };

  // Updated: If payment overlay is closed without finishing, send abort message.
  const handleAppPayAbort = () => {
    setShowAppPayOverlay(false);
    const payload = {
      ref: AppPayRef,
      success: false,
      txid: null,
      signature: "dummy_signature",
      message: "Payment aborted by user",
      responseCode: "ERR_ABORTED"
    };
    if (appSignaler) {
      appSignaler.postMessage({ type: "pay-response", payload });
    } else {
      console.error("No appSignaler available to post failure message");
    }
  };

  useEffect(() => {
    if (wallet && appId) {
      devApiService.findApp(wallet, appId)
        .then(response => {
          if(response.success && response.app) {
            setAppData(response.app);
          } else {
            setError('Failed to load app details.');
          }
          setLoading(false);
        })
        .catch(err => {
          setError('Error fetching app details: ' + err.message);
          setLoading(false);
        });
    }
  }, [wallet, appId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;
  if (!appData) return <div className="alert alert-warning">App not found</div>;

  return (
    <div className="app-container p-0" style={{ marginTop: 0 }}>
      <div className="app-iframe-container">
        {/* Iframe container */}
        <iframe 
          title={appData.appName}
          srcDoc={appData.packageContent}
          sandbox="allow-scripts"
          referrerPolicy="no-referrer"
          className="app-iframe p-0 m-0"
          onLoad={(e) => {
            // Initialize AppSignaler with this iframe's contentWindow.
            const targetWindow = e.target.contentWindow;
            const signalerInstance = new AppSignaler(targetWindow);
            setAppSignaler(signalerInstance);
            // Send wallet info using our signaler instance.
            setTimeout(() => {
              signalerInstance.postMessage({
                type: "platform-connected",
                appId: appData._id,
                timestamp: "timestamp",
                wallet: {
                  address: wallet.address,
                  signature: "platform_signature" // Replace with actual signature logic.
                }
              });
              console.log("Sent wallet info from AppDetails iframe.");
            }, 500);
          }}
        ></iframe>
        {/* Compact browser bar style details */}
        <div className="app-browser-bar p-2">
          <img 
            src={appData.appIcon || '/default-icon.png'} 
            alt={`${appData.appName} icon`} 
            className="app-icon-small"
          />
          <span className="app-title">{appData.appName}</span>
          <button 
            className="btn btn-warning" 
            onClick={() => navigate('/apps')}
            style={{
              
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
             <i className="fa fa-arrow-circle-left me-1" style={{ fontSize: '14px', color: '#fff' }}></i> Back to Apps
          </button>
        </div>
      </div>
      {/* AppPay overlay rendering */}
      {showAppPayOverlay && (
        <div style={{
          position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1050
        }}>
          <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
          <div className="modal fade show" style={{ display: 'block', zIndex: 1050 }} role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">In-app Payment</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleAppPayAbort}  // NEW: call abort handler on close
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body pt-0 pb-0">
                  <BSVTransferForm
                    wallet={wallet}
                    transfer_params={AppPayTransferParams}
                    onTransactionComplete={handleAppPayTransactionComplete}
                    onClose={handleAppPayAbort} // NEW: pass onClose prop to BSVTransferForm
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* NEW: Index Post Confirmation Overlay */}
      {showIndexPostConfirm && (
        <OverlayConfirm
          confirmation={{ messages: ["The app is asking your permission to send some content cover to you on Metanet. Are you OK with this?"] }}
          optionsMsg={{ confirm: "Yes, cool", cancel: "No, I don't want" }}
          onComplete={handleIndexPostConfirmComplete}
        />
      )}
    </div>
  );
};

export default AppDetails;
