import React, { createContext, useContext, useState } from 'react';

const GlobalAlertContext = createContext({
  showAlert: () => {},
  hideAlert: () => {}
});

export function GlobalAlertProvider({ children }) {
  const [alert, setAlert] = useState({ visible: false, message: '', type: 'info', title: '', timeout: 5000 });

  const showAlert = (message, type = 'info', title = '', timeout = 5000) => {
    setAlert({ visible: true, message, type, title, timeout });
    if (timeout !== false) {
      setTimeout(() => setAlert(a => ({ ...a, visible: false })), timeout);
    }
  };

  const hideAlert = () => {
    setAlert(a => ({ ...a, visible: false }));
  };

  // Determine default title based on type if title not provided.
  const getTitle = () => {
    if (alert.title) return alert.title;
    switch (alert.type) {
      case 'success': return 'Success';
      case 'danger': return 'Error';
      default: return 'Notice';
    }
  };

  return (
    <GlobalAlertContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      {alert.visible && (
        <div 
          style={{ 
            position: 'fixed', 
            bottom: '50px', 
            left: '50%', 
            transform: 'translateX(-50%)',
            zIndex: 9999,
            maxWidth: '700px',
            width: '90%'
          }}
          className="global-alert-overlay"
        >
          <div 
            className="card"
            style={{
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              boxShadow: '0 0 1rem rgba(68, 120, 176, 0.3)'
            }}
          >
            <div 
              className="card-header d-flex justify-content-between align-items-center"
              style={{
                background: alert.type === 'success' ? '#8cc9aa' : alert.type === 'danger' ? '#f8d7da' : '#f3d455',
                color: alert.type === 'success' ? '#155724' : alert.type === 'danger' ? '#721c24' : '#6e5d38',
                border: '1px solid #efede0',
              }}
            >
              <strong>{getTitle()}</strong>
              <button 
                type="button" 
                className="close" 
                aria-label="Close"
                onClick={hideAlert}
                style={{
                  background: 'transparent',
                  border: 'none',
                  fontSize: '1.2rem',
                  lineHeight: '1'
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div 
              className="card-body"
              style={{
                background: '#fff',
                color: '#000',
                borderRadius: '0.5rem' // Rounded corners added here
              }}
            >
              <p className="card-text">{alert.message}</p>
            </div>
          </div>
        </div>
      )}
    </GlobalAlertContext.Provider>
  );
}

export function useGlobalAlert() {
  return useContext(GlobalAlertContext);
}
