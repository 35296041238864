import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [wallet, setWallet] = useState(() => localStorage.getItem("wallet") || null);
  const [walletSwitch, setWalletSwitch] = useState(() => {
    const stored = localStorage.getItem("wallet_switch");
    return stored ? JSON.parse(stored) : [];
  });

  useEffect(() => {
    const storedWallet = localStorage.getItem('wallet');
    if (storedWallet) {
      try {
        const walletData = JSON.parse(storedWallet);
        setWallet(walletData);
      } catch (err) {
        console.error('Error parsing wallet from storage', err);
      }
    }
  }, []);

  const saveWallet = (walletData) => {
    setWallet(walletData);
    localStorage.setItem('wallet', JSON.stringify(walletData));
    addWalletSwitch(walletData); // added to ensure the wallet is added to walletSwitch
  };

  const addWalletSwitch = (newWallet) => {
    setWalletSwitch(prev => {
      // Use address to check for existence
      const exists = prev.some(w => w.address === newWallet.address);
      const updated = exists ? prev : [...prev, newWallet];
      localStorage.setItem("wallet_switch", JSON.stringify(updated));
      return updated;
    });
  };

  // NEW: Update a wallet in the walletSwitch list by matching its address.
  const updateWalletSwitch = (updatedWallet) => {
    setWalletSwitch(prev => {
      const updatedList = prev.map(w => w.address === updatedWallet.address ? updatedWallet : w);
      localStorage.setItem("wallet_switch", JSON.stringify(updatedList));
      return updatedList;
    });
  };

  const switchWallet = (newWallet) => {
    setWallet(newWallet);
    localStorage.setItem("wallet", newWallet);
    addWalletSwitch(newWallet);
  };

  const logout = () => {
    setWallet(null);
    setWalletSwitch([]);
    localStorage.removeItem('wallet');
    localStorage.removeItem('wallet_switch');
  };

  return (
    <AuthContext.Provider value={{ wallet, saveWallet, switchWallet, logout, walletSwitch, setWallet, updateWalletSwitch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
