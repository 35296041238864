import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthProvider';
import devApiService from '../services/devApiService';
import '../css/AppEdit.css';

const categories = [
	"Productivity",
	"Gaming",
	"Finance",
	"Education",
	"Entertainment",
	"Social",
	"Health",
	"News",
	"Travel",
	"Utilities"
];

const DevAppSubmit = () => {
  const { wallet } = useContext(AuthContext);
  
  const [appName, setAppName] = useState('');
  const [packageFile, setPackageFile] = useState(null);
  const [appIcon, setAppIcon] = useState('');
  const [appCover, setAppCover] = useState('');
  const [category, setCategory] = useState(categories[0]);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const validate = () => {
    const newErrors = {};
    if (!appName.trim()) newErrors.appName = 'App Name is required';
    if (!packageFile) newErrors.packageFile = 'App Package file is required';
    if (!appIcon.trim()) newErrors.appIcon = 'App Icon URL is required';
    if (!appCover.trim()) newErrors.appCover = 'App Cover URL is required';
    return newErrors;
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if(file) setPackageFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    setIsSubmitting(true);
    setMessage('');
    const formData = new FormData();
    formData.append('appName', appName);
    formData.append('appIcon', appIcon);
    formData.append('appCover', appCover);
    formData.append('category', category);
    formData.append('packageFile', packageFile);
    try {
      const result = await devApiService.submitApp(wallet, formData);
      if (result && result.success) {
        setMessage('App submitted successfully!');
        setAppName('');
        setPackageFile(null);
        setAppIcon('');
        setAppCover('');
        setCategory(categories[0]);
      } else {
        setMessage(result.error || 'Submission failed.');
      }
    } catch (err) {
      setMessage(err.message || 'Submission failed due to an error.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="app-container p-3">
      <div className="card appedit-card">
        <h2 className="appedit-title">Submit Your App</h2>
        <form onSubmit={handleSubmit} className="appedit-form">
          <div className="form-group mb-2 appedit-form-group">
            <label>App Name</label>
            <input 
              type="text" 
              value={appName} 
              onChange={(e) => setAppName(e.target.value)} 
              className="form-control appedit-form-control"
              placeholder="Enter app name" 
            />
            {errors.appName && <small className="text-danger">{errors.appName}</small>}
          </div>
          <div className="form-group mb-2 appedit-form-group">
            <label>App Package (HTML/JS)</label>
            <input 
              type="file" 
              accept=".html,.htm,.js" 
              onChange={handleFileUpload} 
              className="form-control"
            />
            {errors.packageFile && <small className="text-danger">{errors.packageFile}</small>}
          </div>
          <div className="form-group mb-2 appedit-form-group">
            <label>App Icon URL</label>
            <input 
              type="text" 
              value={appIcon} 
              onChange={(e) => setAppIcon(e.target.value)} 
              className="form-control appedit-form-control"
              placeholder="https://..."
            />
            {errors.appIcon && <small className="text-danger">{errors.appIcon}</small>}
          </div>
          <div className="form-group mb-2 appedit-form-group">
            <label>App Cover URL</label>
            <input 
              type="text" 
              value={appCover} 
              onChange={(e) => setAppCover(e.target.value)} 
              className="form-control appedit-form-control"
              placeholder="https://..."
            />
            {errors.appCover && <small className="text-danger">{errors.appCover}</small>}
          </div>
          <div className="form-group mb-2 appedit-form-group">
            <label>Category</label>
            <select 
              value={category} 
              onChange={(e) => setCategory(e.target.value)} 
              className="form-control appedit-form-control"
            >
              {categories.map(cat => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>
          </div>
          <button 
            type="submit" 
            className="btn btn-primary appedit-submit-btn"
          >
            Submit App
          </button>
        </form>
        {message && <div className="alert alert-info appedit-alert-info">{message}</div>}
      </div>
    </div>
  );
};

export default DevAppSubmit;
