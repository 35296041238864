import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom'; // import useNavigate and NavLink
import EmbeddedPost from './EmbeddedPost';
import IconButtons from './IconButtons';
import PropertiesOverlay from './PropertiesOverlay';
import MintOverlay from './MintOverlay';
import { Address } from 'bsv'; // Import Address from the BSV SDK
import BSVTransferForm from './BSVTransferForm';
import { ConfigContext } from '../config'; // add this import
import { isAdmin } from '../utils/mintAllocations'; // add this import
import { deletePost } from '../services/apiOutCalls'; // add this import
import { useGlobalAlert } from '../components/GlobalAlert'; // add this import
import OverlayConfirm from '../components/OverlayConfirm'; // NEW import for confirmation overlay
import { safeText, safeTextLinks } from '../utils/stringUtils';
import ExpandedPostOverlay from './ExpandedPostOverlay';
import moment from 'moment';
import DecryptedVideo from './DecryptedVideo';
import DecryptedImage from './DecryptedImage';

import '../css/NFTCard.css';

const NFTCard = ({ post, embedded, isReply, wallet, mintFromCard, ownProfile }) => {
  const config = React.useContext(ConfigContext); // use context to get config
  const passphrase = config.CONTENT_PASSPHRASE;
  const saltBuffer = new Uint8Array([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]); // or from config
  // Pull content and reaction from post.properties
  const content = post?.properties?.content || {};
  const reaction = post?.properties?.reaction || {};
  
  const navigate = useNavigate();
  const { showAlert } = useGlobalAlert(); // add this line
  const [showOverlay, setShowOverlay] = useState(false);
  const [showMintOverlay, setShowMintOverlay] = useState(false);
  const [mintParams, setMintParams] = useState({});
  const [outcome, setOutcome] = useState(null);  // new outcome state
  const [showTipOverlay, setShowTipOverlay] = useState(false);
  const [tranferParams, setTranferParams] = useState({});
  const [reactionMode, setReactionMode] = useState(false);
  const [reactionCounts, setReactionCounts] = useState({}); // new state to hold totals
  const [deleted, setDeleted] = useState(false); // added state
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false); // NEW state for delete confirmation
  const [showExpandedOverlay, setShowExpandedOverlay] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); // new state

  const imageSrc = content && content.type === "Image64"
    ? content.content
    : (post?.image || null);
  const tags = content && content.tags 
    ? content.tags 
    : [];
  // Update hasReply and hasRepost based on reaction.type
  const hasReply = !!post.replyFrom;
  const hasRepost = reaction?.type === "repostOf" && reaction.contractTx;

  // Compute tipAddress from tokenId (ensure tokenId is valid)
  const tipAddress = post && post.recentOwner 
    ? Address.fromPublicKeyHash(Buffer.from(post.recentOwner, 'hex')).toString() 
    : "";

  // When transaction completes, hide overlays and display outcome.
  const handleTransactionComplete = (txResult) => {
    // setOutcome(txResult);
    setShowMintOverlay(false);
    setShowTipOverlay(false);
  };

  useEffect(() => {
    // console.log("NFTCard post:", post);
  });
  // When comment icon is clicked, pass reply reaction built from this post.
  const onCommentClick = () => {
    setMintParams({
      reaction: {
        type: "replyTo",
        contractTx: post.contractTx,     // Use the NFT's symbol
      },
    });
    setOutcome(null);  // reset outcome if reopening mint overlay
    setShowMintOverlay(true);
  };

  // When share icon is clicked, pass repost reaction built from this post.
  const onShareClick = () => {
    setMintParams({
      reaction: {
        type: "repostOf",
        contractTx: post.contractTx,
      },
    });
    setOutcome(null);
    setShowMintOverlay(true);
  };

  // Navigate to TransferNFT page passing along the post details.
  const onTransferClick = () => {
    navigate(`/transfer/${post.tokenId}/${post.symbol}`, { state: { tokenId: post.tokenId, symbol: post.symbol, post } });
  };

  // Optional copy handler for TransactionOutcome.
  const handleCopyOutcome = async () => {
    const textToCopy = outcome.txid ? outcome.txid : outcome.signedHex;
    try {
      await navigator.clipboard.writeText(textToCopy);
      alert('Copied to clipboard.');
    } catch (err) {
      alert('Failed to copy.');
    }
  };

  // Handler for tip functionality.
  const onTipClick = (fiatvalue) => {
    if (config.FX_BSV === 0) {
      showAlert("We couldn't fetch current $BSV rate. If the problem persists please contact support.", "danger", "Error");
      return;
    }
    // Use config.FX_BSV from config instead of hardcoded 36.
    // Generate a P2PKH address from the tokenId hex.
    const tipAddress = Address.fromPublicKeyHash(Buffer.from(post.recentOwner, 'hex')).toString();
    setTranferParams({
      autoutxo: true,
      recipients: [
        {
          address: tipAddress,
          value: fiatvalue / config.FX_BSV * 100000000,
          fiatvalue,
        }
      ]
    });
    setShowTipOverlay(true);
  };

  // Toggle reaction mode (replaces icon-buttons with horizontal reaction emoticons)
  const toggleReactionMode = () => {
    setReactionMode(!reactionMode);
  };

  // New local function to handle delete action
  const performDelete = async () => {
    try {
      const response = await deletePost(wallet, post.contractTx);
      showAlert(response.message || "Post deleted successfully.", "success", "Post Deleted");
      setDeleted(true); // mark as deleted on success
    } catch (error) {
      const errorMessage =
        (error.response && error.response.data && error.response.data.error) ||
        error.message;
      showAlert(errorMessage, "danger", "Post Delete Error");
    }
  };

  const handleDeleteConfirm = ({ status }) => {
    setShowDeleteConfirm(false);
    
    if (status) {
      performDelete();
    }
  };

  // New function to show the confirmation
  const handleDelete = () => {
    setShowDeleteConfirm(true);
  };

  // New function to handle full post expansion
  const onExpandFullPost = () => {
    setShowExpandedOverlay(true);
  };

  // New callback to expand the post
  const handleExpandFullPost = () => {
    setIsExpanded(true);
  };

  return (
    <div>
      <div id={post.contractTx} className={isReply ? "nft-reply" : "nft-post card"} style={{ position: 'relative', cursor: 'pointer' }}>
        {deleted ? (
          <div className="card-body">Post deleted!</div>
        ) : (
          <>
            <div >
              {/* New: Profile icon at top-right corner*/}
              
                <div  className="nft-post-content pb-0" style={{ position: 'relative' }}>
                <small>
                {post.ownerUsername && tipAddress && (
                  <strong  onClick={() => { navigate(`/profile/${tipAddress}`) }} > 
                    @{post.ownerUsername}
                  </strong> 
                  )} {moment(post.createdAt).format("MMM D, YYYY, HH:mm")}
                </small>
                </div>
              

              <div className="nft-post-content pt-2 pb-2" style={{ position: 'relative' }}>
                
                <div onClick={() => { navigate(`/post/${post.contractTx}`) }}  >
                {post.name && (
                <h5 className="card-title"
                  dangerouslySetInnerHTML={{ __html: safeText(post?.name || "") }}>
                </h5>
                )}
                
                {post.description && (
                  <p className="card-text mb-1"
                    dangerouslySetInnerHTML={{ __html: safeTextLinks(post.description) }}>
                  </p>
                )}
                </div>
                
                {/* NEW: Render preview media if available (as a list) */}
                {post?.properties?.content?.preview_media &&
                  Array.isArray(post.properties.content.preview_media) &&
                  post.properties.content.preview_media.length > 0 && (
                    <>
                      {post.properties.content.preview_media.map((preview, index) => (
                        preview.type === 'video' ? (
                          <div className="video-asset mt-3" key={index}>
                            <DecryptedVideo 
                              videoUrl={preview.CID} 
                              password={config.CONTENT_PASSPHRASE} 
                              saltBuffer={saltBuffer} 
                            />
                          </div>
                        ) : preview.type === 'image' ? (
                          <div className="image-asset mt-3 bg-light border rounded p-3" key={index}>
                          <DecryptedImage 
                            key={index}
                            cid={preview.CID} 
                            passphrase={config.CONTENT_PASSPHRASE} 
                            alt={`Preview Media ${index}`} 
                          /></div>
                        ) : null
                      ))}
                    </>
                )}
                
                {tags.length > 0 && (
                  <div className="nft-post-tags m-0">
                    {tags.map((tag, index) => (
                      <span key={index} className="badge bg-light text-secondary me-1 mb-1">
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
               
              </div>

              {config.ENABLE_INPOST_MEDIA && post?.properties?.content?.assets.length > 0 && (
                  <div className="border rounded bg-light p-2 nft-media-indicator d-flex align-items-center justify-content-center m-2 mt-0">
                    <span className="me-2 text-secondary" style={{ fontSize: '1.1rem' }}>
                      <i className="fa fa-photo"></i>
                    </span>
                    <a
                      href="#/"
                      onClick={onExpandFullPost}
                      className="media-expand-link text-secondary"
                      style={{ fontSize: '0.9rem', fontWeight: 'bold' }}
                    >
                      View Content
                    </a>
                  </div>
                )}
              
              {imageSrc && (
                <div className="nft-post-image-container">
                  <img src={imageSrc} alt={post?.name || post?.symbol} className="nft-post-image-full" />
                </div>
              )}
              {hasRepost && !embedded && (
                <div className="embedded-share">
                  <EmbeddedPost wallet={wallet} meta={reaction} />
                </div>
              )}
              {!mintFromCard && (isReply || !embedded) && (
                <div className="nft-post-footer">
                  <div className="footer-left">
                    <IconButtons 
                      ownProfile={ownProfile}
                      reactionMode={reactionMode}
                      onCommentClick={onCommentClick} 
                      onShareClick={onShareClick} 
                      onTipClick={onTipClick} 
                      onExpandFullPost={onExpandFullPost}
                      onToggleReaction={toggleReactionMode}
                      wallet={wallet}                // Pass wallet to IconButtons
                      post={post}                    // Pass the full post here for _id access
                    />
                    {/* Optionally display reaction totals */}
                    {Object.keys(reactionCounts).length > 0 && (
                      <div className="reaction-totals">
                        {Object.entries(reactionCounts).map(([emotion, count]) => (
                          <span key={emotion} className="reaction-total">
                            {emotion}: {count}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="footer-right">
                    {post.reactionCount  && (
                     <button className="chart-btn">
                      <i className="fa fa-chart-line" style={{marginRight: '0.3rem'}}></i>
                      <span>{post.reactionCount || 0}</span>
                     </button>
                    )}
                    <button className="info-btn" onClick={() => setShowOverlay(true)}>
                      <i className="fa fa-info-circle"></i>
                    </button>
                    {ownProfile && (
                    <button className="transfer-btn" onClick={onTransferClick}>
                      <i className="fa fa-exchange" aria-hidden="true"></i> 
                    </button>
                    )}
                    {isAdmin(wallet,config) && (
                    <button className="transfer-btn" onClick={handleDelete}>
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                    )}

                  {/* New: Profile icon at top-right corner*/}
                  {tipAddress && !post.ownerUsername && (
                  <NavLink to={`/profile/${tipAddress}`} className="profile-icon">
                      <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                  </NavLink>
                  )}
                    
                  </div>
                </div>
                )}
            </div>
            
            {showOverlay && (
              <PropertiesOverlay wallet={wallet} post={post} onClose={() => setShowOverlay(false)} />
            )}
            {/* Render MintOverlay if outcome is not yet available */}
            {!outcome && showMintOverlay && (
              <MintOverlay
                mintFromCard={true}
                show={showMintOverlay}
                wallet={wallet}
                mintParams={mintParams}
                outcome={outcome}
                onTransactionComplete={handleTransactionComplete}
                onCopyOutcome={handleCopyOutcome}
                onResetOutcome={() => { setShowMintOverlay(false); setOutcome(null); }}
                onClose={() => setShowMintOverlay(false)}
              />
            )}
            {/* Render BSVTransferForm overlay for tips if outcome is not available */}
            {!outcome && showTipOverlay && (
              <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1050 }}>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
                <div className="modal fade show" style={{ display: 'block', zIndex: 1050 }} role="dialog">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Send Tip</h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => setShowTipOverlay(false)}
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body pt-0 pb-0">
                        <BSVTransferForm
                          wallet={wallet}
                          transfer_params={tranferParams}
                          onTransactionComplete={handleTransactionComplete}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Render confirmation overlay for deletion */}
            {showDeleteConfirm && (
              <OverlayConfirm
                confirmation={{messages:["Are you sure you want to delete this post?"]}}
                onComplete={handleDeleteConfirm}
              />
            )}
           
          </>
        )}
        {hasReply && !embedded && (
        <div className="embedded-reply">
          <EmbeddedPost post={post} isReply={true} wallet={wallet} meta={post.replyFrom} />
        </div>
        )}
      </div>
      {/* Render full post expanded overlay */}
      {showExpandedOverlay && (
        <ExpandedPostOverlay wallet={wallet} post={post} onClose={() => setShowExpandedOverlay(false)} />
      )}
      {/* Render assets container when expanded. Assets are always loaded */}
      {isExpanded && (
        <div id="expanded-post" className="expanded-post">
          { post?.properties?.content?.assets && post.properties.content.assets.length > 0 ? (
            post.properties.content.assets.map((asset, idx) => (
              <img key={idx} src={asset} alt={`Asset ${idx}`} />
            ))
          ) : (
            <div>No assets available</div>
          )}
        </div>
      )}
    </div>
  );
};

export default NFTCard;