import React, { useState, useEffect } from 'react';
import { fetchNftUtxos } from 'js-1sat-ord';
import PlaceholderIcon from '../assets/placeholder-icon.svg';
import '../css/OneSatOrdinals.css';

const ONE_SAT_PAGE_SIZE = 20;

const OneSatOrdinals = ({ wallet }) => {
  const ordAddress = wallet?.address;
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  // Helper function to parse detailed token info.
  const parseTokenDetails = (token) => {
    const { txid, vout } = token;
    const url = `https://plugins.whatsonchain.com/api/plugin/main/${txid}/${vout}`;
    // Use WordPress mShots API to generate a square snapshot of the ordinal URL on the fly.
    const image = `https://image.thum.io/get/auth/73554-4786567c439440bb40b8c561b0ca3828/${url}`;
    return {
      id: vout,
      txid: txid,
      name: `${txid}_${vout}`,
      image,
      url
    };
  };

  useEffect(() => {
    if (!ordAddress) return; // Exit if there is no address available.
    const fetchNFTData = async () => {
      setLoading(true);
      try {
        // Fetch the minimal list of NFT UTXOs using the wallet address.
        const nftUtxos = await fetchNftUtxos(ordAddress);
        
        const tokenDetails = await Promise.all(nftUtxos);
        // Parse each detailed token into a displayable NFT object.
        const parsedNFTs = tokenDetails.map(token => parseTokenDetails(token));
        setNfts(parsedNFTs);
      } catch (error) {
        console.error('Error fetching 1sat NFTs:', error);
      }
      setLoading(false);
    };
    fetchNFTData();
  }, [ordAddress]);

  // Pagination logic.
  const totalPages = Math.ceil(nfts.length / ONE_SAT_PAGE_SIZE);
  const currentNFTs = nfts.slice(
    (currentPage - 1) * ONE_SAT_PAGE_SIZE,
    currentPage * ONE_SAT_PAGE_SIZE
  );

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  // Function to generate pagination page numbers with ellipsis when needed.
  const getPaginationPages = () => {
    const maxPagesToShow = 5;
    let pages = [];

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, '...', totalPages];
      } else if (currentPage >= totalPages - 2) {
        pages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
      } else {
        pages = [1, '...', currentPage, '...', totalPages];
      }
    }
    return pages;
  };

  const paginationPages = getPaginationPages();

  return (
    <div className="nft-section">
      {loading ? (
        <div>Loading NFTs...</div>
      ) : (
        <>
          <div className="row">
            {currentNFTs.map(nft => {
              const [txid, vout] = nft.name.split('_');
              return (
                <div key={nft.name} className="col-6 col-md-4 col-lg-3 mb-3">
                  <div className="card">
                    <a 
                      href={nft.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ordinal-item d-block"
                    >
                      <img src={nft.image} className="card-img-top img-fluid ordinal-thumbnail" alt={nft.name} />
                    </a>
                    <div className="card-footer text-center">
                      {nft.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {totalPages > 1 && (
            <div className="pagination-controls">
              <button className="pagination-button" onClick={handlePrevious} disabled={currentPage === 1}>
                Prev
              </button>
              {paginationPages.map((page, index) =>
                page === '...' ? (
                  <span key={index} className="pagination-ellipsis">{page}</span>
                ) : (
                  <button
                    key={index}
                    className={`pagination-number ${page === currentPage ? 'active' : ''}`}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </button>
                )
              )}
              <button className="pagination-button" onClick={handleNext} disabled={currentPage === totalPages}>
                Next
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OneSatOrdinals;
