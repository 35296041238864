import {useContext} from 'react';
import { P2PKH, Script } from '@bsv/sdk';

/**
 * allocateMintUtxos
 *
 * Given an array of available UTXOs and an array of role requirements,
 * this function sorts the requirements (largest minimum first) and
 * the UTXOs (descending by satoshis) and then allocates a UTXO for each role.
 *
 * @param {Array} availableUTXOs - Array of transformed UTXO objects (each with txid, vout, satoshis).
 * @param {Array} requiredRoles - Array of objects in the form { role: string, min: number }.
 * @returns {Object} Allocations in the form { role1: utxo, role2: utxo, ... }.
 * @throws Will throw an error when a required role cannot be allocated.
 */
export function allocateMintUtxos(availableUTXOs = [], requiredRoles = []) {
  // Clone the arrays so as not to modify originals.
  let utxos = [...availableUTXOs];
  let roles = [...requiredRoles];

  // Sort required roles descending by minimum amount.
  roles.sort((a, b) => b.min - a.min);

  // Sort available UTXOs descending by satoshis.
  utxos.sort((a, b) => b.satoshis - a.satoshis);

  const allocations = {};

  // Iterate over each required role and allocate an UTXO.
  for (const req of roles) {
    const found = utxos.find(utxo => utxo.satoshis >= req.satoshis);
    if (!found) {
      throw new Error(`No UTXO meets the requirement for ${req.role}: minimum ${req.satoshis} satoshis.`);
    }
    allocations[req.role] = found;
    // Remove the allocated UTXO by matching both txid and vout.
    utxos = utxos.filter(utxo => !(utxo.txid === found.txid && utxo.vout === found.vout));
  }

  return allocations;
}



// Helper: Transform UTXO into proper format.
export function  transformUtxo (utxo, address) {
  const p2pkh = new P2PKH();
  return {
    txid: utxo.tx_hash || utxo.txid,
    vout: typeof utxo.tx_pos === 'number' ? utxo.tx_pos : utxo.vout,
    satoshis: typeof utxo.satoshis === 'number' ? utxo.satoshis : utxo.value,
    script: utxo.script || p2pkh.lock(address).toHex()
  };
};

/**
 * validateUtxo: Validates that a UTXO object has the required keys and types.
 * Expected keys: txid (string), vout (number), satoshis (number), script (string).
 */
export function  validateUtxo (utxo, description) {
  if (!utxo || typeof utxo !== 'object') {
    throw new Error(`${description} : Invalid value for utxo`);
  }
  if (typeof utxo.satoshis !== 'number') {
    throw new Error(`${description} : Expected number value for satoshis but got ${utxo.satoshis}`);
  }
  if (typeof utxo.script !== 'string') {
    throw new Error(`${description} : Expected string value for script but got ${utxo.script}`);
  }
  if (typeof utxo.vout !== 'number' || utxo.vout < 0) {
    throw new Error(`${description} : Expected number value for vout but got ${utxo.vout}`);
  }
  if (typeof utxo.txid !== 'string') {
    throw new Error(`${description} : Expected string value for txid but got ${utxo.txid}`);
  }
};

// Transforms a raw STAS NFT UTXO to the format expected by stas-sdk
export function transformStasUtxo(rawUtxo) {
  return {
    txid: rawUtxo.txid,
    vout: rawUtxo.index,
    satoshis: Number(rawUtxo.amount),
    script: rawUtxo.script
  };
}

// Helper: Generate a locking script from a recipient or wallet.
export const getLockingScript = (input) => {
  const addr = typeof input === 'string' ? input : input.address;
  if (!addr) throw new Error('No address provided for locking script.');
  if (/^(1|3)[a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(addr)) {
    return Script.fromHex(new P2PKH().lock(addr).toHex());
  }
  return Script.fromHex(addr);
};

// Helper: Generate a locking script from a recipient or wallet.
export const getLockingScriptHex = (input) => {
  const addr = typeof input === 'string' ? input : input.address;
  if (!addr) throw new Error('No address provided for locking script.');
  if (/^(1|3)[a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(addr)) {
    return new P2PKH().lock(addr).toHex();
  }
  return addr;
};

// NEW: Extract the public key hash from a P2PKH locking script.
// Example: input "76a91471c44336967019b9e7a46192bce262493cdf4b3688ac"
// returns "71c44336967019b9e7a46192bce262493cdf4b36"
export function getPKHash(scriptHex) {
  if (!scriptHex || typeof scriptHex !== 'string') {
    throw new Error('Invalid script provided.');
  }
  if (!/^76a914[0-9a-fA-F]{40}88ac$/.test(scriptHex)) {
    throw new Error('Not a valid P2PKH locking script.');
  }
  return scriptHex.substring(6, 46);
}

export const estimateFee = (inputs, outputs, config) => {
  // estimating for 4 outputs (3 for fees split, 1 for change)
  const estimatedSize = 10 + 148 + (34 * inputs) + (148*outputs);
  return Math.ceil(estimatedSize / 1024 * config.SATS_PER_KB_FEE); // in sats
}

// Helper: Generate a locking script from a recipient or wallet.
export const isAdmin = (wallet, config) => {
  return config.admins.includes(wallet.pubKeyString);
};
