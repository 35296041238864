import React, { useEffect, useState , useContext} from 'react';
import { NavLink } from 'react-router-dom';
import devApiService from '../services/devApiService';
import { AuthContext } from '../context/AuthProvider';
import '../css/Apps.css';

const Apps = () => {
	const [apps, setApps] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
  const { wallet } = useContext(AuthContext);

	useEffect(() => {
		if(wallet && wallet.privateKey && wallet.address) {
			devApiService.getPublicApps(wallet)
				.then((data) => {
					// Expect data to be an array or an object with apps list according to API response
					if(data && !data.error) {
						// If the response has a wrapper use e.g. data.apps, else use data directly.
						setApps(Array.isArray(data) ? data : data.apps || []);
					} else {
						setError(data.error || 'Failed to load apps');
					}
					setLoading(false);
				})
				.catch((err) => {
					setError(err.message);
					setLoading(false);
				});
		} 
	}, [wallet]);

  return (
    <div className="apps-page p-3">
      {apps.length ? (
        <div className="apps-grid">
          {apps.map(app => (
            <NavLink key={app._id} to={`/apps/${app._id}`} className="app-card">
              <div className="app-cover" style={{ backgroundImage: `url(${app.appCover || '/default-cover.jpg'})` }}>
                <img 
                  src={app.appIcon || '/default-icon.png'} 
                  alt={`${app.appName} icon`} 
                  className="app-card-icon"
                />
              </div>
              <div className="app-card-info">
                <h5>{app.appName}</h5>
                <p className="app-card-category">{app.category}</p>
              </div>
            </NavLink>
          ))}
        </div>
      ) : (
        <p className="empty-message">No apps available.</p>
      )}
    </div>
  );
};

export default Apps;
