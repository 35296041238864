import React, { useState, useEffect, useRef } from 'react';
import stas from 'stas-sdk';
import { P2PKH, Hash, Transaction, Script,PrivateKey } from '@bsv/sdk';
import { Buffer } from 'buffer';
import bsv from 'bsv';
import UTXOSelectorOverlay from './UTXOSelectorOverlay';
import { allocateMintUtxos, transformUtxo, validateUtxo } from '../utils/mintAllocations';
import EmbeddedPost from './EmbeddedPost';
import { generateUniqueId } from '../utils/uniqueId';
// Import the broadcast service from your api.js file in services/
import { broadcastTransactions, createPost, dynamicApiCall, } from '../services/apiOutCalls';
import SHA256 from 'crypto-js/sha256'; // ensure this is imported
import { ConfigContext } from '../config';
import { consolidate,fetch_utxos } from '../utils/consolidateBSV';

import { useGlobalAlert } from './GlobalAlert';
import ShowBalance from '../components/ShowBalance'; 
import {uploadFileToIpfs} from '../services/devApiService';
import VideoPlayer from './VideoPlayer';
/**
 * TokenMintSTAS789 Component
 *
 * This component builds a dynamic form to mint a STAS‑789 token (an onchain NFT)
 * using the stas-sdk. It supports various content types (Text, HTML, Image) and
 * allows the user to add dynamic key/value metadata properties.
 *
 * It creates two transactions:
 *   • Contract Transaction: Created using the top two sorted UTXOs.
 *   • Issuance Transaction: Uses the contract output and the third UTXO.
 *
 * Props:
 *  - wallet: object containing wallet.address and wallet.privateKey (WIF)
 *  - onTransactionComplete: callback invoked with raw transaction hex strings on success
 *  - onMintError: callback invoked on mint failure
 *  - params (optional): an object containing reactionType, reactionNftId,
 *      and reactionSymbol. If provided (and all three properties exist), the component
 *      automatically uses these values and shows an embedded post preview instead of input fields.
 */
const TokenMintSTAS789 = ({ wallet, mintFromCard, onTransactionComplete, onMintError = () => {}, params }) => {
  const { showAlert } = useGlobalAlert();
  const config = React.useContext(ConfigContext);
  
  // New fields for NFT Name and Description.
  const [nftName, setNftName] = useState('');
  const [nftDescription, setNftDescription] = useState('');
  // Content Type: "Text", "HTML", or "Image64"
  const [contentType, setContentType] = useState('Text');
  // For "Text" or "HTML": content string. For "Image64": base64 encoded data.
  const [contentData, setContentData] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  // Dynamic additional metadata as an array of { key, value }
  const [metadataList, setMetadataList] = useState([{ key: '', value: '' }]);
  // Status and error messages.
  const [txStatus, setTxStatus] = useState('');
  const [error, setError] = useState('');
  
  // System Metadata – these normally are filled by the user but can be pre‑set from parent.
  const [reactionType, setreactionType] = useState('');
  const [reactionNftId, setreactionNftId] = useState('');

  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false); // Added to fix ESLint errors
  const [addHeadline, setAddHeadline] = useState(false);
  const inputRef = useRef(null);

  // Remove or ignore the old contentType and contentData states if not used elsewhere.
  const [extraContents, setExtraContents] = useState([]);
  // For image type extra content, we’ll store preview inside extraContents as well.
  // NEW state for extra content upload tracking
  const [extraUploading, setExtraUploading] = useState(false);
  const [extraUploaded, setExtraUploaded] = useState(true);
  // NEW: separate previews state for image files
  const [extraPreviews, setExtraPreviews] = useState([]);

  // NEW: Add state for preview asset
  const [previewAsset, setPreviewAsset] = useState(null); // will hold an object { type, file, CID, preview }
  const [previewUploading, setPreviewUploading] = useState(false);
  const [previewUploaded, setPreviewUploaded] = useState(true); // true if no pending upload

  // Add the available tags list at the top of the component:
  const availableTags = [
    "News",
    "Economy",
    "Business",
    "Politics",
    "Opinion",
    "Science",
    "Travel",
    "Cosmos",
    "Quotes",
    "Memes",
    "Podcast",
    "Technology",
    "Entertainment",
    "History",
    "Sports",
    "Gaming",
  ];

  // NEW: Dynamic parameters
  const [tokenSchema, setTokenSchema] = useState({});
  const [feeContract, setFeeContract] = useState(null);
  const [feeIssuance, setFeeIssuance] = useState(null);
  const [minContract, setMinContract] = useState(null);
  const [dynamicSymbol, setDynamicSymbol] = useState('');
  const [totalFunds, setTotalFunds] = useState(null);

  // If params is provided and complete, set system metadata state.
  useEffect(() => {
    if (
      params &&
      params.reaction &&
      params.reaction.type &&
      params.reaction.contractTx
    ) {
      setreactionType(params.reaction.type);
      setreactionNftId(params.reaction.contractTx);
    }
  }, [params]);

  // File upload handler for Image content.
  const handleFileUpload = (e) => {
    const file = e.target.files && e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setContentData(reader.result);
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // Handlers for dynamic metadata list.
  const handleMetadataChange = (index, field, value) => {
    const updated = [...metadataList];
    updated[index][field] = value;
    setMetadataList(updated);
  };

  const addMetadataField = () => {
    setMetadataList([...metadataList, { key: '', value: '' }]);
  };

  const removeMetadataField = (index) => {
    setMetadataList(metadataList.filter((_, idx) => idx !== index));
  };

  useEffect(() => {
    async function updateDynamicParams() {
      // Build the token schema based on current form state.
      const newTokenSchema = {
        protocolId: "STAS-789",
        description: nftDescription,
        totalSupply: 1,
        satsPerToken: config.SATOSHIS_PER_NFT,
        properties: {
          legal: { 
            licence: config.STAS789_LICENSE_ID, 
            terms: config.STAS789_TERMS 
          },
          issuer: { 
            schemaId: config.STAS789_ISSUER_SCHEMA, 
            details: config.STAS789_ISSUER 
          },
          meta: { schemaId: "STAS1.0" }
        }
      };
      if (nftName) newTokenSchema.name = nftName;
      if (extraContents.length > 0 || tags.length > 0 || (previewAsset && previewAsset.CID)) {
        newTokenSchema.properties.content = {
          ...(tags.length > 0 ? { tags } : {}),
          ...(extraContents.length > 0 ? { 
            passphrase: config.CONTENT_PASSPHRASE, 
            assets: extraContents 
          } : {}),
          ...(previewAsset && previewAsset.CID ? {
            passphrase: config.CONTENT_PASSPHRASE,
            preview_media: [
              {
                type: previewAsset.type,
                CID: previewAsset.CID,
                extension: previewAsset.extension,
                mimetype: previewAsset.mimetype
              }
            ]
          } : {}),
        };
      }
      
      // NEW: Extend content with preview if available
      if (previewAsset && previewAsset.CID) {
        newTokenSchema.properties.content = {
          ...newTokenSchema.properties.content,
          preview_media: [
            {  // changed from a single object to a list with one element
              type: previewAsset.type,
              CID: previewAsset.CID,
              extension: previewAsset.extension,    // should be "jpg" for images
              mimetype: previewAsset.mimetype         // should be "image/jpeg" for images
            }
          ]
        };
      }
      if (reactionType && reactionNftId) {
        newTokenSchema.properties.reaction = {
          type: reactionType,
          contractTx: reactionNftId,
        };
      }
      // Generate a unique symbol.
      const uniqueId = generateUniqueId();
      const sym = uniqueId + SHA256(JSON.stringify(newTokenSchema)).toString().substring(0, 8);
      newTokenSchema.symbol = sym;
      setDynamicSymbol(sym);
      setTokenSchema(newTokenSchema);

      // If we have 3 UTXOs, update fees and symbol dynamically.
        try {
          const feeC = await stas.stasContract.feeEstimate(newTokenSchema, config.SATOSHIS_PER_NFT);
          const dummyTxid = "0000000000000000000000000000000000000000000000000000000000000000";
          const tokenSchemaHex = Buffer.from(JSON.stringify(newTokenSchema), 'utf8').toString('hex');
          const feeI = await stas.stasIssuance.feeEstimate(
            [{
              addr: wallet.address,
              satoshis: config.SATOSHIS_PER_NFT,
              data: []
            }],
            { satoshis: 1, vout: 0, txid: dummyTxid, script: tokenSchemaHex },
            false,
            newTokenSchema.symbol,
            "STAS-789"
          );
          setFeeContract(feeC);
          setFeeIssuance(feeI);
          const minC = newTokenSchema.totalSupply * newTokenSchema.satsPerToken;
          setMinContract(minC);
        } catch (err) {
          console.error("Dynamic fee estimation error:", err);
        }
    }
    updateDynamicParams();
  }, [nftName, nftDescription, metadataList, extraContents, tags, reactionType, reactionNftId, previewAsset]);

  useEffect(() => {
    if (feeContract != null && feeIssuance != null && minContract != null && totalFunds!= null) {

      const totalRequired = feeContract + feeIssuance + minContract;
      if (totalRequired > totalFunds) {
        setError("Posts, Replies or Reposts are considered NFTs, published on Bitcoin SV, and require a fee to mint. You can receive Tips from every NFT you publish, so make it count. Not enough balance to mint a post. Top up your account from the profile page.");
      } else {
        // Optionally clear error if it previously indicated balance issues.
        // if (error && error.includes("Not enough balance")) {
        //   setError("");
        // }
      }
    }
  }, [feeContract, feeIssuance, minContract, totalFunds]);

  // NEW: Function to upload extra content assets to IPFS for both images and long text
  const handleExtraUpload = async () => {
    setExtraUploading(true);
    let allUploaded = true;

    // Combine in-post attachment (previewAsset) and extra content into a single array
    const allContents = [
      ...(previewAsset && !previewAsset.CID ? [{ ...previewAsset, isPreview: true }] : []),
      ...extraContents.filter(item => !item.CID),
    ];

    const updatedContents = await Promise.all(
      allContents.map(async (item) => {
        if (item.CID) return item; // Skip if already uploaded

        try {
          const response = await uploadFileToIpfs(wallet, item.file || item.contentData, config);
          const cid = response?.cid;
          if (!cid) {
            allUploaded = false;
            showAlert("IPFS upload error: No CID returned", "danger");
            return item;
          }

          // Update the item with CID and other metadata
          return {
            ...item,
            CID: cid,
            ...(item.isPreview ? {} : { extension: item.extension, mimetype: item.mimetype }),
          };
        } catch (err) {
          allUploaded = false;
          showAlert(`IPFS upload failed: ${err.message}`, "danger");
          return item;
        }
      })
    );

    // Separate updated preview asset and extra contents
    const updatedPreview = updatedContents.find(item => item.isPreview) || previewAsset;
    const updatedExtraContents = updatedContents.filter(item => !item.isPreview);

    setPreviewAsset(updatedPreview);
    setExtraContents(updatedExtraContents);
    setExtraUploading(false);
    setExtraUploaded(allUploaded);
  };

  // Add new submission state near other state declarations
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Mint process for STAS‑789 (onchain NFT) using stas-sdk.
   */
  const handleMint = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true); // set submission state
    if (nftName.length > config.MAX_HEAD_CHARS) {
      setError(`Headline exceeds maximum allowed ${config.MAX_HEAD_CHARS} characters.`);
      setIsSubmitting(false);
      throw new Error(`nftName length (${nftName.length}) exceeds maximum allowed ${config.MAX_HEAD_CHARS}`);
    }
    if (nftDescription.length > config.MAX_DESC_CHARS) {
      setError(`Description exceeds maximum allowed ${config.MAX_DESC_CHARS} characters.`);
      setIsSubmitting(false);
      throw new Error(`nftDescription length (${nftDescription.length}) exceeds maximum allowed ${config.MAX_DESC_CHARS}`);
    }
    if (!nftDescription) {
      setIsSubmitting(false);
      throw new Error("Some content is required.");
    }

    try {
      setTxStatus("Preparing minting process...");
      
      // Create the required utxo values array.
      const requiredUTxO = [
        { role: 'contractUtxo', satoshis: minContract },
        { role: 'contractFeeUtxo', satoshis: feeContract },
        { role: 'issuanceUtxo', satoshis: feeIssuance }
      ];

      const selectedUTXOList = await fetch_utxos(wallet, requiredUTxO,config);
      
      if (selectedUTXOList.failed) {
        setTxStatus("Error: Unable to obtain enough satoshis");
      }

      // Create the issuance data array.
      const issueData = [{
        addr: wallet.address,
        satoshis: config.SATOSHIS_PER_NFT,
        data: [],
      }];

      const {consolidationTx, consolidatedUTxO} = await consolidate(wallet, selectedUTXOList, requiredUTxO,config);

      const contractUtxo = consolidatedUTxO[0];
      const contractFeeUtxo = consolidatedUTxO[1];
      const issuanceUtxo = consolidatedUTxO[2];

      const issuerPk = bsv.PrivateKey.fromWIF(wallet.privateKey);
      setTxStatus("Creating token contract transaction...");
      const contractTx = await stas.stasContract.signed(
        issuerPk,
        contractUtxo,
        contractFeeUtxo,
        issuerPk,
        tokenSchema,
        config.SATOSHIS_PER_NFT,
        true
      );

      setTxStatus("Contract transaction created. Extracting contract UTXO...");
      const extractedContractUtxo = stas.utility.getUtxoFromTx(contractTx, 0);
      if (!extractedContractUtxo) {
        throw new Error("Failed to extract contract UTXO from contract transaction.");
      }
      
      setTxStatus("Creating issuance transaction...");
      const issuanceTx = await stas.stasIssuance.signed(
        issuerPk,
        issueData,
        extractedContractUtxo,
        issuanceUtxo,
        issuerPk,
        true,
        tokenSchema.symbol,
        "STAS-789",
        true
      );
      
      setTxStatus("Broadcasting transactions...");
      // consolidationTx is built through BSV SDK, contractTx and issuanceTx are stas-sdk objects.
      // Broadcast both transactions.
      const txHexes = [consolidationTx.toHex(), contractTx.toString(), issuanceTx.toString()];
      console.log("txHexes",txHexes);
      const broadcastResults = await broadcastTransactions(wallet,txHexes);
      let outcome;
      if (Array.isArray(broadcastResults) && broadcastResults.length > 0) {
        const failedResult = broadcastResults.find(result => result.error && result.error.code !== 0);
        if (failedResult) {
          outcome = { message: failedResult.error.message };
          showAlert(outcome.message, "danger", "Mint Error", false);

        } else {
          // Use wallet as the parameter for createPost
          try {
            await createPost(wallet, {
                contractTx: broadcastResults[1].txid,
                issueTx: broadcastResults[2].txid
            });
          } catch (error) {
            console.error("Error creating mint post:", error);
          }
          outcome = { txid: broadcastResults.map(result => result.txid).join(', ') };
          showAlert("Your content is getting processed and should be visible on your profile shortly.", "success", "Posted");

        }
      }
      setTxStatus("Broadcast complete.");
      onTransactionComplete(outcome);
      setIsSubmitting(false);
    } catch (err) {
      console.error("Error creating STAS‑789 token:", err);
      setTxStatus("Error: " + err.message);
      setIsSubmitting(false);
      if (typeof onMintError === "function") {
        onMintError(err);
      }
    }
  };

  return (
    <div className="token-mint-form p-0 m-0">
      {/* Add ShowBalance above the form to update totalFunds */}
      <ShowBalance wallet={wallet} onFundsChange={setTotalFunds} />

      {error && <div className="alert alert-danger" style={{ textAlign: 'justify' }}>{error}</div>}

      {/* Replace <form> with a <div> */}
      <form onSubmit={handleMint}>
        

        {/* System Metadata Section */}
        {params &&
          params.reaction &&
          params.reaction.type &&
          params.reaction.contractTx && (
            <div className="mb-3">
              <EmbeddedPost wallet={wallet} mintFromCard={mintFromCard} meta={params.reaction} />
            </div>
          )}

        {/* {addHeadline  ? (
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            value={nftName}
            onChange={(e) => setNftName(e.target.value)}
            placeholder='Enter a headline'
          />

          <div className={`form-text ${nftName.length > config.MAX_HEAD_CHARS ? 'text-danger' : 'text-muted'}`}>
            {nftName.length <= config.MAX_HEAD_CHARS
              ? `${config.MAX_HEAD_CHARS - nftName.length} characters remaining`
              : `${nftName.length - config.MAX_HEAD_CHARS} characters over limit`}
          </div>
        </div>
        ) : (
          <span
            style={{ cursor: "pointer", color: "#999", marginBottom: "10px", display: "block" }}
            onClick={() => setAddHeadline(true)}
          >
            Click to + headline (optional)
          </span>
        )} */}
        <div className="mb-3">
          <textarea
            className="form-control"
            value={nftDescription}
            onChange={(e) => setNftDescription(e.target.value)}
            placeholder="Express here your thoughts"
            rows="3"
            required
          ></textarea>
          <div className={`form-text ${nftDescription.length > config.MAX_DESC_CHARS ? 'text-danger' : 'text-muted'}`}>
            {nftDescription.length <= config.MAX_DESC_CHARS
              ? `${config.MAX_DESC_CHARS - nftDescription.length} characters remaining`
              : `${nftDescription.length - config.MAX_DESC_CHARS} characters over limit`}
          </div>
        </div>
        
        <div className="mb-3">
          <div
            className="form-control d-flex flex-wrap align-items-center"
            style={{ minHeight: "38px", cursor: "text", position: "relative" }}
            onClick={() => inputRef.current && inputRef.current.focus()}
          >
            {tags.map((tag, index) => (
              <span key={index} className="badge bg-primary me-1 mb-1">
                {tag}
                <button
                  type="button"
                  className="btn-close btn-close-white btn-sm ms-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setTags(tags.filter((_, i) => i !== index));
                  }}
                ></button>
              </span>
            ))}
            <input
              ref={inputRef}
              type="text"
              className="border-0 flex-grow-1"
              style={{ outline: "none" }}
              placeholder="Type a tag..."
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              onFocus={() => setShowSuggestions(true)}
              onBlur={() => setTimeout(() => setShowSuggestions(false), 100)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && tagInput.trim() !== '') {
                  e.preventDefault();
                  if (!tags.includes(tagInput.trim())) {
                    setTags([...tags, tagInput.trim()]);
                  }
                  setTagInput('');
                }
              }}
            />
            {showSuggestions && (
              <ul className="suggestions-dropdown" style={{
                  position: 'absolute',
                  bottom: '100%', // changed from top to bottom to display dropup
                  left: 0,
                  right: 0,
                  background: '#fff',
                  border: '1px solid #ccc',
                  zIndex: 10,
                  listStyle: 'none',
                  padding: '0',
                  margin: '0',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  maxHeight: '200px', // Set a max height
                  overflowY: 'auto',  // Enable scrolling when content exceeds the max height
              }}>
                {availableTags.filter(tag =>
                  tag.toLowerCase().includes(tagInput.toLowerCase()) && !tags.includes(tag)
                ).map(tag => (
                  <li key={tag} style={{ padding: '4px 8px', cursor: 'pointer' }}
                    onMouseDown={() => {
                      setTags([...tags, tag]);
                      setTagInput('');
                    }}
                  >
                    {tag}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {/* new: Suggestion message for tags */}
          <div className="form-text">
            Highly suggested so your content gets found
          </div>
        </div>
        {/* NEW: Preview Attachment UI */}
        <div className="mb-3">
          <label className="form-label mb-0 alert alert-light p-2">In-post Attachment</label>
          <small className="text-muted d-block mb-2">
            This single media attachment will be displayed in the post. Only landscape or square images are supported at the moment.
          </small>
          {previewAsset && previewAsset.preview ? (
            <div className="mb-2 border rounded p-2">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <strong>Preview</strong>
                <button 
                  type="button" 
                  className="btn-close" 
                  onClick={() => {
                    setPreviewAsset(null);
                    setPreviewUploaded(true);
                  }}
                ></button>
              </div>
              { previewAsset.type === 'video' ?
                <VideoPlayer 
                  src={previewAsset.preview} 
                  style={{ maxWidth: '100%'}}
                /> :
                <img src={previewAsset.preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
              }
            </div>
          ) : (
            <input 
              type="file" 
              accept="image/*" 
              className="form-control" 
              onChange={async e => {
                e.preventDefault(); // NEW: Prevent default submission behavior in iOS Safari
                e.stopPropagation(); // NEW: Stop propagation if needed
                const file = e.target.files[0];
                if(file) {
                  try {
                    const result = await processPreviewFile(file);
                    const previewURL = URL.createObjectURL(result.blob || file);
                    setPreviewAsset({
                      type: result.type,
                      file: result.blob 
                        ? new File(
                            [result.blob], 
                            file.name.replace(/\.[^.]+$/, result.type === 'image' ? '.jpg' : (result.type === 'video' ? '.mp4' : '')), 
                            { type: result.type === 'image' ? 'image/jpeg' : (result.type === 'video' ? 'video/mp4' : file.type) }
                          )
                        : file,
                      preview: previewURL,
                      ...(result.type === 'image'
                          ? { extension: 'jpg', mimetype: 'image/jpeg' }
                          : result.type === 'video'
                            ? { extension: 'mp4', mimetype: 'video/mp4' }
                            : {})
                    });
                    setPreviewUploaded(false);
                  } catch (err) {
                    showAlert("Media processing error: " + err.message, "danger");
                  }
                }
              }}
            />
          )}
          {/* {previewAsset && previewAsset.file && (
          <div className="mt-2">
            <button 
              type="button" 
              className="btn btn-secondary btn-sm" 
              onClick={async (e) => {
                e.preventDefault(); // Prevent default behavior
                e.stopPropagation(); // Stop propagation to parent elements
                if (!previewAsset || !previewAsset.file) return;
                setPreviewUploading(true);
                try {
                  const response = await uploadFileToIpfs(wallet, previewAsset.file, config);
                  const cid = response?.cid;
                  if (!cid) throw new Error("No CID returned");
                  setPreviewAsset(prev => ({ ...prev, CID: cid }));
                  setPreviewUploaded(true); 
                } catch (err) {
                  showAlert("Media upload failed: " + err.message, "danger");
                  setPreviewUploaded(false);
                } finally {
                  setPreviewUploading(false);
                }
              }}
              disabled={previewUploading || previewUploaded}
            >
              { previewUploading ? "Uploading Media..." : (previewUploaded ? "Media Uploaded" : "Upload Media") }
              </button>
            </div>
            )} */}
        </div>
        {config.ENABLE_INPOST_MEDIA && (
        <div className="mb-3">
          <label className="form-label mb-0 alert alert-light p-2">Metanet Blogs</label>
          <small className="text-muted d-block mb-2">
            Wanna add ordered images, text blocks to create something special? This content will be displayed in the expanded <i className='fa fa-arrows-alt'></i> view of the post!
          </small>
          { extraContents.length < 10 ? (
            <div className="d-flex mb-2">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm me-2"
                onClick={() => {
                  setExtraContents([...extraContents, { type: 'long_text', contentData: '' }]);
                  setExtraUploaded(false);
                }}
              >
                + Text Block
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm me-2"
                onClick={() => {
                  setExtraContents([...extraContents, { type: 'image', contentData: '', preview: '' }]);
                  setExtraUploaded(false);
                }}
              >
                + Image
              </button>
              {/* <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                onClick={() => {
                  setExtraContents([...extraContents, { type: 'video', file: null, preview: '' }]);
                  setExtraUploaded(false);
                }}
              >
                + Video
              </button> */}
            </div>
          ) : (
            <div className="mb-2 text-muted">
              Maximum extra content reached. Remove some to add more.
            </div>
          )}
          {extraContents.map((item, index) => (
            <div key={index} className="mb-2 border rounded p-2">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <strong>{item.type === 'long_text' ? 'Text Block' : item.type === 'video' ? 'Video' : 'Image'}</strong>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    const newContents = extraContents.filter((_, i) => i !== index);
                    setExtraContents(newContents);
                    const newPreviews = extraPreviews.filter((_, i) => i !== index);
                    setExtraPreviews(newPreviews);
                    if (newContents.length === 0) setExtraUploaded(true);
                  }}
                ></button>
              </div>
              { item.type === 'long_text' ? (
                <textarea
                  className="form-control"
                  placeholder="Enter long text..."
                  value={item.contentData}
                  onChange={(e) => {
                    const newContents = [...extraContents];
                    newContents[index].contentData = e.target.value;
                    setExtraContents(newContents);
                    setExtraUploaded(false);
                  }}
                  rows="3"
                ></textarea>
              ) : item.type === 'video' ? (
                <>
                  {(extraPreviews[index] || item.CID) ? (
                    <video src={extraPreviews[index] || ""} controls style={{ maxWidth: '100%', maxHeight: "50px", objectFit: "cover", marginTop: "10px" }}></video>
                  ) : (
                    <input
                      type="file"
                      accept="video/mp4"
                      className="form-control"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const file = e.target.files[0];
                        if (file) {
                          const fileURL = URL.createObjectURL(file);
                          const newContents = [...extraContents];
                          newContents[index] = { type: 'video', file, preview: fileURL };
                          setExtraContents(newContents);
                          const newPreviews = [...extraPreviews];
                          newPreviews[index] = fileURL;
                          setExtraPreviews(newPreviews);
                          setExtraUploaded(false);
                        }
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  {(extraPreviews[index] || item.CID) ? (
                    <img src={extraPreviews[index] || ""} alt="Thumbnail" style={{ maxWidth: '100%', maxHeight: "50px", objectFit: "cover", marginTop: "10px" }} />
                  ) : (
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const file = e.target.files[0];
                        if (file) {
                          const fileURL = URL.createObjectURL(file);
                          const extension = file.name.split('.').pop();
                          const mimetype = file.type;
                          const newContents = [...extraContents];
                          newContents[index] = { type: 'image', file, extension, mimetype };
                          setExtraContents(newContents);
                          const newPreviews = [...extraPreviews];
                          newPreviews[index] = fileURL;
                          setExtraPreviews(newPreviews);
                          setExtraUploaded(false);
                        }
                      }}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        )}
        
        {/* NEW: Show Upload Extra Content button when extra contents exist and have not been processed */}
        {((extraContents.length > 0 && !extraUploaded) || (previewAsset && !previewAsset.CID)) && (
          <div className="d-flex justify-content-end mb-3">
            <button type="button" className="btn btn-secondary" onClick={handleExtraUpload} disabled={extraUploading}>
              { extraUploading ? "Uploading..." : "Upload Media Assets" }
            </button>
          </div>
        )}

        {/* --- UTXO Selection Button --- */}
        <div className="d-flex justify-content-end">
          {/* Only show Publish NFT Post when extra content has been uploaded or none exists */}
          { (extraContents.length === 0 || extraUploaded) && (!previewAsset || previewAsset.CID) && (
            <button disabled={!totalFunds || isSubmitting} type="submit" className="btn btn-primary">
              {isSubmitting ? "Posting..." : "Publish NFT Post"}
            </button>
          )}
        </div>
      </form>
      {txStatus && <div className="alert alert-info mt-3">{txStatus}</div>}
      
    </div>
  );
};

// NEW: Add helper to process preview file
async function processPreviewFile(file) {
  if (file.type.startsWith('image/')) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function(e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function() {
          // Reject if image is vertical (height greater than width)
          if (img.height > img.width) {
            reject(new Error("Image must be landscape or square (vertical images are not allowed)"));
            return;
          }
          const canvas = document.createElement('canvas');
          const maxWidth = 1024;
          let width = img.width;
          let height = img.height;
          if (width > maxWidth) {
            height = height * (maxWidth / width);
            width = maxWidth;
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(blob => {
            if (blob) {
              resolve({ blob, type: 'image' });
            } else {
              reject(new Error("Canvas conversion failed"));
            }
          }, 'image/jpeg', 0.95);
        };
        img.onerror = reject;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  } else if (file.type === 'video/mp4') {
    return new Promise((resolve, reject) => {
      // Reject if file size exceeds 20MB.
      if (file.size > 20 * 1024 * 1024) {
        reject(new Error("Video file size exceeds 20MB"));
        return;
      }
      const url = URL.createObjectURL(file);
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function() {
        URL.revokeObjectURL(url);
        // Reject if video is vertical (height greater than width).
        if (video.videoHeight > video.videoWidth) {
          reject(new Error("Video must be square or horizontal (vertical videos are not allowed)"));
          return;
        }
        if (video.duration > 30) {
          reject(new Error("Video exceeds 30 seconds"));
          return;
        }
        resolve({ file, type: 'video' });
      };
      video.onerror = function() {
        reject(new Error("Error loading video"));
      };
      video.src = url;
    });
  } else {
    return Promise.reject(new Error("Unsupported file type"));
  }
}

export default TokenMintSTAS789;
