import React, { useState, useCallback, useEffect } from 'react';
import '../css/UTXOTable.css';
import { dynamicApiCall } from '../services/apiOutCalls';

const UTXOTable = ({ wallet, onSelectionChange, requiredAmount, requiredUTXOs, showOnly }) => {
  const [utxos, setUtxos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUtxos, setSelectedUtxos] = useState([]);
  const [autoSortDone, setAutoSortDone] = useState(false);
  const utxosPerPage = 7;

  // Helper: truncate long tx_hash for display.
  const truncateHash = (hash) => {
    if (!hash || hash.length <= 12) return hash;
    return `${hash.substring(0, 9)}*********${hash.substring(hash.length - 9)}`;
  };

  // Determine UTXO status.
  const getStatusText = (utxo) => {
    if (utxo.status === "confirmed") {
      return utxo.isSpentInMempoolTx ? "outgoing" : "confirmed";
    }
    return "incoming";
  };

  // New helper: assign sort order for status
  function getStatusOrder(utxo) {
    const status = getStatusText(utxo);
    if (status === "confirmed") return 1;
    if (status === "incoming") return 2;
    if (status === "outgoing") return 3;
    return 4;
  }

  // Auto-select confirmed UTXOs based on a required amount.
  const selectUTXOsForRequiredAmount = (fetched) => {
    // Sort fetched UTXOs by status order then by value descending.
    const sortedFetched = [...fetched].sort((a, b) => {
      const orderA = getStatusOrder(a);
      const orderB = getStatusOrder(b);
      return orderA !== orderB ? orderA - orderB : b.value - a.value;
    });
    setUtxos(sortedFetched);

    const amountRequiredValue = parseInt(requiredAmount, 10);
    let sum = 0;
    const autoSelected = [];
    for (const utxo of sortedFetched) {
      if (getStatusText(utxo) !== "outgoing") {
        autoSelected.push(utxo);
        sum += utxo.value;
        if (sum >= amountRequiredValue) break;
      }
    }
    setSelectedUtxos(autoSelected);
    onSelectionChange && onSelectionChange(autoSelected);
  };

  // Auto-select confirmed UTXOs based on requiredUTXOs list.
  const selectUTXOsForRequiredUTXOs = (fetched) => {
    // First, sort all fetched UTXOs by status then by value descending.
    const statusOrder = { 'confirmed': 0, 'outgoing': 1, 'incoming': 2 };
    const sortedFetched = [...fetched].sort((a, b) => {
      const orderA = statusOrder[getStatusText(a)] ?? 99;
      const orderB = statusOrder[getStatusText(b)] ?? 99;
      if (orderA !== orderB) {
        return orderA - orderB;
      } else {
        return b.value - a.value;
      }
    });
    // Set the table ordering with the sorted result.
    setUtxos(sortedFetched);

    // Process requiredUTXOs (sorted descending) against confirmed ones only.
    const reqs = [...requiredUTXOs].sort((a, b) => b - a);
    // Filter out confirmed UTXOs from sorted list.
    const confirmed = sortedFetched.filter(
      (utxo) => getStatusText(utxo) !== "outgoing"
    );
    const autoSelected = [];
    for (const req of reqs) {
      for (let i = 0; i < confirmed.length; i++) {
        if (confirmed[i].value >= req) {
          autoSelected.push(confirmed[i]);
          confirmed.splice(i, 1); // remove used UTXO
          break;
        }
      }
    }
    setSelectedUtxos(autoSelected);
    onSelectionChange && onSelectionChange(autoSelected);
  };

  // Fetch UTXOs and auto-select based on provided criteria.
  const fetchUTXOs = useCallback(() => {
    if (!wallet) return;
    setLoading(true);
    setError(null);
    dynamicApiCall(wallet, { action: "getAllUTxO", address: wallet.address })
      .then((res) => {
        const fetched = res.data;
        setUtxos(fetched);
        setCurrentPage(1);
        if (requiredUTXOs && Array.isArray(requiredUTXOs) && requiredUTXOs.length > 0) {
          selectUTXOsForRequiredUTXOs(fetched);
        } else {
          selectUTXOsForRequiredAmount(fetched);
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [wallet, requiredAmount, requiredUTXOs]);

  // Automatically fetch UTXOs once when component mounts or when address changes.
  useEffect(() => {
    fetchUTXOs();
  }, [fetchUTXOs]);

  // Pagination calculations.
  const indexOfLast = currentPage * utxosPerPage;
  const indexOfFirst = indexOfLast - utxosPerPage;
  const currentUtxos = utxos.slice(indexOfFirst, indexOfLast);
  const totalPages = Math.ceil(utxos.length / utxosPerPage);

  // Handle manual checkbox selection.
  const handleCheckboxChange = (utxo, checked) => {
    let newSelection;
    if (checked) {
      newSelection = [...selectedUtxos, utxo];
    } else {
      newSelection = selectedUtxos.filter(
        (item) =>
          item.tx_hash !== utxo.tx_hash || item.tx_pos !== utxo.tx_pos
      );
    }
    setSelectedUtxos(newSelection);
    onSelectionChange && onSelectionChange(newSelection);
  };

  // Bulk action for required amount.
  const selectUTXOsForRequired = () => {
    // If requiredUTXOs list is provided, auto-select based on that, otherwise use requiredAmount.
    if (requiredUTXOs && Array.isArray(requiredUTXOs) && requiredUTXOs.length > 0) {
      selectUTXOsForRequiredUTXOs(utxos);
    } else {
      selectUTXOsForRequiredAmount(utxos);
    }
  };

  // Handle bulk select options.
  const handleSelectOptionChange = (e) => {
    const option = e.target.value;
    if (option === "unselect") {
      setSelectedUtxos([]);
      onSelectionChange && onSelectionChange([]);
    } else if (option === "selectCurrent") {
      const selectableCurrent = currentUtxos.filter(
        (utxo) => getStatusText(utxo) !== "outgoing"
      );
      setSelectedUtxos(selectableCurrent);
      onSelectionChange && onSelectionChange(selectableCurrent);
    } else if (option === "selectAll") {
      const selectableAll = utxos.filter(
        (utxo) => getStatusText(utxo) !== "outgoing"
      );
      setSelectedUtxos(selectableAll);
      onSelectionChange && onSelectionChange(selectableAll);
    } else if (option === "selectRequired") {
      selectUTXOsForRequired();
    }
  };

  // Sort UTXOs based on status order
  const sortedUtxos = utxos.sort((a, b) => getStatusOrder(a) - getStatusOrder(b));

  return (
    <div className="utxo-table">
      {loading && <div>Fetching UTXOs...</div>}
      {error && <div className="alert alert-danger mt-2">Error: {error}</div>}
      {(!loading && utxos.length === 0) && (
        <div className="no-utxos text-center">No UTxO fetched.</div>
      )}
      {utxos.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>
                    <select className="form-select form-select-sm" onChange={handleSelectOptionChange}>
                      <option value="">-- Select Option --</option>
                      <option value="unselect">Unselect All</option>
                      <option value="selectCurrent">Select Current Page</option>
                      <option value="selectAll">Select All Pages</option>
                      <option value="selectRequired">Select Required</option>
                    </select>
                  </th>
                  <th>Value (sats)</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {sortedUtxos.map((utxo) => (
                  <React.Fragment key={`${utxo.tx_hash}-${utxo.tx_pos}`}>
                    <tr className="data-row">
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleCheckboxChange(utxo, e.target.checked)}
                          checked={selectedUtxos.some(item =>
                            item.tx_hash === utxo.tx_hash && item.tx_pos === utxo.tx_pos
                          )}
                          disabled={getStatusText(utxo) === "outgoing"}
                        />
                      </td>
                      <td>{utxo.value}</td>
                      <td>
                        <span className={`status-badge ${getStatusText(utxo)}`}>
                          {getStatusText(utxo)}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-controls d-flex justify-content-between align-items-center">
            <button
              className="btn btn-secondary btn-sm"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(prev => prev - 1)}
            >
              Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button
              className="btn btn-secondary btn-sm"
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(prev => prev + 1)}
            >
              Next
            </button>
          </div>
        </>
      )}
     
    </div>
  );
};

export default UTXOTable;
