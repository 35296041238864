import React, { useState } from 'react';
import UTXOTable from './UTXOTable';

// Modified UTXOSelectorOverlay component.
const UTXOSelectorOverlay = ({ wallet, allocatedTotal, requiredUTXOs, onClose, onSelectionComplete, showOnly }) => {
  const [selectedUTXOs, setSelectedUTXOs] = useState([]);

  const handleSelectionChange = (utxos) => {
    setSelectedUTXOs(utxos);
  };

  return (
    <div
      className="utxo-overlay"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.6)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div className="overlay-content" style={{ background: '#fff', padding: '1rem', borderRadius: '8px', width: '90%', maxWidth: '800px', maxHeight: '90%', overflowY: 'auto' }}>
        <div className="overlay-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <h4>Wallet UTxO</h4>
          <button className="close-btn" onClick={onClose} style={{ background: 'none', border: 'none', fontSize: '1.5rem', cursor: 'pointer' }}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className="overlay-body" style={{ marginBottom: '1rem' }}>
          <UTXOTable
            wallet={wallet}
            requiredAmount={allocatedTotal} // allocatedTotal is passed as amountRequired.
            requiredUTXOs={requiredUTXOs}
            onSelectionChange={handleSelectionChange}
            showOnly={true}
          />
        </div>
        {!showOnly && (
          <div className="overlay-footer" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button className="btn btn-secondary me-2" onClick={onClose}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={() => onSelectionComplete(selectedUTXOs)}>
              Confirm {selectedUTXOs.reduce((acc, utxo) => acc + utxo.value, 0)} sats
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UTXOSelectorOverlay;