class AppSignaler {
  constructor(targetWindow) {
    this.targetWindow = targetWindow;
  }
  
  postMessage(payload) {
    // Always include payload and command "ninja-app-reply"
    const msg = {
      ...payload,
      command: "ninja-app-command"
    };
    if (this.targetWindow && this.targetWindow.postMessage) {
      this.targetWindow.postMessage(msg, "*");
      console.log("AppSignaler posted message:", msg);
    } else {
      console.error("AppSignaler: No valid target window to post message");
    }
  }
  
  // Instance method to listen only for "ninja-app-command" from our target window.
  listen(callback) {
    const handler = (event) => {
      if (event.source === this.targetWindow && event.data && event.data.command === "ninja-app-command") {
        callback(event.data);
      }
    };
    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }
}

export default AppSignaler;
