import { Hash, PublicKey, P2PKH } from '@bsv/sdk';
import * as bs58 from 'bs58';
import { getPKHash } from './mintAllocations';

// Reusable function that computes HASH160 of a hex string,
// i.e. RIPEMD160(SHA256(Buffer.from(input, 'hex'))).
export default function hash160(hexString) {
  const buffer = Buffer.from(hexString, 'hex');
  const sha256Buffer = Hash.sha256(buffer);
  const ripemd160Buffer = Hash.ripemd160(sha256Buffer);
  return Buffer.from(ripemd160Buffer).toString('hex');
}


export function sha256(hexString) {
  const buffer = Buffer.from(hexString, 'hex');
  const sha256Buffer = Hash.sha256(buffer);
  return Buffer.from(sha256Buffer).toString('hex');
}

// Utility: derive address from pub key string (same as before)
export function getAddressFromKey(pubKeyString, networkVersion = 0x00) {
  return PublicKey.fromString(pubKeyString).toAddress();
}

export function getAddressFromPKHash(ripemd160Hex, networkVersion = 0x00) {
  const ripemd160Buffer = Buffer.from(ripemd160Hex, 'hex');
  const payload = Buffer.concat([Buffer.from([networkVersion]), ripemd160Buffer]);
  const firstSha = Hash.sha256(payload);
  const secondSha = Hash.sha256(Buffer.from(firstSha, 'hex'));
  const checksum = Buffer.from(secondSha, 'hex').slice(0, 4);
  const addressBytes = Buffer.concat([payload, checksum]);
  return bs58.default.encode(addressBytes);
}

export function getPKHashFromAddress(address) {
  return getPKHash(new P2PKH().lock(address).toHex());
}