import React from 'react';
import '../css/InfoPages.css';
import Accordion from 'react-bootstrap/Accordion';
import { ConfigContext } from '../config';

const FAQ = () => {
  const config = React.useContext(ConfigContext);
  return (
    <div className="info-page">
      <h1>Frequently Asked Questions</h1>
      <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
          <Accordion.Header>Is {config.BRAND_NAME} a $BSV Wallet?</Accordion.Header>
          <Accordion.Body>
          NO! Yet, in a broader sense, it could be interpreted as a wallet. Wallets are not exclusively meant to store cash—you can also store your ID card or business cards in a wallet. Similarly, {config.BRAND_NAME} is a place to bring digital assets together, connect with people, and share opportunities. {config.BRAND_NAME} supports $BSV for payments as well as STAS-20 tokens that any user can create and exchange. Payments and the various tokens we support—or may support in the future—are only meant to enhance the user experience and not to be the primary focus of the platform. We are a wallet of content worth sharing and connections worth nurturing. With $5-$10 you can create hundreds of NFTs and Tip people. Do not use Metanet.ninja as a wallet. We are not responsible for your funds. Fund your Metanet.ninja wallet as you go, with small amounts, just sufficient to post and Tip what or who you like. Fund at your own risk.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>How do I express my creativity and own my posts?</Accordion.Header>
          <Accordion.Body>
            On {config.BRAND_NAME}, every post you make is truly yours. The platform is powered by the Bitcoin SV protocol, so while your creative ideas, passions, and content are displayed publicly, you maintain complete ownership without interference.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>How do I monetize my ideas and passions?</Accordion.Header>
          <Accordion.Body>
            {config.BRAND_NAME} enables seamless payments and monetization using the Bitcoin protocol. By connecting with like-minded individuals, you can receive tips, micropayments, and other forms of support—all while retaining full control over your creative output.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>How secure is my content on the platform?</Accordion.Header>
          <Accordion.Body>
            The security of your content on {config.BRAND_NAME} is underpinned by the robust Bitcoin SV network along with the safety measures of your own device, browser, and key backups. We provide the tools for expression and connection; ensuring complete data security is in your hands.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>What is {config.BRAND_NAME} / Internet 3.0?</Accordion.Header>
          <Accordion.Body>
            {config.BRAND_NAME} is a decentralized platform that lets you express your creativity, ideas, and passions. You own everything you post and seamlessly connect with others while monetizing your content where possible. It’s Internet 3.0 reimagined.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>How can I manage my content?</Accordion.Header>
          <Accordion.Body>
            With {config.BRAND_NAME}, you have total control over your creative output. Use our intuitive tools to publish, share, and engage with your audience—all without surrendering ownership of your content.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Who offers support for issues I may face?</Accordion.Header>
          <Accordion.Body>
            We ensure the platform runs smoothly so you can focus on expressing your ideas and connecting with others. However if you encounter any issues, the team is here to help. Reach out to us for prompt assistance.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>What technologies empower {config.BRAND_NAME}?</Accordion.Header>
          <Accordion.Body>
            {config.BRAND_NAME} leverages the Bitcoin SV protocol to ensure secure, on-chain transactions while giving you the freedom to express yourself. We provide a seamless, payments-enabled experience that lets you focus on creativity.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>Is it safe to store large amounts of money on {config.BRAND_NAME}?</Accordion.Header>
          <Accordion.Body>
            {config.BRAND_NAME} is a hub for creative expression and content sharing, not a full-service bank. As a beta platform, we strongly advise transferring only minimal amounts needed for posts and activity. Please withdraw your earnings promptly to secure wallets provided by specialised wallet providers.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Need Assistance?</h5>
          <p className="card-text">
            If you have questions or require support, please reach out to us at <a href="mailto:{config.SUPPORT_EMAIL}">{config.SUPPORT_EMAIL}</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
