import {useContext} from 'react';
import { getLockingScript, estimateFee } from '../utils/mintAllocations';
import { P2PKH, Transaction,PrivateKey } from '@bsv/sdk';
import { dynamicApiCall } from '../services/apiOutCalls';

export async function fetch_utxos(wallet, required,config) {
      // NEW: Consolidation logic for fee UTXOs
      const requiredValue = required.reduce((acc, utxo) => acc + utxo.satoshis, 0);
      let reqVal = 0;
      const utxoResult = await dynamicApiCall(wallet,{ action: "getConfirmedUnspent", address: wallet.address });
      if (!utxoResult.success) {
        return {failed: true};
      }
      let utxos = utxoResult.data;
      utxos.sort((a, b) => a.value - b.value);
      let acc = 0;
      let selectedUTXOList = [];
      for (const utxo of utxos) {
        selectedUTXOList.push({
          tx_hash: utxo.tx_hash,
          tx_pos: utxo.tx_pos,
          value: utxo.value,
        });
        acc += utxo.value;
        const feeEstimate = estimateFee(4, selectedUTXOList.length,config);
        reqVal = requiredValue + feeEstimate;
        if (acc >= reqVal) break;
      }

      if (acc < reqVal) {
        return {failed: true};
      }

      return selectedUTXOList;
}

export async function consolidate(wallet, inputs, outputs, config) {
    const consolidationTx = new Transaction();
    const privKey = PrivateKey.fromWif(wallet.privateKey);
    let acc = 0;
    let requiredValue = 0;
    // Add all selected UTXOs as inputs
    for (const utxo of inputs) {
      consolidationTx.addInput({
        sourceTXID: utxo.tx_hash,
        sourceOutputIndex: utxo.tx_pos,
        unlockingScriptTemplate: new P2PKH().unlock(
          privKey,
          'all',
          false,
          utxo.value,
          getLockingScript(wallet)
        )
      });
      acc += utxo.value;
    }
    for (const utxo of outputs) {
      consolidationTx.addOutput({
        satoshis: utxo.satoshis,
        lockingScript: getLockingScript(wallet.address)
      });
      requiredValue += utxo.satoshis;
    }
  
    // Calculate fee
    const fee = estimateFee(inputs.length, outputs.length,config);

    // Calculate change and add if needed
    const change = acc - requiredValue - fee;
    if (change > config.ACCEPTABLE_DUST_FEE) {
      consolidationTx.addOutput({
        satoshis: change,
        lockingScript: getLockingScript(wallet.address)
      });
    }
    await consolidationTx.sign();
    const consolidatedUTxO = consolidationTx.outputs.slice(0, outputs.length).map((output, index) => ({
      txid: consolidationTx.id('hex'),
      vout: index,
      satoshis: output.satoshis,
      script: output.lockingScript.toHex()
    }));

    return {consolidationTx,consolidatedUTxO};
};