import React from 'react';
import '../css/InfoPages.css';
import Accordion from 'react-bootstrap/Accordion';
import { ConfigContext } from '../config';
import { NavLink } from 'react-router-dom';

const ToS = () => {
  const config = React.useContext(ConfigContext);
  return (
    <div className="info-page">
      <h1>Terms of Service</h1>
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>User Responsibilities & Disclaimers</Accordion.Header>
          <Accordion.Body>
            By using {config.BRAND_NAME}, you acknowledge and agree that this platform acts solely as a facilitator connecting users for the purpose of asset exchange and monetization. {config.BRAND_NAME} provides you with tools to publish and share content on a public ledger and to transact via the Bitcoin protocol. However, all management of your content, identity, private keys, and personal security remains entirely your responsibility. You are solely responsible for protecting your device, operating system, browser, and any backups of your private keys. {config.BRAND_NAME} does not offer any guarantees or assume any liability for the security, storage, or integrity of your digital assets or content. Do not use Metanet.ninja as a wallet. BSV allows for instant micropayments. Fund Metanet.ninja wallet as you go, with tiny quantities to post and connect with others. Fund at your own risk.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Our Role and Fee Structure</Accordion.Header>
          <Accordion.Body>
            {config.BRAND_NAME} is designed to connect creators and users by linking their digital assets and facilitating instant payments using the Bitcoin protocol. We provide the infrastructure and tools necessary to enable seamless transactions, including integrated tipping and micropayment features. In exchange for these services, {config.BRAND_NAME} retains a nominal fee on each successful transaction to support platform maintenance, innovation, and connectivity. All fees and rates will be clearly communicated and are subject to change without notice.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Limitations of Liability</Accordion.Header>
          <Accordion.Body>
            The services provided by {config.BRAND_NAME} are offered on an "as is" basis. We do not guarantee uninterrupted or error-free operation of the platform, nor do we guarantee the security or integrity of any content or transaction. {config.BRAND_NAME} shall not be liable for any loss or damage—whether direct, indirect, or consequential—arising from your use of, or inability to use, our platform. This includes, without limitation, any claims based on errors, omissions, interruptions, defects, delays in operation or transmission, computer viruses, or loss of goodwill.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Indemnification</Accordion.Header>
          <Accordion.Body>
            You agree to indemnify, defend, and hold harmless {config.BRAND_NAME}, its affiliates, and any of its respective officers, directors, agents, or employees from and against any claims, liabilities, damages, losses, costs or expenses, including reasonable attorneys’ fees, arising out of or in any way connected with your use of the platform, your violation of these terms, or your infringement of any intellectual property or other right of any person or entity.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Changes to Terms</Accordion.Header>
          <Accordion.Body>
            {config.BRAND_NAME} reserves the right to modify or update these Terms of Service at any time without prior notice. It is your responsibility to review the terms periodically for any changes. By continuing to use the platform after revisions are made, you agree to be bound by the updated terms.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Non-Responsibility Over Funds</Accordion.Header>
          <Accordion.Body>
            Users acknowledge that {config.BRAND_NAME} is provided as a platform for creative and content-based interaction and not as a custodial service. You are solely responsible for your funds. We recommend transferring only small amounts for platform activities and promptly withdrawing any earnings to secure, specialised wallet providers. {config.BRAND_NAME} assumes no responsibility for any loss of funds stored on the platform.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Agreement Reminder</h5>
          <p className="card-text">
            By accessing and using {config.BRAND_NAME}, you confirm that you have read, understood, and agree to be bound by these Terms of Service. Please review them regularly as we continuously enhance our platform.
          </p>
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Privacy</h5>
          <p className="card-text">
          We value your privacy. For detailed information on how we protect your data, please visit our dedicated{' '}
          <NavLink to="/privacy">Privacy</NavLink> page.
          </p>
        </div>
      </div>
      
    </div>
  );
};

export default ToS;
