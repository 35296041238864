import React, { useState, useEffect } from 'react';
import { verifiedFetch } from '@helia/verified-fetch';
import CryptoJS from 'crypto-js';

const DecryptedText = ({ cid, passphrase }) => {
  const [text, setText] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!cid || !passphrase) return;
    const url = cid.startsWith('ipfs://') ? cid : `ipfs://${cid}`;
    verifiedFetch(url)
      .then(async response => {
        if (!response.ok) throw new Error('Failed to fetch encrypted text');
        const encryptedText = await response.text();
        const bytes = CryptoJS.AES.decrypt(encryptedText, passphrase);
        let decrypted = bytes.toString(CryptoJS.enc.Utf8);
        const prefix = "data:text/plain;base64,";
        if (decrypted.startsWith(prefix)) {
          const base64Content = decrypted.slice(prefix.length);
          decrypted = window.atob(base64Content);
        }
        setText(decrypted);
      })
      .catch(err => setError(err.message));
  }, [cid, passphrase]);

  if (error) return <div>Error: {error}</div>;
  if (!text) return <div className="card-text placeholder-glow p-3">  
  <h5 className="card-title placeholder-glow">
      <span className="placeholder col-6"></span>
  </h5>
  <span className="placeholder col-7"></span>
  <span className="placeholder col-4"></span>
  <span className="placeholder col-4"></span>
  <span className="placeholder col-6"></span>
  <span className="placeholder col-8"></span>
  </div>;

  return <div>{text}</div>;
};

export default DecryptedText;
