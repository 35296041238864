import React, { useState, useEffect, useRef, useContext } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { searchPosts, trendingSearch, leaderboardSearch } from '../services/apiOutCalls';
import { getAddressFromKey, getAddressFromPKHash } from '../utils/hash160';
import NFTCard from '../components/NFTCard';
import LandingPage from './LandingPage';
import '../css/Home.css'; // new import for Home-specific styles
import { NavLink } from 'react-router-dom';

// Predefined list of tags available for search.
const availableTags = [
  "Science",
  "Travel",
  "Cosmos",
  "Quotes",
  "Memes",
  "Podcast",
  "Opinion",
  "Technology",
  "Entertainment",
  "History",
  "Sports",
  "Gaming",
];

const journalTags = ["Business", "News", "Politics", "Economy"];

const Home = () => {
  const { wallet } = useContext(AuthContext);

  const [posts, setPosts] = useState([]);
  // Removed searchTerm state. Only tag-based filtering remains.
  const [searchTags, setSearchTags] = useState([]);
  const [searchTagInput, setSearchTagInput] = useState('');
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);
  const searchInputRef = useRef(null);

  const [page, setPage] = useState(1);
  const [limit] = useState(10); // limit fixed, no change allowed.
  const [total, setTotal] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false); // Track if a search has been performed
  // New state to control pagination display.
  const [showPagination, setShowPagination] = useState(false);
  const [activeTab, setActiveTab] = useState("main"); // new state for active tab
  const [trendingPosts, setTrendingPosts] = useState([]); // state for trending posts
  const [trendingFilter, setTrendingFilter] = useState(""); // state for trending reaction filter
  const [trendingTimeframe, setTrendingTimeframe] = useState("week"); // default set to 1M instead of 1D
  // Add the missing leaderboard states:
  const [leaderboardTimeframe, setLeaderboardTimeframe] = useState("week");
  const [leaderboardPage, setLeaderboardPage] = useState(1);
  const [leaderboardData, setLeaderboardData] = useState([]);
  
  const autoLoadRef = useRef(null);
  const lastFetchTimestampRef = useRef(Date.now());

  // Effect for when searchTags change (new search):
  useEffect(() => {
    // When tags change, reset page and hide pagination.
    setPage(1);
    setShowPagination(false);
    fetchPosts(1, limit, searchTags).then(() => setShowPagination(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTags]); // Compare serialized tags

  // Modify useEffect for page (or limit) changes to exclude activeTab "main"
  useEffect(() => {
    if (hasSearched && activeTab !== "main") {
      fetchPosts(page, limit, searchTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  // New useEffect for trending search when activeTab is trending or trendingFilter/timeframe changes.
  useEffect(() => {
    if(activeTab === "trending") {
      setLoading(true);
      trendingSearch(wallet, { 
        timeframe: trendingTimeframe, 
        ...(trendingFilter ? { type: trendingFilter } : {}) 
      })
        .then(posts => {
          setTrendingPosts(posts);
          setLoading(false);
        })
        .catch(err => {
          console.error("Error fetching trending posts:", err);
          setLoading(false);
        });
    }
  }, [activeTab, trendingFilter, trendingTimeframe]);

  // NEW useEffect for leaderboard search (removed wallet from dependencies)
  useEffect(() => {
    if(activeTab === "leaderboard") {
      setSearchTags([]);
      setLoading(true);
      leaderboardSearch(wallet, { 
        timeframe: leaderboardTimeframe, 
        options: { page: leaderboardPage, limit }
      })
        .then(data => {
          setLeaderboardData(data);
          setLoading(false);
        })
        .catch(err => {
          console.error("Error fetching leaderboard:", err);
          setLoading(false);
        });
    }
  }, [activeTab, leaderboardTimeframe, leaderboardPage, limit]);

  // New effect to trigger fresh search on landing if wallet is set.
  useEffect(() => {
    if (wallet) {
      setPage(1);
      setShowPagination(false);
      fetchPosts(1, limit, searchTags).then(() => setShowPagination(true));
    }
  }, [wallet]);

  const fetchPosts = async (currentPage, currentLimit, tags) => {
    if (!wallet) {
      setErrorMessage("No account found. Please log in to view posts.");
      return Promise.resolve(); // ensure promise is returned
    }
    // Combine search query and tags into a single query string.
    const fullQuery = tags.join(" ");
    try {
      setErrorMessage("");  // Clear previous error
      setLoading(true);
      const result = await searchPosts(wallet, {search: fullQuery}, currentPage, currentLimit);
      setPosts(result.posts);
      setPage(result.page);
      setTotal(result.total);
      setLoading(false);
      setHasSearched(true);
      return Promise.resolve(true); // resolved promise on success
    } catch (error) {
      console.error("Error fetching posts:", error);
      setErrorMessage(error.message || "Failed to fetch posts. Please try again later.");
      setPosts([]);
      setTotal(0);
      setLoading(false);
      setHasSearched(true);
      setShowPagination(true);
      return Promise.resolve(false); // resolved promise on error
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(total / limit);

  const handleNext = () => {
    if (page < totalPages) {
      const newPage = page + 1;
      setPage(newPage);
      fetchPosts(newPage, limit, searchTags);
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      const newPage = page - 1;
      setPage(newPage);
      fetchPosts(newPage, limit, searchTags);
    }
  };

  // NEW: handleLoadMore function for main feed pagination
  const handleLoadMore = async () => {
    const nextPage = page + 1;
    setLoading(true);
    try {
      const result = await searchPosts(wallet, { search: searchTags.join(" ") }, nextPage, limit);
      setPosts(prevPosts => [...prevPosts, ...result.posts]);
      setPage(result.page);
      setTotal(result.total);
      lastFetchTimestampRef.current = Date.now();
    } catch (error) {
      setErrorMessage(error.message || "Failed to load more posts.");
    }
    setLoading(false);
  };

  // Modify the suggestions filter based on activeTab
  const filteredAvailableTags = activeTab === "citizen"
    ? availableTags.filter(tag => !journalTags.includes(tag))
    : availableTags;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (
          entry.isIntersecting &&
          !loading &&
          page < Math.ceil(total / limit) &&
          (Date.now() - lastFetchTimestampRef.current >= 2000)
        ) {
          handleLoadMore();
        }
      });
    }, { threshold: 0.1 });
    if (autoLoadRef.current) observer.observe(autoLoadRef.current);
    return () => {
      if (autoLoadRef.current) observer.unobserve(autoLoadRef.current);
    };
  }, [loading, page, total, limit]);

  if (!wallet) {
    return <LandingPage />;
  }

  return (
    <div className="p-3">
      {/* New Tabs Navigation with icons */}
      <div className="tabs-container">
        <button 
          className={`tab-button ${activeTab==="main" ? "active" : ""}`}
          onClick={() => { setActiveTab("main"); setSearchTags([]); }}
        >
          <i className="fa fa-home" style={{ marginRight: '0.5rem', color: 'var(--primary-bg)' }}></i>
          Feed
        </button>
        <button 
          className={`tab-button ${activeTab==="citizen" ? "active" : ""}`}
          onClick={() => { setActiveTab("citizen"); setSearchTags(journalTags); }}
        >
          <i className="fa fa-newspaper-o" style={{ marginRight: '0.5rem', color: 'var(--warning-color)' }}></i>
          Ninja News
        </button>
        <button 
          className={`tab-button ${activeTab==="trending" ? "active" : ""}`}
          onClick={() => { setActiveTab("trending"); setSearchTags([]); }}
        >
          <i className="fa fa-rocket" style={{ marginRight: '0.5rem', color: '#c02727' }}></i>
          Hot
        </button>
      </div>
      
      {/* Active Tab Wrapper */}
      {activeTab === "trending" ? (
        <div className="card bg-light shadow-sm">
          <div className="card-body">
            <div className="trending-filter-grid" style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div className="trending-filter-left" style={{ flex: 1, minWidth: '200px' }}>
                <div className="trending-header">Trending posts by:</div>
                <div className="trending-filter-container">
                  <button 
                    className={`trending-filter-btn ${trendingFilter === "like" ? "active" : ""}`}
                    onClick={() => setTrendingFilter("like")}
                  >
                    <i className="fa fa-thumbs-up"></i>
                  </button>
                  <button 
                    className={`trending-filter-btn ${trendingFilter === "love" ? "active" : ""}`}
                    onClick={() => setTrendingFilter("love")}
                  >
                    <i className="fa fa-heart"></i>
                  </button>
                  <button 
                    className={`trending-filter-btn ${trendingFilter === "sad" ? "active" : ""}`}
                    onClick={() => setTrendingFilter("sad")}
                  >
                    <i className="fa fa-sad-tear"></i>
                  </button>
                  <button 
                    className={`trending-filter-btn ${trendingFilter === "dislike" ? "active" : ""}`}
                    onClick={() => setTrendingFilter("dislike")}
                  >
                    <i className="fa fa-thumbs-down"></i>
                  </button>
                  <button 
                    className={`trending-filter-btn ${trendingFilter === "" ? "active" : ""}`}
                    onClick={() => setTrendingFilter("")}
                  >
                    All
                  </button>
                </div>
              </div>
              <div className="trending-filter-right" style={{ flex: 1, minWidth: '200px' }}>
                <div className="timeframe-header" style={{ fontWeight: 'bold', marginBottom: '0.5rem', textAlign: 'center', color: '#333' }}>
                  Timeframe:
                </div>
                <div className="timeframe-filter-container" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  <button 
                    className={`trending-filter-btn ${trendingTimeframe === "day" ? "active" : ""}`}
                    onClick={() => setTrendingTimeframe("day")}
                  >
                    1D
                  </button>
                  <button 
                    className={`trending-filter-btn ${trendingTimeframe === "week" ? "active" : ""}`}
                    onClick={() => setTrendingTimeframe("week")}
                  >
                    1W
                  </button>
                  <button 
                    className={`trending-filter-btn ${trendingTimeframe === "month" ? "active" : ""}`}
                    onClick={() => setTrendingTimeframe("month")}
                  >
                    1M
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : activeTab === "citizen" ? (
        <div className="card bg-light shadow-sm">
          <div className="card-body">
            <div
              className="form-control d-flex flex-wrap align-items-center"
              style={{ minHeight: "38px", padding: "0.5rem", position: "relative" }}
            >
              <p className="mb-0">
                True Web3.0 Journalism. This section shows #News, #Politics, #Business, #Economy tags of NFT posts from all Metanet Ninja and professional journalists.
              </p>
            </div>
          </div>
        </div>
      ) : (activeTab === "main" || activeTab === "leaderboard") ? (
        // Main Feed Search Wrapper displayed for both "main" and "leaderboard"
        <div className="card bg-light shadow-sm">
          <div className="card-body">
            <div
              className="form-control d-flex flex-wrap align-items-center"
              style={{ minHeight: "38px", cursor: "text", position: "relative" }}
              onClick={() => searchInputRef.current && searchInputRef.current.focus()}
            >
              {searchTags.map((tag, idx) => (
                <span key={idx} className="badge bg-primary me-1">
                  {tag}
                  <button
                    type="button"
                    className="btn-close btn-close-white btn-sm ms-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSearchTags(searchTags.filter((_, i) => i !== idx));
                    }}
                  ></button>
                </span>
              ))}
              <input
                type="text"
                className="border-0 flex-grow-1"
                style={{ outline: "none" }}
                placeholder="Search by tags or set your search terms..."
                value={searchTagInput}
                ref={searchInputRef}
                onChange={(e) => setSearchTagInput(e.target.value)}
                onFocus={() => setShowSearchSuggestions(true)}
                onBlur={() => setTimeout(() => setShowSearchSuggestions(false), 100)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && searchTagInput.trim() !== '') {
                    e.preventDefault();
                    if (!searchTags.includes(searchTagInput.trim())) {
                      setSearchTags([...searchTags, searchTagInput.trim()]);
                    }
                    setSearchTagInput('');
                  }
                }}
              />
            </div>
            {showSearchSuggestions && (
              <ul
                className="suggestions-dropdown mt-2"
                style={{
                  position: "absolute",
                  width: "calc(100% - 2rem)",
                  background: "#fff",
                  border: "1px solid #ccc",
                  borderRadius: ".25rem",
                  zIndex: 10,
                  listStyle: "none",
                  padding: "0.5rem",
                  margin: 0,
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {(
                  activeTab === "citizen"
                    ? availableTags.filter(tag => journalTags.includes(tag) && !searchTags.includes(tag))
                    : availableTags.filter(tag =>
                        tag.toLowerCase().includes(searchTagInput.toLowerCase()) &&
                        !searchTags.includes(tag)
                      )
                ).map(tag => (
                  <li
                    key={tag}
                    style={{ padding: "4px 8px", cursor: "pointer" }}
                    onMouseDown={() => {
                      setSearchTags([...searchTags, tag]);
                      setSearchTagInput('');
                    }}
                  >
                    {tag}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ) : (
        // Fallback (should not occur)
        null
      )}
      
      {/* Leaderboard Toggle Links (inserted between the wrapper and the loaded content) */}
      <div className="leaderboard-toggle-links bg-light p-2" style={{ textAlign: 'center', marginBottom: '1rem', border: '1px solid #ddd' }}>
        <strong>Earn Bitcoin SV!</strong>
        <a 
          href="#" 
          onClick={(e) => { 
            e.preventDefault(); 
            setActiveTab("leaderboard");
            setLeaderboardTimeframe("week");
            setLeaderboardPage(1);
            setSearchTags([]); // clear tags
          }}
          style={{ marginLeft: '1rem', fontWeight: activeTab==="leaderboard" && leaderboardTimeframe==="week" ? 'bold' : 'normal' }}
        >
          <i className="fa fa-trophy" style={{ marginRight: '0.3rem', color: '#FFD700' }}></i>
          Weekly
        </a>
        <a 
          href="#" 
          onClick={(e) => { 
            e.preventDefault(); 
            setActiveTab("leaderboard");
            setLeaderboardTimeframe("month");
            setLeaderboardPage(1);
            setSearchTags([]); // clear tags
          }}
          style={{ marginLeft: '1rem', fontWeight: activeTab==="leaderboard" && leaderboardTimeframe==="month" ? 'bold' : 'normal' }}
        >
          <i className="fa fa-calendar" style={{ marginRight: '0.3rem', color: '#5bc0de' }}></i>
          Monthly
        </a>
      </div>
      
      {/* Loaded Content for the Active Tab */}
      {activeTab === "trending" ? (
        <>
          {loading ? (
            <div className="text-center my-4">
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
              <span className="sr-only">Loading trending posts...</span>
            </div>
          ) : trendingPosts.length === 0 ? (
            <div className="alert alert-info">No trending posts available.</div>
          ) : (
            trendingPosts.map(post => (
              <NFTCard wallet={wallet} key={post.contractTx} post={post} />
            ))
          )}
        </>
      ) : activeTab === "citizen" ? (
        <>
          {loading && (
            <div className="text-center my-4">
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {!loading && posts.length === 0 && hasSearched && (
            <div className="alert alert-info">No posts available.</div>
          )}
          {!loading && posts.map((post) => (
            <NFTCard wallet={wallet} key={post._id} post={post} />
          ))}
          {showPagination && (
            <div className="d-flex justify-content-between align-items-center mt-4">
              <button className="btn btn-secondary" onClick={handlePrev} disabled={page === 1}>
                &laquo; Previous
              </button>
              <span>Page {page} of {totalPages}</span>
              <button className="btn btn-secondary" onClick={handleNext} disabled={page === totalPages}>
                Next &raquo;
              </button>
            </div>
          )}
        </>
      ) : activeTab === "leaderboard" ? (
        <>
          {/* Added message wrapper above leaderboard */}
          <div className="leaderboard-message bg-light p-3 mb-3" style={{ border: '1px solid #ddd', borderRadius: '4px' }}>
            <p style={{ margin: 0 }}>
              Metanet Ninjas win prizes just for posting! 100K, 500K and 1M satoshis for the Top 3 weekly winners every week, and 2M, 3M, 5M satoshis for the Top 3 monthly winners. Post content that people love, have the opportunity to receive Tips and on top of that the weekly and monthly prizes for the reactions your posts generate.
            </p>
          </div>
          {loading ? (
            <div className="text-center my-4">
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
              <span className="sr-only">Loading leaderboard...</span>
            </div>
          ) : leaderboardData.length === 0 ? (
            <div className="alert alert-info">No leaderboard data available.</div>
          ) : (
            // New single-row display with 2 columns: Rank and Address.
            leaderboardData.map((item, index) => {
              const rank = ((leaderboardPage - 1) * limit) + index + 1;
              let medal = null;
              if (rank === 1) {
                medal = <i className="fa fa-medal" style={{ color: '#FFD700', marginRight: '0.5rem' }}></i>;
              } else if (rank === 2) {
                medal = <i className="fa fa-medal" style={{ color: '#C0C0C0', marginRight: '0.5rem' }}></i>;
              } else if (rank === 3) {
                medal = <i className="fa fa-medal" style={{ color: '#cd7f32', marginRight: '0.5rem' }}></i>;
              }
              const displayRank = rank <= 3 ? medal : rank;
              return (
                <div key={item._id} style={{ display: 'flex', borderBottom: '1px solid #ddd', padding: '0.5rem 0' }}>
                  <div style={{ width: '50px', fontWeight: 'bold', textAlign: 'center' }}>
                    {displayRank}
                  </div>
                  <div>
                    <NavLink to={`/profile/${getAddressFromPKHash(item._id)}`} rel="noopener noreferrer">
                      {item.username?item.username:getAddressFromPKHash(item._id)}
                    </NavLink> <small>({item.totalReactions})</small>
                  </div>
                </div>
              );
            })
          )}
        </>
      ) : (
        // Main Feed loaded content
        <>
          {loading && posts.length === 0 && (
            <div className="text-center my-4">
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {!loading && posts.length === 0 && hasSearched && (
            <div className="alert alert-info">No posts available.</div>
          )}
          {posts.map((post, index) => {
            const autoLoadIndex = posts.length >= 6 ? posts.length - 6 : posts.length - 1;
            return (
              <div key={post._id} ref={index === autoLoadIndex ? autoLoadRef : null}>
                <NFTCard wallet={wallet} post={post} />
              </div>
            );
          })}
          {/* Loader below posts when loading more */}
          {loading && posts.length > 0 && (
            <div className="text-center my-4">
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Home;
