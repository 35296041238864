import React, { useState, useEffect, useContext } from 'react';
import { Transaction, PrivateKey, P2PKH, Script } from '@bsv/sdk';
import { Buffer } from 'buffer';
import '../css/BSVTransferForm.css';
import UTXOSelectorOverlay from './UTXOSelectorOverlay';
import { broadcastTransactions, dynamicApiCall } from '../services/apiOutCalls';

import { getLockingScript } from '../utils/mintAllocations';
import { useGlobalAlert } from './GlobalAlert';

import { ConfigContext } from '../config';
import ShowBalance from '../components/ShowBalance'; // <-- NEW IMPORT
import { Scanner } from '@yudiel/react-qr-scanner';
import OverlayConfirm from './OverlayConfirm';

// Helper: Build an OP_RETURN script from ASCII data (UTF-8 encoded)
const buildOpReturnScript = (address, message) => {
  const standardScriptHex = new P2PKH().lock(address).toHex();
  const pubKeyHashHex = standardScriptHex.slice(6, 46);
  const messageBuffer = Buffer.from(message, 'utf8');
  const messageHex = messageBuffer.toString('hex');
  const dataLength = messageBuffer.length;
  let pushOpcode;
  if (dataLength <= 75) {
    pushOpcode = dataLength.toString(16).padStart(2, '0');
  } else if (dataLength < 256) {
    pushOpcode = "4c" + dataLength.toString(16).padStart(2, '0');
  } else if (dataLength < 65536) {
    pushOpcode = "4d" + dataLength.toString(16).padStart(4, '0');
  } else {
    pushOpcode = "4e" + dataLength.toString(16).padStart(8, '0');
  }
  const scriptHex =
    "76" +
    "a9" +
    "14" +
    pubKeyHashHex +
    "88" +
    "ac" +
    "6a" +
    pushOpcode +
    messageHex;
  return Script.fromHex(Buffer.from(scriptHex, 'hex').toString('hex'));
};



const BSVTransferForm = ({ wallet, onTransactionComplete, transfer_params }) => {
  const config = useContext(ConfigContext);

  const { showAlert } = useGlobalAlert();

  const [fee, setFee] = useState(0);
  const [feeManuallySet, setFeeManuallySet] = useState(false);
  const [recipients, setRecipients] = useState([{ address: '', value: '' }]);
  const [errorMsg, setErrorMsg] = useState('');
  const [txStatus, setTxStatus] = useState('');
  const [signedHex, setSignedHex] = useState('');
  const [opReturnMessages, setOpReturnMessages] = useState(['']);
  const [estimatedSize, setEstimatedSize] = useState(0);
  const [totalFunds, setTotalFunds] = useState(0);

  // New state for UTXO selection overlay.
  const [showUtxoOverlay, setShowUtxoOverlay] = useState(false);
  const [selectedUTXOs, setSelectedUTXOs] = useState([]);
  const [hasTransferParams, setHasTransferParams] = useState(false);
  const [params, setParams] = useState({});
  const [showAdvanced, setShowAdvanced] = useState(false);
  
  // State for QR scanning:
  const [showQRScanner, setShowQRScanner] = useState(false);
  const [qrRecipientIndex, setQrRecipientIndex] = useState(null);

  // Add state for merge-split confirmation input
  const [showMergeSplitConfirm, setShowMergeSplitConfirm] = useState(false);

  const resetState = () => {
    // Reset all state variables to their initial values
    setFee(0);
    setFeeManuallySet(false);
    setRecipients([{ address: '', value: '' }]);
    setErrorMsg('');
    setTxStatus('');
    setSignedHex('');
    setOpReturnMessages(['']);
    setEstimatedSize(0);
    // If needed, reset other state variables (e.g., UTXO selection, parameters)
    setSelectedUTXOs([]);
    setHasTransferParams(false);
    setParams({});
    setShowAdvanced(false);
  };
  
  useEffect(() => {
    if (transfer_params && transfer_params.recipients && Array.isArray(transfer_params.recipients) && transfer_params.recipients.length > 0) {
      setHasTransferParams(true);
      setParams(transfer_params);
    }
  }, [transfer_params]);

  useEffect(() => {
    if (params && params.recipients && Array.isArray(params.recipients) && params.recipients.length > 0) {
      setRecipients(params.recipients);
    }
  }, [params]);

  // Helper: Sum recipient amounts.
  const sumFilledRecipients = (rows) => 
    rows.filter(r => r.address.trim() !== '').reduce((sum, r) => sum + (Number(r.value) || 0), 0);

  // Validate allocation.
  useEffect(() => {
    const filledSum = sumFilledRecipients(recipients);
    const allocated = fee + filledSum;
    let msg = '';
    if (totalFunds <= 0) {
      msg = 'No funds available. You either need to top up or you may need to wait for pending funds to clear.';
    } else if (allocated > totalFunds) {
      msg = `Spending (${filledSum}) plus fees exceed available funds. You should spend less than ${totalFunds} satoshis or fund your wallet.`;
    }
    setErrorMsg(msg);
  }, [fee, recipients, totalFunds]);

  // Estimate size.
  const estimateTxnSize = () => {
    let size = 10;
    size += (selectedUTXOs.length * 148);
    const normalOutCount = recipients.filter(r => r.address.trim() !== '').length;
    size += normalOutCount * 34;
    opReturnMessages.forEach(msg => {
      if (msg.trim() !== '') {
        const opReturnScript = buildOpReturnScript(wallet.address, msg);
        const scriptSize = opReturnScript.toHex().length / 2;
        size += 8 + 1 + scriptSize;
      }
    });
    return size;
  };
  
  useEffect(() => {
    setEstimatedSize(estimateTxnSize());
  }, [fee, recipients, opReturnMessages, selectedUTXOs]);

  useEffect(() => {
    if (!feeManuallySet) {
      const newFee = Math.ceil(estimatedSize / 1024 * config.SATS_PER_KB_FEE);
      setFee(newFee);
    }
  }, [estimatedSize, feeManuallySet]);

  const setTranferParams = () => {
    setParams({
      autoutxo: true,
      recipients: recipients.map(r => ({
        address: r.address, 
        value: r.value,
      }))
    });
    setHasTransferParams(true);

  };

  const handleRecipientChange = (index, field, value) => {
    setRecipients(prev => {
      const newRecipients = [...prev];
      newRecipients[index] = { ...newRecipients[index], [field]: value };
      return newRecipients;
    });
  };

  const addRecipientRow = () => {
    setRecipients(prev => [...prev, { address: '', value: '' }]);
  };

  const removeRecipient = (index) => {
    setRecipients(prev => {
      const newRecipients = prev.filter((_, i) => i !== index);
      return newRecipients.length ? newRecipients : [{ address: '', value: '' }];
    });
  };

  const resetOutputs = () => {
    setFee(0);
    setFeeManuallySet(false);
    setRecipients([{ address: '', value: '' }]);
  };

  // Handlers for OP_RETURN messages.
  const handleOpReturnChange = (index, value) => {
    setOpReturnMessages(prev => {
      const newMessages = [...prev];
      newMessages[index] = value;
      return newMessages;
    });
  };

  const addOpReturnField = () => {
    setOpReturnMessages(prev => [...prev, '']);
  };

  const removeOpReturnField = (index) => {
    setOpReturnMessages(prev => prev.filter((_, i) => i !== index));
  };

  const acceptableDustFee = 50;
  const filledSum = sumFilledRecipients(recipients);
  const allocatedTotal = fee + filledSum;
  const remaining = totalFunds - allocatedTotal;
  const selectedTotal = selectedUTXOs.reduce((acc, utxo) => acc + utxo.value, 0);
  const unusedUtxoValue = selectedTotal - allocatedTotal;
  const formValid = totalFunds > 0 && unusedUtxoValue >= 0 && unusedUtxoValue <= acceptableDustFee;

  const totalRecipientValue = recipients.reduce((acc, r) => acc + Number(r.value || 0), 0);

  // Generate transaction using selectedUTXOs from overlay.
  const generateSignedTransaction = async () => {
    try {
      setTxStatus('Building transaction...');
      setSignedHex('');

      if (totalRecipientValue <= 0) {
        setTxStatus("You haven't chosen any value to send out");
        return;
      }
      
      let selectedUTXOList = selectedUTXOs;
      
      // If autoutxo is enabled, fetch UTXOs from the API.
      if (params && params.autoutxo) {
        const utxoResult = await dynamicApiCall(wallet,{ action: "getConfirmedUnspent", address: wallet.address });
        if (utxoResult.success) {
          const utxos = utxoResult.data;
          // (Optionally) sort the utxos in ascending order (by value)
          utxos.sort((a, b) => a.value - b.value);
          let acc = 0;
          selectedUTXOList = [];
          for (const utxo of utxos) {
            selectedUTXOList.push(utxo);
            acc += utxo.value;
            if (acc >= allocatedTotal) break;
          }
          if (acc < allocatedTotal) {
            setTxStatus("Error: Insufficient funds");
            return;
          }
          // Update the state if needed.
          setSelectedUTXOs(selectedUTXOList);
        } else {
          setTxStatus("Error fetching UTXOs");
          return;
        }
      }
      
      const privKey = PrivateKey.fromWif(wallet.privateKey);
      const tx = new Transaction();
      
      // Use selectedUTXOList to add input UTXOs.
      selectedUTXOList.forEach((utxo) => {
        tx.addInput({
          sourceTXID: utxo.tx_hash,
          sourceOutputIndex: utxo.tx_pos,
          unlockingScriptTemplate: new P2PKH().unlock(
            privKey,
            'all',
            false,
            utxo.value,
            getLockingScript(wallet)
          )
        });
      });

      // Process recipients.
      recipients.forEach((recipient) => {
        if (recipient.address.trim() === '') return;
        const normalScript = getLockingScript(recipient);
        tx.addOutput({
          satoshis: Number(recipient.value),
          lockingScript: normalScript
        });
      });


      

      // Add OP_RETURN outputs.
      opReturnMessages.forEach((msg) => {
        if (msg.trim() !== '') {
          const opReturnScript = buildOpReturnScript(wallet.address, msg);
          tx.addOutput({
            satoshis: 10,
            lockingScript: opReturnScript
          });
        }
      });

      if (params && params.autoutxo) {
        // Calculate total value from auto-selected UTXOs.
        const autoSelectedSum = selectedUTXOList.reduce((sum, utxo) => sum + utxo.value, 0);
        // Compute change = autoSelectedSum - allocatedTotal.
        const change = autoSelectedSum - allocatedTotal - fee;
        if (change > 0) {
          // Use wallet address to build the locking script.
          const changeLockingScript = getLockingScript(wallet.address);
          tx.addOutput({
            satoshis: Number(change),
            lockingScript: changeLockingScript
          });
        }
      }
      
      setTxStatus('Signing transaction...');
      await tx.sign();
      
      setTxStatus('Broadcasting transaction...');
      const rawTxHex = tx.toHex();

      try {
        const broadcastResults = await broadcastTransactions(wallet, rawTxHex);
        // Assume we broadcast a single transaction, pick the first result.
        let outcome;
        if (Array.isArray(broadcastResults) && broadcastResults.length > 0) {
          const firstResult = broadcastResults[0];
          if (firstResult.error && firstResult.error.code !== 0) {
            showAlert("An error occured, you should. If you don't see the transaction in your history in a while you might need to try again.", "danger", "Transfer Failed", false);

            outcome = { message: firstResult.error.message };
          } else {

            showAlert("Here is the transaction ID: "+ tx.id('hex'), "info", "Transfer Done", false);
            resetState();

            outcome = { txid: firstResult.txid };
          }
        } else {
          showAlert("An error occured, you should. If you don't see the transaction in your history in a while you might need to try again.", "danger", "Transfer Failed", false);

        }
        setTxStatus('Broadcast complete.');
        if (onTransactionComplete) {
          onTransactionComplete(outcome);
        }
      } catch (broadcastErr) {
        console.error("Broadcast error:", broadcastErr);
        setTxStatus("Broadcast error: " + broadcastErr.message);
      }
    } catch (err) {
      console.error('Error building transaction:', err);
      setTxStatus('Error: ' + err.message);
    }
  };

  // Updated handleMergeSplit using OverlayConfirm instead of window.prompt
  const handleMergeSplit = async () => {
    setShowMergeSplitConfirm(true);
  };

  const handleQRScanResult = (result) => {
    if (Array.isArray(result) && result.length > 0 && result[0].rawValue) {
      let scanned = result[0].rawValue.trim();
      let newRecipient = { address: scanned, value: '' };
      if (scanned.startsWith("{")) {
        try {
          const parsed = JSON.parse(scanned);
          if (parsed.address) {
            newRecipient.address = parsed.address;
          }
          if (parsed.amount) {
            newRecipient.value = parsed.amount;
          }
        } catch (e) {
          console.error("QR parse error:", e);
        }
      }
      // Look for an existing recipient with an empty address
      const emptyIndex = recipients.findIndex(r => r.address.trim() === "");
      if (emptyIndex !== -1) {
        handleRecipientChange(emptyIndex, 'address', newRecipient.address);
        if (newRecipient.value) {
          handleRecipientChange(emptyIndex, 'value', newRecipient.value);
        }
      } else {
        setRecipients(prev => [...prev, newRecipient]);
      }
    }
    setShowQRScanner(false);
  };


  return (
    <div className="wallet-transaction-form">
      <form>
        {/* ...existing code above recipients table... */}
        <ShowBalance wallet={wallet} onFundsChange={setTotalFunds} />
        {/* Recipients Table */}
        {params && params.recipients && params.recipients.length > 0 ? (
          <div className="read-only-recipients mb-3">
            {params.recipients.map((recipient, idx) => (
              <div key={idx} className="recipient-readonly-item">
                <p>
                  <strong>Recipient Address:</strong> {recipient.address}
                </p>
                <p>
                <strong>Amount:</strong> {recipient.fiatvalue ? `$USD ${recipient.fiatvalue}` : `${recipient.value} Sats`}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="recipients-section mb-3">
              {recipients.map((recipient, i) => (
                <div key={i} className="recipient-card p-0 mb-2  rounded">
                  {/* Row for input fields */}
                  <div className="d-flex flex-sm-row flex-column align-items-center">
                    <div className="flex-fill me-sm-2  w-100">
                      <input
                        type="text"
                        className="border-bottom-only-field w-100"
                        value={recipient.address}
                        onChange={(e) => handleRecipientChange(i, 'address', e.target.value)}
                        placeholder="Recipient address"
                      />
                    </div>
                    <div className="flex-fill me-sm-2  w-100">
                      <input
                        type="number"
                        className="border-bottom-only-field w-100"
                        value={recipient.value}
                        onChange={(e) => handleRecipientChange(i, 'value', e.target.value)}
                        placeholder="Satoshis to send"
                      />
                    </div>
                  </div>
                  {/* Row for removal link always placed below the fields */}
                  {i>0 && (
                  <div className="text-start">
                    - <a href="#/" className="text-muted" onClick={() => removeRecipient(i)}>
                     Remove recipient
                    </a>
                  </div>
                  )}
                </div>
              ))}
              <div className="d-flex justify-content-between align-items-center mt-4">
                <button type="button" className="btn btn-info" onClick={addRecipientRow}>
                  <i className="fa fa-plus"></i> Recipient
                </button>
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    type="button"
                    className="btn btn-light me-2"
                    onClick={() => setShowQRScanner(true)}
                    title="Scan Recipient QR"
                  >
                    <i className="fa fa-camera"></i> QR
                  </button>
                  {totalFunds > 0 && (
                    <button type="button" className="btn btn-light" onClick={handleMergeSplit}>
                      <i className="fa fa-compress"></i> Tidy Up
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {/* Output Messages Table */}
        {/* <table className="table table-bordered">
          <thead>
            <tr>
              <th className="table-custom-header" colSpan={2}>Output Messages</th>
            </tr>
            <tr>
              <th>Message</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {opReturnMessages.map((msg, i) => (
              <tr key={i}>
                <td>
                  <textarea
                    className="form-control"
                    rows="2"
                    value={msg}
                    onChange={(e) => handleOpReturnChange(i, e.target.value)}
                    placeholder="Enter OP_RETURN message text"
                  />
                </td>
                <td style={{ width: '3em', textAlign: 'center' }}>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => removeOpReturnField(i)}
                  >
                    X
                  </button>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="2">
                <button type="button" className="btn btn-secondary" onClick={addOpReturnField}>
                  Add Output Messages
                </button>
              </td>
            </tr>
          </tbody>
        </table>  */}


        {/* Advanced Options Toggle Row */}
        {!hasTransferParams && (

        <div className="d-flex justify-content-between align-items-center mb-3">
          {!showAdvanced && (
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={setTranferParams}
            >
              Review Transaction
            </button>
          )}
          <div>
            <input
              type="checkbox"
              id="advancedOptions"
              checked={showAdvanced}
              onChange={() => setShowAdvanced(!showAdvanced)}
            />
            <label htmlFor="advancedOptions" className="ms-2">Advanced</label>
          </div>
        </div>
        )}

        {/* Only show Summary and Fee section if autoutxo is not true */}
        {(!params?.autoutxo && showAdvanced) && (
          <>
            {/* Summary and Fee Table (vertical layout) */}
            <div className="summary-card card mb-3">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-6"><strong>Wallet Funds (sat)</strong></div>
                  <div className="col-6 text-end">{totalFunds}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-6"><strong>Estimated Tx Size (bytes)</strong></div>
                  <div className="col-6 text-end">{estimatedSize}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-6"><strong>Estimate Required (sat)</strong></div>
                  <div className="col-6 text-end">{allocatedTotal}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-6"><strong>Remaining (sat)</strong></div>
                  <div className="col-6 text-end">{remaining}</div>
                </div>
                <div className="row align-items-center">
                  <div className="col-6">
                    <strong>Fee (sat) ~ {config.SATS_PER_KB_FEE} sats/Kb</strong>
                  </div>
                  <div className="col-6 text-end">
                    <input
                      type="number"
                      id="fee"
                      className="form-control fee-cell"
                      value={fee}
                      onChange={(e) => {
                        setFee(parseInt(e.target.value));
                        setFeeManuallySet(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <p className="note">
              You may adjust the fee manually but ensure it is enough to process the transaction.
              Please ensure the fee is sufficient (at least {config.SATS_PER_KB_FEE} sats/Kb) so the transaction does not get stuck.
            </p>
          </>
        )}

        {/* In the button container, only show the "Select UTXOs" button when autoutxo is not enabled */}
        <div className="d-flex justify-content-between">
          {(!params?.autoutxo && showAdvanced) && (
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => setShowUtxoOverlay(true)}
            >
              {selectedTotal > 0
                ? `Selected (${selectedTotal} sats)`
                : "Select UTXOs"}
            </button>
          )}
          
          {(showAdvanced || hasTransferParams) && (
          <button
            type="button"
            className={`btn ${(formValid || params?.autoutxo) ? "btn-outline-primary" : (unusedUtxoValue < 0 ? "btn-danger" : "btn-outline-primary")}`}
            onClick={generateSignedTransaction}
            disabled={!(formValid || params?.autoutxo)}
          >
            {(formValid || params?.autoutxo)
              ? "Finalise and send"
              : `Unallocated ${unusedUtxoValue} sats`}
          </button>
          )}
        </div>
      </form>
      {txStatus && <div className="alert alert-info mt-3">{txStatus}</div>}
      {signedHex && (
        <div className="alert alert-success mt-3">
          <strong>Signed Transaction Hex:</strong>
          <pre>{signedHex}</pre>
        </div>
      )}
      {errorMsg && <div className="alert alert-danger mt-3">{errorMsg}</div>}
      {showUtxoOverlay && (
        <UTXOSelectorOverlay
          wallet={wallet}
          allocatedTotal={allocatedTotal} // Pass allocatedTotal here.
          onClose={() => setShowUtxoOverlay(false)}
          onSelectionComplete={(utxos) => {
            setSelectedUTXOs(utxos);
            setShowUtxoOverlay(false);
          }}
        />
      )}
      {showQRScanner && (
        <div style={{
          position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.8)', display: 'flex',
          alignItems: 'center', justifyContent: 'center', zIndex: 9999
        }}>
          <div style={{ position: 'relative', width: '300px', height: '300px' }}>
            <Scanner 
              onScan={(result) => {
                if(result) {
                  handleQRScanResult(result);
                }
              }}
              onError={(error) => console.error("Scanner error:", error)}
              style={{ width: '100%', height: '100%' }}
            />
            <button
              type="button"
              onClick={() => { setShowQRScanner(false); setQrRecipientIndex(null); }}
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                background: 'red',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                cursor: 'pointer'
              }}
              title="Close Scanner"
            >
              <i className="fa fa-close" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}
      {showMergeSplitConfirm && (
        <OverlayConfirm
          confirmation={{
            messages: [
              "This will merge all your UTXOs and split them into equal parts. The last UTxO may be a little bigger. Enter the number of UTXOs to create:"
            ],
            requiresInput: true
          }}
          optionsMsg={{ confirm: "Submit", cancel: "Cancel" }}
          onComplete={async (result) => {
            setShowMergeSplitConfirm(false);
            const input = result?.status && result.values && result.values[0];
            const n = parseInt(input, 10);
            if (isNaN(n) || n <= 0) return;
            
            let autoSelectedUTXOs = selectedUTXOs;
            try {
              const utxoResult = await dynamicApiCall(wallet, { action: "getConfirmedUnspent", address: wallet.address });
              const utxos = utxoResult.data;
              utxos.sort((a, b) => a.value - b.value);
              let acc = 0;
              autoSelectedUTXOs = [];
              for (const utxo of utxos) {
                autoSelectedUTXOs.push(utxo);
                acc += utxo.value;
              }
              setSelectedUTXOs(autoSelectedUTXOs);
            } catch (err) {
              console.error("Auto UTXO selection error:", err);
              return;
            }
            
            const estimatedSize = 10 + 148 + (34 * n) + (148 * autoSelectedUTXOs.length);
            const feeEstimate = Math.ceil(estimatedSize / 1024 * config.SATS_PER_KB_FEE);
            if (totalFunds <= feeEstimate) {
              alert("Not enough funds to cover fee.");
              return;
            }
            const available = totalFunds - feeEstimate;
            if (available < n) {
              alert("Not enough funds to split into equal parts.");
              return;
            }
            const splitAmount = Math.floor(available / n);
            let newRecipients = [];
            for (let i = 0; i < n - 1; i++) {
              newRecipients.push({ address: wallet.address, value: splitAmount });
            }
            const lastAmount = available - splitAmount * (n - 1);
            newRecipients.push({ address: wallet.address, value: lastAmount });
            setRecipients(newRecipients);
          }}
        />
      )}
    </div>
  );
};

export default BSVTransferForm;
