/**
 * Generates a unique short identifier (length 5) to support up to 100M unique ids.
 * Allowed characters: uppercase, lowercase, digits; hyphen allowed only in middle positions.
 *
 * @returns {string} A unique id (e.g.: "A3-bZ")
 */
export function generateUniqueId() {
  // Allowed characters for first and last positions (62 chars)
  const charsStartEnd = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // Allowed characters for middle positions (62 + hyphen = 63 chars)
  const charsMiddle = charsStartEnd + '-';
  const idLength = 5;

  // Check if crypto.getRandomValues is available
  const cryptoAvailable = () =>
    typeof crypto !== 'undefined' && typeof crypto.getRandomValues === 'function';

  // Get a random integer in [0, max) using crypto if available, fallback to Math.random()
  const getRandomInt = (max) => {
    if (cryptoAvailable()) {
      const array = new Uint32Array(1);
      crypto.getRandomValues(array);
      return array[0] % max;
    }
    return Math.floor(Math.random() * max);
  };

  // Return a random character from the given chars string.
  const getRandomChar = (chars) => chars.charAt(getRandomInt(chars.length));

  let id = '';
  // First character (cannot be hyphen)
  id += getRandomChar(charsStartEnd);
  // Middle characters (can include hyphen)
  for (let i = 0; i < idLength - 2; i++) {
    id += getRandomChar(charsMiddle);
  }
  // Last character (cannot be hyphen)
  id += getRandomChar(charsStartEnd);
  return id;
}