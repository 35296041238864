import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import NFTCard from './NFTCard';
import '../css/EmbeddedPost.css';
import { getPostByContractTx } from '../services/apiOutCalls';

const EmbeddedPost = ({ post, meta, isReply, wallet, mintFromCard, parentEmbed }) => {
  const [parentPost, setParentPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const containerClass = isReply && !parentEmbed ? "reply-post" : (!parentEmbed?"embedded-post":'parent-post');

  useEffect(() => {
    const fetchParentPost = async () => {
      try {
        const result = await getPostByContractTx(wallet, meta.contractTx);
        if (result.success) {
          setParentPost(result.post);
        } else {
          console.error("findPost call failed", result.error);
        }
      } catch (err) {
        console.error("Error fetching parent post:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchParentPost();
  }, [meta, wallet]);

  if (loading) return <div className="embedded-post">Loading parent post...</div>;
  if (!parentPost) return <div className="embedded-post">Parent post not found.</div>;
  
  return (
    <div>
      {(isReply && !parentEmbed) && (
        <div className="expand-comments">
          <NavLink to={`/post/${post?.contractTx}`}>
            Expand all comments
          </NavLink>
        </div>
      )}
      <div className={containerClass}>
        <NFTCard 
        mintFromCard={mintFromCard}
        wallet={wallet} 
        post={parentPost} 
        embedded={!parentEmbed? true : false}     
        isReply={isReply}              
        />
      </div>
      
    </div>
  );
};

export default EmbeddedPost;