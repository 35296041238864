import React, { useState, useEffect, useContext } from 'react';
import WalletDetails from './WalletDetails';
import BSVTransferForm from './BSVTransferForm';
import TokenMintOrdinals1satNFT from './TokenMintOrdinals1satNFT';
import TokenMintSTAS20 from './TokenMintSTAS20';
import TokenMintSTAS789 from './TokenMintSTAS789';
import TransactionOutcome from './TransactionOutcome';
import Holdings from './Holdings';
import '../css/TransactionStep.css';
import { useLocation, useNavigate } from 'react-router-dom';

const InitiateTransaction = ({ wallet, selectedUTXOs, totalFunds }) => {
  const [transactionType, setTransactionType] = useState('send'); // 'send' or 'mint'
  const [mintTokenType, setMintTokenType] = useState('STAS-20');
  const [transactionCompleted, setTransactionCompleted] = useState(false);
  const [outcome, setOutcome] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');
    if (tabParam === 'mint') {
      setTransactionType('mint');
    } else {
      setTransactionType('send');
    }
  }, [location.search]);
  
  const handleTransactionComplete = (result) => {
   
    // Reset any custom state if needed
    setTransactionCompleted(true);
    setOutcome(null);
  };

  return (

    <>
    <WalletDetails
        wallet={wallet}
        onShowHoldings={()=>setTransactionType('holdings')}
    />
     <small>The platform is a Beta version. Check our ToS and FAQ.</small>
    <div className="card shadow  mt-4 ">
     
      <div className="card-body">
      

        <div className="transaction-type-selector mb-4">
          <div
            className={`tab ${transactionType === 'send' ? 'active' : ''}`}
            onClick={() => setTransactionType('send')}
          >
            Pay
          </div>
          <div
            className={`tab ${transactionType === 'mint' ? 'active' : ''}`}
            onClick={() => setTransactionType('mint')}
          >
            Mint
          </div>
          <div
            className={`tab ${transactionType === 'holdings' ? 'active' : ''}`}
            onClick={() => setTransactionType('holdings')}
          >
            Tokens
          </div>
        </div>
        
        {transactionType === 'mint' && (
          <div className="card bg-light p-2 mb-3">
            <select
              className="form-select"
              value={mintTokenType}
              onChange={(e) => setMintTokenType(e.target.value)}
            >
              {/* <option value="Ordinals 1sat NFT">Ordinals 1sat NFT</option> */}
              <option value="STAS-20">STAS-20</option>
            </select>
          </div>
        )}
        <div className="transaction-content mt-4">
          {transactionType === 'send' ? (
            <BSVTransferForm
              wallet={wallet}
              onTransactionComplete={handleTransactionComplete}
            />
          ) : transactionType === 'mint' ? (
            <>
              {mintTokenType === 'Ordinals 1sat NFT' && (
                <TokenMintOrdinals1satNFT
                  wallet={wallet}
                  onTransactionComplete={handleTransactionComplete}
                />
              )}
              {mintTokenType === 'STAS-20' && (
                <TokenMintSTAS20
                  wallet={wallet}
                  onTransactionComplete={handleTransactionComplete}
                />
              )}
            </>
          ) : transactionType === 'holdings' ? (
            <Holdings
              wallet={wallet}
              totalFunds={totalFunds}
              onModuleBack={() => setTransactionType('send')}
            />
          ) : null}
        </div>
      </div>
    
    </div>
    </>
  );
};

export default InitiateTransaction;
