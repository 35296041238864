import React, { useState, useEffect, useContext } from 'react';
import { safeTextLinks } from '../utils/stringUtils';
import { verifiedFetch } from '@helia/verified-fetch'
import '../css/ExpandedPostOverlay.css';
import CryptoJS from 'crypto-js';
import { ConfigContext } from '../config';
import DecryptedVideo from './DecryptedVideo';
import DecryptedImage from './DecryptedImage';
import DecryptedText from './DecryptedText';

// Helper function to convert ArrayBuffer to base64.
function arrayBufferToBase64(buffer) {
	let binary = '';
	const bytes = new Uint8Array(buffer);
	for (let i = 0; i < bytes.byteLength; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
}

const ExpandedPostOverlay = ({ post, onClose, wallet }) => {
	const config = React.useContext(ConfigContext);
	const assets = post?.properties?.content?.assets || [];
	const [assetContents, setAssetContents] = useState(Array(assets.length).fill(null));
	const passphrase = config.CONTENT_PASSPHRASE;
	const saltBuffer = new Uint8Array([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]); // Replace with actual salt

	useEffect(() => {
		setAssetContents(Array(assets.length).fill(null));
		
		assets.forEach((asset, index) => {
			console.log('asset.CID', asset.CID);
			const url = `ipfs://${asset.CID}`;

			if (asset.type === 'image') {
				verifiedFetch(url)
						.then(async response => {
							// Read encrypted content as text
							const encryptedContent = await response.text();
							const bytes = CryptoJS.AES.decrypt(encryptedContent, passphrase);
							// decryptedDataUrl should be a valid dataURL (e.g., "data:image/png;base64,...")
							const decryptedDataUrl = bytes.toString(CryptoJS.enc.Utf8);
							setAssetContents(prev => {
								const updated = [...prev];
								updated[index] = decryptedDataUrl;
								return updated;
							});
						})
						.catch(err => {
							console.error(`Error loading ${asset.type} asset:`, err);
							setAssetContents(prev => {
								const updated = [...prev];
								updated[index] = "Error loading image.";
								return updated;
							});
						});
			} else if (asset.type === 'long_text') {
				verifiedFetch(url)
						.then(async response => {
							const encryptedText = await response.text();
							const bytes = CryptoJS.AES.decrypt(encryptedText, passphrase);
							let decryptedText = bytes.toString(CryptoJS.enc.Utf8);
							// NEW: If decryptedText begins with a data URL prefix, extract and decode base64.
							const prefix = "data:text/plain;base64,";
							if (decryptedText.startsWith(prefix)) {
							  const base64Content = decryptedText.slice(prefix.length);
							  decryptedText = window.atob(base64Content);
							}
							return decryptedText;
						})
						.then(text => {
							const content = safeTextLinks(text);
							setAssetContents(prev => {
								const updated = [...prev];
								updated[index] = content;
								return updated;
							});
						})
						.catch(err => {
							console.error(`Error loading ${asset.type} asset:`, err);
							setAssetContents(prev => {
								const updated = [...prev];
								updated[index] = "Error loading content.";
								return updated;
							});
						});
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [post]);

	return (
	<div
      className="modal fade show"
      style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">

		<div className="modal-body p-0">
                

                    <button className="close-btn-fullcard" onClick={onClose}>
						<i className="fa fa-times"></i>
					</button>
					{assets.length === 0 && <div>No assets to display.</div>}
					{assets.map((asset, index) => (
						<div key={index} className="asset-item">
							{asset.type === 'video' ? (
								<div className="video-asset">
								<DecryptedVideo videoUrl={asset.CID} password={passphrase} saltBuffer={saltBuffer} />
								</div>
							) : asset.type === 'image' ? (
								<DecryptedImage cid={asset.CID} passphrase={passphrase} alt={`Asset ${index}`} />
							) : asset.type === 'long_text' ? (
								<div className="p-3">
								<DecryptedText cid={asset.CID} passphrase={passphrase} />
								</div>
							) : (
								<div>{safeTextLinks(asset.CID)}</div>
							)}
						</div>
					))}
				</div>
			</div>
			</div>
			</div>
		);
};

export default ExpandedPostOverlay;
