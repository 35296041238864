export const toCamelCase = (str) => {
  if (!str) return '';
  return str
    .toLowerCase()
    // Replace any non-alphanumeric character(s) with a space then upper-case the following character.
    .replace(/[^a-z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
};

export const toTitleCase = (str) => {
  if (!str) return '';
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};


export const convertCamelToCamelCase = (str) => {
  if (!str) return "";
  // Insert a space before all caps, trim and capitalize first letter.
  const words = str.replace(/([A-Z])/g, " $1").trim();
  return words.charAt(0).toUpperCase() + words.slice(1);
};

/**
 * Remove all HTML tags except allowed <b> tags.
 * Allowed <b> tags are stripped of all attributes.
 */
const cleanHTML = (text) => {
  if (!text) return "";
  // Temporarily replace all allowed <b> tags with placeholders.
  let cleaned = text.replace(/<b\b[^>]*>/gi, "###B_OPEN###").replace(/<\/b>/gi, "###B_CLOSE###");
  // Remove all remaining HTML tags.
  cleaned = cleaned.replace(/<\/?[^>]+(>|$)/g, "");
  // Restore allowed <b> tags without any attributes.
  cleaned = cleaned.replace(/###B_OPEN###/g, "<b>").replace(/###B_CLOSE###/g, "</b>");
  return cleaned;
};

/**
 * Convert URLs and email addresses into clickable links.
 * Identifies patterns for URLs (with or without protocol) and emails.
 */
const linkify = (text) => {
  // Replace web URLs. This regex matches URLs starting with http(s):// or www.
  const urlRegex = /((https?:\/\/)|(www\.))([\w\-]+(\.[\w\-]+)+)(\/[\w\-.,@?^=%&:/~+#]*)?/gi;
  text = text.replace(urlRegex, (match, p1) => {
    let url = match;
    // If URL doesn't start with http, add it
    if (!/^https?:\/\//i.test(url)) {
      url = "http://" + url;
    }
    let display;
    try {
      display = new URL(url).host;
    } catch (err) {
      display = match;
    }
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${display}</a>`;
  });
  // Replace email addresses
  const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/gi;
  text = text.replace(emailRegex, '<a href="mailto:$1">$1</a>');
  return text;
};

/**
 * safeTextLinks(text)
 * Clean the text, allowing only <b> tags (without attributes),
 * then convert URLs and emails into links.
 */
export const safeTextLinks = (text) => {
  const cleaned = cleanHTML(text);
  return linkify(cleaned);
};

/**
 * safeText(text)
 * Clean the text, allowing only <b> tags (without attributes),
 * but do NOT convert URLs and emails into links.
 */
export const safeText = (text) => {
  return cleanHTML(text);
};