import React, { useState, useEffect } from 'react';
import TokenMintSTAS789 from './TokenMintSTAS789';
import TransactionOutcome from './TransactionOutcome';
import {convertCamelToCamelCase} from '../utils/stringUtils'; // adjust path if needed
const MintOverlay = ({
  show,
  mintFromCard,
  wallet,
  mintParams: initialMintParams, // initial parameters from parent
  outcome,
  onTransactionComplete,
  onCopyOutcome,
  onResetOutcome,
  onClose,
  onUpdateMintParams, // callback to update mint parameters in parent
}) => {
  const [mintParams, setMintParams] = useState(initialMintParams || {});

  // Update local state if parent provided new initial parameters.
  useEffect(() => {
    setMintParams(initialMintParams || {});
  }, [initialMintParams]);

  // Handler to update mint parameters both internally and notify parent.
  const handleParamsChange = (newParams) => {
    const updatedParams = { ...mintParams, ...newParams };
    setMintParams(updatedParams);
    if (onUpdateMintParams) {
      onUpdateMintParams(updatedParams);
    }
  };

  if (!show) return null;
  return (
    <div
      className="modal fade show"
      style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header justify-content-between">
            <span className="modal-title">
              <b>
                {mintParams && mintParams.reaction && mintParams.reaction.type
                  ? convertCamelToCamelCase(mintParams.reaction.type)
                  : 'Publish an NFT Post'}
              </b>
            </span>
            <button type="button" className="btn btn-sm btn-secondary" onClick={onClose}>
              <span>Close</span>
            </button>
          </div>
          <div className="modal-body">
            
            {outcome ? (
              <TransactionOutcome
                outcome={outcome}
                onCopyOutcome={onCopyOutcome}
                onResetOutcome={onResetOutcome}
              />
            ) : (
              <TokenMintSTAS789
                mintFromCard={mintFromCard}
                wallet={wallet}
                params={mintParams}
                onTransactionComplete={onTransactionComplete}
                onUpdateParams={handleParamsChange} // pass update callback to child too, if needed
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintOverlay;