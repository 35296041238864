import React from 'react';
import { NavLink } from 'react-router-dom';
import '../css/Footer.css'; // For footer styling
import { useGlobalAlert } from './GlobalAlert';

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="footer-content">
        <div className="footer-links">
          <NavLink to="/faq">FAQ</NavLink>
          <NavLink to="/tos">ToS</NavLink>
        </div>
        <button className="btn btn-light p-0 px-2 py-1" onClick={() => window.location.href='/donate'}>
          Support us <i className="fas fa-heart"></i>
        </button>
      </div>
    </footer>
  );
};

export default Footer;
