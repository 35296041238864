import React from 'react';
import '../css/InfoPages.css';
import Accordion from 'react-bootstrap/Accordion';
import { ConfigContext } from '../config';

const Privacy = () => {
  const config = React.useContext(ConfigContext);
  return (
    <div className="info-page">
      <h1>Privacy Policy</h1>
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Data Collection & Local Storage</Accordion.Header>
          <Accordion.Body>
            {config.BRAND_NAME} stores certain information locally on your device to facilitate a smooth user experience. This includes your connected wallet details and related activity data (browsing history, search queries, and messaging interactions). These details remain on your device to enable personalized suggestions and efficient messaging. We do not transmit or share this data with third parties without your consent.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Cookies and Tracking Technologies</Accordion.Header>
          <Accordion.Body>
            Our website may use cookies and similar tracking technologies to improve your experience. These tools help us remember your preferences and facilitate smoother interactions with {config.BRAND_NAME}. The collected data assists in providing personalized content, targeted suggestions, and seamless communication. By using our platform, you consent to these technologies as described in this Privacy Policy.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>User Activity and Interactions</Accordion.Header>
          <Accordion.Body>
            In addition to local storage, {config.BRAND_NAME} collects data about your activity on the platform. This includes usage statistics, interaction logs, and feedback when using various features. This information is used solely to optimize user experience, enhance our service offerings, and support effective communication between creators and audiences.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Further Information</h5>
          <p className="card-text">
            We continuously review and improve our privacy practices to ensure that your data remains solely under your control. For any additional questions or concerns, please contact our support team. By using {config.BRAND_NAME}, you acknowledge and agree to our handling of your data as described above.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
