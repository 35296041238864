import React, { useRef, useState, useEffect } from 'react';

const VideoPlayer = ({ file, className, style, ...rest }) => {
  const videoRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [muted, setMuted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  
  // When receiving file, create an Object URL.
  useEffect(() => {
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setVideoSrc(objectURL);
      return () => {
        URL.revokeObjectURL(objectURL);
      };
    }
  }, [file]);

  useEffect(() => {
    const vid = videoRef.current;
    if (!vid) return;
    // Force muted autoplay exactly as in DecryptedVideo.js
    vid.muted = true;
    setMuted(true);
    vid.play().catch(() => {});
  }, [videoSrc]);

  useEffect(() => {
    if (videoSrc && videoRef.current) {
      videoRef.current.onloadedmetadata = () => {
        setIsLoading(false);
      };
    }
  }, [videoSrc]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    const handleTimeUpdate = () => {
      setProgress((video.currentTime / video.duration) * 100);
    };
    video.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  const togglePlay = () => {
    const video = videoRef.current;
    if (!video) return;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleProgressChange = (e) => {
    const video = videoRef.current;
    if (!video) return;
    const percent = Number(e.target.value);
    video.currentTime = (percent / 100) * video.duration;
    setProgress(percent);
  };

  const handleVolumeChange = (e) => {
    const video = videoRef.current;
    if (!video) return;
    const vol = Number(e.target.value);
    video.volume = vol;
    setVolume(vol);
  };

  // Cycle through predefined speeds.
  const speedOptions = [0.5, 1, 1.5, 2];
  const cycleSpeed = () => {
    const currentIndex = speedOptions.indexOf(playbackRate);
    const nextIndex = (currentIndex + 1) % speedOptions.length;
    const newSpeed = speedOptions[nextIndex];
    setPlaybackRate(newSpeed);
    videoRef.current.playbackRate = newSpeed;
  };

  // Clicking the video area will unmute.
  const handleVideoClick = () => {
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
      setMuted(false);
    }
  };

  // Toggle fullscreen (with support for iOS fullscreen)
  const toggleFullScreen = () => {
    const video = videoRef.current;
    if (!video) return;
    if (video.webkitEnterFullscreen) {
      video.webkitEnterFullscreen();
    } else {
      const container = video.parentElement;
      if (!document.fullscreenElement) {
        container.requestFullscreen().catch(err => console.error(err));
      } else {
        document.exitFullscreen();
      }
    }
  };

  

  
  return (
    <div className="video-player-container" style={{ position: 'relative', ...style }}>
      <video
        ref={videoRef}
        src={videoSrc}
        preload="metadata"
        playsInline
        style={{ width: '100%', display: 'block' }}
        onClick={handleVideoClick}
        loop
        {...rest}
      />
      <div
        className="custom-controls p-2 bg-light"
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '-6px'
        }}
      >
        {/* Left Controls: Play/Pause */}
        <div className="left-controls" style={{ flex: '0 0 auto' }}>
          <button onClick={togglePlay} className="btn btn-sm btn-light">
            {isPlaying ? <i className="fa fa-pause"></i> : <i className="fa fa-play"></i>}
          </button>
        </div>
        {/* Center Controls: Progress Bar */}
        <div className="center-controls" style={{ flex: '1 1 auto', margin: '0 10px', display: 'flex', alignItems: 'center' }}>
          <input
            type="range"
            min="0"
            max={videoRef.current?.duration || 0}
            value={videoRef.current ? videoRef.current.currentTime : 0}
            onChange={handleProgressChange}
            style={{ width: '100%' }}
          />
          <span 
            className="d-none d-md-inline-block"
            style={{ color: '#000', marginLeft: '8px', fontSize: '0.9rem', whiteSpace: 'nowrap' }}
          >
            {(videoRef.current && Math.floor(videoRef.current.currentTime)) || 0} / {(videoRef.current && Math.floor(videoRef.current.duration)) || 0}
          </span>
        </div>
        {/* Right Controls: Volume, Speed, Fullscreen */}
        <div className="right-controls" style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center' }}>
          <button onClick={() => { videoRef.current.muted = !videoRef.current.muted; setMuted(videoRef.current.muted); }} className="btn btn-sm btn-light" style={{ marginRight: '8px' }}>
            {muted ? <i className="fa fa-volume-mute"></i> : <i className="fa fa-volume-up"></i>}
          </button>
          <div style={{ marginRight: '8px' }}>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              style={{ width: '80px' }}
            />
          </div>
          <button onClick={cycleSpeed} className="btn btn-sm btn-light" style={{ marginRight: '8px' }}>
            <i className="fa fa-tachometer"></i> {playbackRate}x
          </button>
          <button onClick={toggleFullScreen} className="btn btn-sm btn-light">
            <i className="fa fa-arrows-alt"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
