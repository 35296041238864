import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import EmbeddedPost from '../components/EmbeddedPost';
import TransactionOutcome from '../components/TransactionOutcome';
import '../css/TransferNFT.css';
import stas from 'stas-sdk';
import UTXOSelectorOverlay from '../components/UTXOSelectorOverlay';
import { transformStasUtxo, transformUtxo } from '../utils/mintAllocations';
import bsv from 'bsv';
import { broadcastTransactions, dynamicApiCall, transferProcessing, redeemProcessing } from '../services/apiOutCalls';
import { getPKHash, getLockingScriptHex } from '../utils/mintAllocations';
import { useGlobalAlert } from '../components/GlobalAlert';
import { AuthContext } from '../context/AuthProvider';

const FEE_PER_TRANSFER = 20; // fee per transfer in satoshis

const TransferNFT = () => {
  const { showAlert } = useGlobalAlert();
  const navigate = useNavigate();
  
  const { tokenId, symbol } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const splittable = searchParams.get('splittable') === 'true';  
  const { post } = location.state || { post: { tokenId, symbol } };

  const { wallet } = useContext(AuthContext);

  // New state for token balance
  const [tokenBalance, setTokenBalance] = useState(0);

  // State for tab selection.
  const [activeTab, setActiveTab] = useState("transfer"); // "transfer" or "redeem"

  // Common states.
  const [recipient, setRecipient] = useState('');
  const [paymentUTXO, setPaymentUTXO] = useState(null);
  const [showPaymentUTXOOverlay, setShowPaymentUTXOOverlay] = useState(false);

  // Separate amount fields for Transfer and Redeem.
  const [transferAmount, setTransferAmount] = useState('');
  const [redeemAmount, setRedeemAmount] = useState('');

  // Outcomes and errors.
  const [transferOutcome, setTransferOutcome] = useState('');
  const [transferError, setTransferError] = useState('');
  const [redeemOutcome, setRedeemOutcome] = useState({});
  const [redeemError, setRedeemError] = useState('');

  // Loader states for each tab.
  const [transferLoading, setTransferLoading] = useState(false);
  const [redeemLoading, setRedeemLoading] = useState(false);

  stas.utility.SATS = 10;
  stas.utility.PERBYTE = 1024;

  // NEW: loadTokenBalance runs on page load when splittable is true.
  const loadTokenBalance = async () => {
    try {
      if (!wallet) return;
      const query = {
        action: "getNFTUnspent",
        tokenId,
        symbol,
        from: 0,
        limit: 10,
        address: wallet.address
      };
      const result = await dynamicApiCall(wallet,query);
      if (result.success && result.data) {
        let total = 0;
        const allUtxos = Array.isArray(result.data) ? result.data : [result.data];
        for (const item of allUtxos) {
          if (item.utxos && item.utxos.length > 0) {
            for (let utxo of item.utxos) {
              const transformed = transformStasUtxo(utxo);
              total += transformed.satoshis;
            }
          }
        }
        setTokenBalance(total);
      }
    } catch (error) {
      console.error('Error in loadTokenBalance:', error);
    }
  };

  useEffect(() => {
    if (splittable) {
      loadTokenBalance();
    }
  }, [splittable, tokenId, symbol, wallet]);

  const handleSelectUTXO = async () => {
    try {
      if (!wallet) {
        console.error('No wallet found in local storage.');
        return [];
      }
      const query = {
        action: "getNFTUnspent",
        tokenId,
        symbol,
        from: 0,
        limit: 10,
        address: wallet.address
      };
      const result = await dynamicApiCall(wallet,query);
      if (!result.success) {
        console.error('Error fetching STAS UTXOs:', result.error);
        return [];
      }
      const allUtxos = Array.isArray(result.data) ? result.data : [result.data];
      if (splittable) {
        let accumulated = 0;
        const transformedList = [];
        const amountNeeded = parseInt(activeTab === "transfer" ? transferAmount : redeemAmount, 10);
        const flattened = [];
        for (const item of allUtxos) {
          if (item.utxos && item.utxos.length > 0) {
            flattened.push(...item.utxos);
          }
        }
        flattened.sort((a, b) => b.satoshis - a.satoshis);
        for (const utxo of flattened) {
          const transformed = transformStasUtxo(utxo);
          transformedList.push(transformed);
          accumulated += transformed.satoshis;
          if (accumulated >= amountNeeded) break;
        }
        if (accumulated < amountNeeded) {
        }
        return transformedList;
      } else {
        // Non-splittable: return the first UTXO.
        const resultUtxos = allUtxos[0] && allUtxos[0].utxos && allUtxos[0].utxos.length > 0 
          ? transformStasUtxo(allUtxos[0].utxos[0]) 
          : null;
        return resultUtxos ? [resultUtxos] : [];
      }
    } catch (error) {
      console.error('Error in handleSelectUTXO:', error);
      return [];
    }
  };

  const handleSelectPaymentUTXO = () => {
    setShowPaymentUTXOOverlay(true);
  };

  // Add a function to reset transfer-related state to initial values.
  const resetTransferState = () => {
    setRecipient('');
    setTransferAmount('');
    setPaymentUTXO(null);
    setTransferOutcome('');
    setTransferError('');
  };

  const handleCreateTransaction = async () => {
    setTransferLoading(true);
    
    const selectedUTXO = await handleSelectUTXO();

    try {
      setTransferOutcome('');
      setTransferError('');
      if (!wallet) {
        setTransferError('No wallet found in local storage.');
        return;
      }
      const ownerPrivatekey = bsv.PrivateKey.fromWIF(wallet.privateKey);
      if (!selectedUTXO || (splittable && selectedUTXO.length === 0)) {
        setTransferError("No STAS UTXO available. NFT UTXO is auto-loaded on page load.");
        return;
      }
      if (!paymentUTXO) {
        setTransferError("No payment UTXO selected. Please select a payment UTXO.");
        return;
      }
      if (splittable) {
        if (!transferAmount || isNaN(parseInt(transferAmount, 10)) || parseInt(transferAmount, 10) <= 0) {
          setTransferError("Please enter a valid transfer amount.");
          return;
        }
        
        const numTransfers = selectedUTXO.length;
        const requiredFee = numTransfers * FEE_PER_TRANSFER;
        if (paymentUTXO.satoshis < requiredFee) {
          setTransferError(`Payment UTXO does not cover the required fee of ${requiredFee} sats.`);
          return;
        }
        
        let totalNeeded = parseInt(transferAmount, 10);
        let currentPaymentUTXO = paymentUTXO;
        let combinedOutcome = [];
        
        for (let i = 0; i < selectedUTXO.length; i++) {
          if (i === selectedUTXO.length - 1) {
            if (selectedUTXO[i].satoshis > totalNeeded) {
              const splitDestinations = [
                { satoshis: totalNeeded, address: recipient },
                { satoshis: selectedUTXO[i].satoshis - totalNeeded, address: wallet.address }
              ];
              const transferTx = await stas.stasSplit.signed(
                ownerPrivatekey,
                selectedUTXO[i],
                splitDestinations,
                currentPaymentUTXO,
                ownerPrivatekey
              );
              combinedOutcome.push(transferTx.toString());
            } else {
              const transferTx = await stas.stasTransfer.signed(
                ownerPrivatekey,
                selectedUTXO[i],
                recipient,
                currentPaymentUTXO,
                ownerPrivatekey
              );
              totalNeeded -= selectedUTXO[i].satoshis;
              combinedOutcome.push(transferTx.toString());
            }
          } else {
            const transferTx = await stas.stasTransfer.signed(
              ownerPrivatekey,
              selectedUTXO[i],
              recipient,
              currentPaymentUTXO,
              ownerPrivatekey
            );
            totalNeeded -= selectedUTXO[i].satoshis;
            combinedOutcome.push(transferTx.toString());
            currentPaymentUTXO = stas.utility.getUtxoFromTx(transferTx, 1);
            if (!currentPaymentUTXO) {
              setTransferError("Failed to extract payment UTXO from transfer transaction.");
              return;
            }
          }
        }
        
        const broadcastResults = await broadcastTransactions(wallet,combinedOutcome);
        let outcome;
        if (Array.isArray(broadcastResults) && broadcastResults.length > 0) {
          const failedResult = broadcastResults.find(result => result.error && result.error.code !== 0);
          if (failedResult) {
            outcome = { message: failedResult.error.message };
          } else {
            outcome = { txid: broadcastResults.map(result => result.txid).join(', ') };
          }
        } else {
          outcome = { message: 'There seems to have been an issue with finishing the transaction. Check your balance after 20-30 minutes.' };
        }
        setTransferOutcome(outcome);
      } else {

        // Non-splittable transfer: use new backend transferProcessing
        const transferTx = await stas.stasTransfer.signed(
          ownerPrivatekey,
          selectedUTXO[0],
          recipient,
          paymentUTXO,
          ownerPrivatekey
        );

        const result = await transferProcessing(
          transferTx.toString(),
          { tokenId, symbol},
          getPKHash(getLockingScriptHex(recipient)),
          wallet
        );
        if (result.error) {
          showAlert(result.error, "danger", "Transfer Failed", false);
        } else {
          showAlert("Transfer processed successfully", "info", "Transfer Completed");
          resetTransferState();
          navigate('/profile');
        }
        setTransferOutcome(result);
      }
    } catch (error) {
      setTransferError(`Error creating transfer transaction: ${error.message}`);
    } finally {
      setTransferLoading(false);
    }
  };

  const handleRedeemTransaction = async () => {
    setRedeemLoading(true);
    const selectedUTXO = await handleSelectUTXO();
    try {
      setRedeemOutcome('');
      setRedeemError('');
      if (!wallet) {
        setRedeemError('No wallet found in local storage.');
        return;
      }
      const ownerPrivatekey = bsv.PrivateKey.fromWIF(wallet.privateKey);
      if (!selectedUTXO || (Array.isArray(selectedUTXO) && selectedUTXO.length === 0)) {
        setRedeemError("No STAS UTXO available. NFT UTXO is auto-loaded on page load.");
        return;
      }
      if (!paymentUTXO) {
        setRedeemError("No payment UTXO selected. Please select a payment UTXO.");
        return;
      }
      
      let currentPaymentUTXO = paymentUTXO;
      let combinedOutcome = [];
      if (splittable) {
        if (!redeemAmount || isNaN(parseInt(redeemAmount, 10)) || parseInt(redeemAmount, 10) <= 0) {
          setRedeemError("Please enter a valid redeem amount.");
          return;
        }
        let totalNeeded = parseInt(redeemAmount, 10);
        for (let i = 0; i < selectedUTXO.length; i++) {
          if (i === selectedUTXO.length - 1) {
            if (selectedUTXO[i].satoshis > totalNeeded) {
              const redeemTx = await stas.stasRedeemSplit.signed(
                ownerPrivatekey,
                selectedUTXO[i],
                [{ satoshis: selectedUTXO[i].satoshis-totalNeeded, address: wallet.address }],
                currentPaymentUTXO,
                ownerPrivatekey
              );
              combinedOutcome.push(redeemTx.toString());
            } else {
              const redeemTx = await stas.stasRedeem.signed(
                ownerPrivatekey,
                selectedUTXO[i],
                currentPaymentUTXO,
                ownerPrivatekey
              );
              totalNeeded -= selectedUTXO[i].satoshis;
              combinedOutcome.push(redeemTx.toString());
            }
          } else {
            const redeemTx = await stas.stasRedeem.signed(
              ownerPrivatekey,
              selectedUTXO[i],
              currentPaymentUTXO,
              ownerPrivatekey
            );
            combinedOutcome.push(redeemTx.toString());
            currentPaymentUTXO = stas.utility.getUtxoFromTx(redeemTx, 1);
            if (!currentPaymentUTXO) {
              setRedeemError("Failed to extract payment UTXO from redeem transaction.");
              return;
            }
          }
        }
        const params = {redeem:true};
        
        const broadcastResults = await broadcastTransactions(wallet, combinedOutcome, params);
        let outcome;
        if (Array.isArray(broadcastResults) && broadcastResults.length > 0) {
          const failedResult = broadcastResults.find(result => result.error && result.error.code !== 0);
          if (failedResult) {
            outcome = { message: failedResult.error.message };
          } else {
            outcome = { txid: broadcastResults.map(result => result.txid).join(', ') };
          }
        } else {
          outcome = { message: 'There seems to have been an issue with redeeming. Check your balance after a while.' };
        }
        setRedeemOutcome(outcome);
      } else {
        const redeemTx = await stas.stasRedeem.signed(
          ownerPrivatekey,
          selectedUTXO[0],
          paymentUTXO,
          ownerPrivatekey
        );
        const result = await redeemProcessing(
          redeemTx.toString(),
          { tokenId, symbol},
          wallet
        );
        if (result.error) {
          showAlert(result.error, "danger", "Redemption Failed", false);
        } else {
          showAlert("Redemption processed successfully", "info", "Redemption Completed");
          resetTransferState();
          navigate('/profile');
        }
        setTransferOutcome(result);
      }
    } catch (error) {
      setRedeemError(`Error creating redeem transaction: ${error.message}`);
    } finally {
      setRedeemLoading(false);
    }
  };

  const handleTransfer = async () => {
    const transferData = {
      action: "transferNFT",
      tokenId,    // NFT token id
      symbol,     // NFT symbol
      recipient,  // Destination address
    };
    try {
      setTransferError('');
      const result = await dynamicApiCall(wallet,wallet.privateKey, transferData);
      if (result.success) {
        setTransferOutcome('Transfer successful!');
      } else {
        setTransferError(result.error || 'Transfer failed.');
      }
    } catch (err) {
      setTransferError(err.message || 'Transfer error.');
    }
  };

  return (
    <div className="m-0 p-3">
      {splittable ? (
        <div className="balance-display mb-3">
          <h4>Balance ${symbol || 'Sats'}: {tokenBalance}</h4>
        </div>
      ) : (
        <EmbeddedPost wallet={wallet} embedType="preview" meta={{contractTx: post.contractTx}} />
      )}
      
      {/* Tab Header */}
      <div className="tab-header mt-3">
        <button 
          className={`btn btn-info ${activeTab === 'transfer' ? 'active' : ''}`}
          onClick={() => setActiveTab("transfer")}
        >
          Transfer
        </button>
        <button 
          className={`btn btn-info ${activeTab === 'redeem' ? 'active' : ''}`}
          onClick={() => setActiveTab("redeem")}
        >
          Burn
        </button>
      </div>
      
      {activeTab === "transfer" && (
        <div className="card mt-4">
          <div className="card-header">Transfer to an Address</div>
          <div className="card-body">
            {transferLoading ? (
              <div className="loader">Loading...</div>
            ) : transferOutcome && transferOutcome.txid ? (
              <TransactionOutcome outcome={transferOutcome} />
            ) : (
              <>
                <form className="transfer-form" onSubmit={(e) => { e.preventDefault(); handleCreateTransaction(); }}>
                  <div className="form-group">
                    <label htmlFor="recipient">Recipient Address</label>
                    {!splittable && (
                    <p>Please note that any revenue on NFTs will go to the new owner once we enable the marketplace functionality.</p>
                    )}
                    <input 
                      type="text" 
                      id="recipient" 
                      className="form-control" 
                      placeholder="Enter recipient address" 
                      value={recipient} 
                      onChange={(e) => setRecipient(e.target.value)}
                      required
                    />
                  </div>
                  {splittable && (
                    <div className="form-group mt-2">
                      <label htmlFor="amount">Amount to Transfer</label>
                      <input 
                        type="number"
                        id="amount" 
                        className="form-control" 
                        placeholder={`Enter amount in ${symbol || 'sats'}`} 
                        value={transferAmount}
                        onChange={(e) => setTransferAmount(e.target.value)}
                        required
                        min={0}
                        step="0.0001"
                      />
                    </div>
                  )}
                  <div className="button-row mt-3">
                    <button type="button" className="btn btn-secondary" onClick={handleSelectPaymentUTXO}>
                      {paymentUTXO ? "✓ Payment UTxO Selected" : "Select 1 UTxO to cover Fee"}
                    </button>
                    <button type="submit" className="btn btn-primary">
                    Execute Transfer
                    </button>
                  </div>
                  {transferError && (
                    <div className="alert alert-danger mt-2">
                      {transferError}
                    </div>
                  )}
                </form>
                {(transferOutcome && transferOutcome.message) && (
                <TransactionOutcome outcome={transferOutcome} />
                )}
              </>
            )}
          </div>
          {!splittable && (
          <div className="card-footer">
            NFTs cannot be sent back to the creator address once left
          </div>
          )}
        </div>
      )}
      
      {activeTab === "redeem" && (
        <div className="card mt-4">
          <div className="card-header">Burn and Redeem Locked sats</div>
          <div className="card-body">
          {redeemLoading ? (
              <div className="loader">Loading...</div>
            ) : redeemOutcome && redeemOutcome.txid ? (
              <TransactionOutcome outcome={transferOutcome} />
            ) : (
              <>
                <p>Destroy the NFT and redeem sats into issuer's wallet. Do NOT use this if you don't know what you do.</p>
                {splittable && (
                  <div className="form-group mt-2">
                    <label htmlFor="redeemAmount">Burn Amount</label>
                    <input 
                      type="number" 
                      id="redeemAmount" 
                      className="form-control" 
                      placeholder={`Enter amount in ${symbol || 'sats'}`} 
                      value={redeemAmount}
                      onChange={(e) => setRedeemAmount(e.target.value)}
                      required
                    />
                  </div>
                )}
                <div className="button-row">
                  <button type="button" className="btn btn-secondary" onClick={handleSelectPaymentUTXO}>
                    {paymentUTXO ? "✓ Payment UTxO Selected" : "Select 1 UTxO"}
                  </button>
                  <button type="button" className="btn btn-danger" onClick={handleRedeemTransaction}>
                    Redeem to Address
                  </button>
                </div>
                {(redeemOutcome && redeemOutcome.message) && (
                <TransactionOutcome outcome={redeemOutcome} />
                )}
                

              </>
            )}
          </div>
          {splittable && (
                <div className='card-footer'>Only the issuer can burn this type of token. Otherwise you will get script execution error.</div>
          )}
        </div>
      )}
      
      {showPaymentUTXOOverlay && (
        <UTXOSelectorOverlay 
          wallet={wallet}
          requiredUTXOs={[100]}
          onSelectionComplete={(utxos) => {
            if (utxos && utxos.length > 0) {
              setPaymentUTXO(transformUtxo(utxos[0], wallet.address));
            }
            setShowPaymentUTXOOverlay(false);
          }}
          onClose={() => setShowPaymentUTXOOverlay(false)}
        />
      )}
    </div>
  );
};

export default TransferNFT;