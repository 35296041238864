import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';  // <-- added
import { PrivateKey } from '@bsv/sdk';
import CryptoJS from 'crypto-js'; // Import CryptoJS for decryption
import '../css/WalletCreator.css';
import { getAddressFromKey } from '../utils/hash160';
import { initGlobalSignaler } from '../services/globalSignaler'; // NEW import for reinitialization of the signaler connection
import { AuthContext } from '../context/AuthProvider';
import OverlayConfirm from '../components/OverlayConfirm';  // Add OverlayConfirm import if not already present

const Authenticate = () => {
  const navigate = useNavigate();  // <-- added
  const { saveWallet } = useContext(AuthContext);
  // Copy the authentication form (formerly when wallet==null) from WalletCreator.
  const [option, setOption] = useState('new');
  const [privateKeyInput, setPrivateKeyInput] = useState('');
  const [chainkeyWord, setChainkeyWord] = useState('');
  const [chainkeyPosition, setChainkeyPosition] = useState('after1');
  const [attemptCount, setAttemptCount] = useState(0);
  const [currentAttempt, setCurrentAttempt] = useState(null);
  const [isGeneratingChainkey, setIsGeneratingChainkey] = useState(false);
  const [chainkeyGenerationStarted, setChainkeyGenerationStarted] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);
  // New state for advanced options (for create account)
  const [advancedOptions, setAdvancedOptions] = useState(false);
  const cancelGeneration = useRef(false); // added cancellation ref
  // NEW: State for passphrase overlay
  const [showPassphrasePrompt, setShowPassphrasePrompt] = useState(false);
  const [passphraseCallback, setPassphraseCallback] = useState(null);

  const handleTosChange = (e) => {
    setTosAccepted(e.target.checked);
  };

  // This component does not manage wallet state (that is left for Profile).
  // Instead, when wallet is created/imported, save it in localStorage.
  
  const generateChainkeyWallet = (targetWord, position) => {
    let localAttemptCount = 0;
    const iterate = () => {
      if (cancelGeneration.current) { // added cancellation check
        setIsGeneratingChainkey(false);
        setChainkeyGenerationStarted(false);
        return;
      }
      localAttemptCount++;
      const candidatePrivKey = PrivateKey.fromRandom();
      const candidatePubKey = candidatePrivKey.toPublicKey();
      const candidatepubKeyString = candidatePubKey.toString();
      const candidateAddress = getAddressFromKey(candidatepubKeyString);
      setAttemptCount(localAttemptCount);
      setCurrentAttempt({
        privateKey: candidatePrivKey.toWif(),
        pubKeyString: candidatepubKeyString,
        address: candidateAddress,
      });
      let condition = false;
      if (position === 'after1') {
        condition = candidateAddress.startsWith('1' + targetWord);
      } else if (position === 'anywhere') {
        condition = candidateAddress.includes(targetWord);
      } else if (position === 'end') {
        condition = candidateAddress.endsWith(targetWord);
      }
      if (condition) {
        const walletData = {
          privateKey: candidatePrivKey.toWif(),
          pubKeyString: candidatepubKeyString,
          address: candidateAddress,
        };
        saveWallet(walletData);
        setIsGeneratingChainkey(false);
        // initGlobalSignaler(walletData); // Instead of resetting undefined globalSignalerInstance, reinitialize via initGlobalSignaler.
        navigate('/profile');   // <-- redirect when wallet is generated
      } else {
        setTimeout(iterate, 0);
      }
    };
    iterate();
  };

  // NEW: Helper to request passphrase via OverlayConfirm
  const getPassphraseFromUser = () => {
    return new Promise((resolve) => {
      setShowPassphrasePrompt(true);
      setPassphraseCallback(() => resolve);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (option === 'existing') {
      let privKey;
      try {
        // Try to parse directly as WIF
        privKey = PrivateKey.fromWif(privateKeyInput.trim());
      } catch (error) {
        // Instead of using prompt(), use overlay confirm
        const passphrase = await getPassphraseFromUser();
        if (!passphrase) {
          alert("Passphrase required for decryption.");  // fallback if canceled; alternatively, return silently.
          return;
        }
        try {
          const bytes = CryptoJS.AES.decrypt(privateKeyInput.trim(), passphrase);
          const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
          if (!decryptedText) {
            alert("Invalid backup data.");
            return;
          }
          // Split lines and trim them to remove extra whitespace
          const lines = decryptedText.split('\n').map(line => line.trim());
          const pkLine = lines.find(line => line.startsWith("Private Key:"));
          if (!pkLine) {
            alert("Invalid backup data.");
            return;
          }
          const pk = pkLine.replace("Private Key:", "").trim();
          privKey = PrivateKey.fromWif(pk);
        } catch (decryptionError) {
          alert('Decryption failed. The passphrase may be incorrect.');
          return;
        }
      }
      const pubKey = privKey.toPublicKey();
      const pubKeyString = pubKey.toString();
      const address = pubKey.toAddress().toString();
      const walletData = {
        privateKey: privKey.toWif(),
        pubKeyString,
        address,
      };
      saveWallet(walletData);
      // initGlobalSignaler(walletData); // Instead of setting globalSignalerInstance = null, simply reinitialize the connection.
      navigate('/profile');   // Redirect after import
    } else {
      if (chainkeyWord.trim() !== '') {
        setChainkeyGenerationStarted(true);
        setIsGeneratingChainkey(true);
        setAttemptCount(0);
        setCurrentAttempt(null);
        cancelGeneration.current = false; // reset cancel flag before starting
        generateChainkeyWallet(chainkeyWord.trim(), chainkeyPosition);
      } else {
        const privKey = PrivateKey.fromRandom();
        const pubKey = privKey.toPublicKey();
        const pubKeyString = pubKey.toString();
        const address = pubKey.toAddress().toString();
        const walletData = {
          privateKey: privKey.toWif(),
          pubKeyString,
          address,
        };
        saveWallet(walletData);
        // initGlobalSignaler(walletData); // Reinitialize the signaler connection.
        navigate('/profile');   // <-- redirect when wallet generated without chainkey word
      }
    }
  };

  const handleStop = () => {
    cancelGeneration.current = true; // set cancel flag to stop iterations
    setIsGeneratingChainkey(false);
    setChainkeyGenerationStarted(false);
    setCurrentAttempt(null);
    setAttemptCount(0);
  };

  if (chainkeyGenerationStarted && isGeneratingChainkey) {
    return (
      <div className="p-2">
      <div className="card shadow-sm p-4 m-0">
      <p><b>Generating Account...</b></p>
        <p>
          <strong>Attempts:</strong> {attemptCount}
        </p>
        {currentAttempt && (
          <div>
            <p>
              <strong>Private Key:</strong>{' '}
              <span className="text-monospace">{currentAttempt.privateKey}</span>
            </p>
            <p>
              <strong>Public Key:</strong>{' '}
              <span className="text-monospace">{currentAttempt.pubKeyString}</span>
            </p>
            <p>
              <strong>Address:</strong>{' '}
              <span className="text-monospace">{currentAttempt.address}</span>
            </p>
          </div>
        )}
        <button className="btn btn-danger mt-3" onClick={handleStop}>
          Stop
        </button>
      </div>
      </div>
    );
  }

  return (
    <div className="auth-landing p-4">
      
      <form onSubmit={handleSubmit} className="card shadow-sm p-4 auth-form">
        {/* New grid container for account options */}
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "1rem" }}>
          <div 
            onClick={() => setOption('new')} 
            style={{ cursor: "pointer", border: "1px solid #ccc", borderRadius: "4px", backgroundColor: "#f9f9f9", padding: "10px" }}
          >
            <input
              type="radio"
              id="newWallet"
              name="walletOption"
              value="new"
              checked={option === 'new'}
              onChange={() => setOption('new')}
              className="form-check-input"
              style={{ marginRight: "10px" }}
            />
            <label htmlFor="newWallet" className="form-check-label" style={{ cursor: "pointer" }}>
              Create Account
            </label>
          </div>
          <div 
            onClick={() => setOption('existing')} 
            style={{ cursor: "pointer", border: "1px solid #ccc", borderRadius: "4px", backgroundColor: "#f9f9f9", padding: "10px" }}
          >
            <input
              type="radio"
              id="existingWallet"
              name="walletOption"
              value="existing"
              checked={option === 'existing'}
              onChange={() => setOption('existing')}
              className="form-check-input"
              style={{ marginRight: "10px" }}
            />
            <label htmlFor="existingWallet" className="form-check-label" style={{ cursor: "pointer" }}>
              Import Backup
            </label>
          </div>
        </div>
        {option === 'existing' && (
          <>
            <div className="mb-3">
              <label htmlFor="privateKeyInput" className="form-label">
                Private Key (WIF)
              </label>
              <input
                type="text"
                id="privateKeyInput"
                className="form-control"
                value={privateKeyInput}
                onChange={(e) => setPrivateKeyInput(e.target.value)}
                placeholder="Enter your private key in WIF format"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="backupFileInput" className="form-label">
                Import Backup File
              </label>
              <input
                type="file"
                id="backupFileInput"
                className="form-control"
                accept=".txt"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const text = await file.text();
                    let backupText = text;
                    if (!backupText.startsWith("Private Key:")) {
                      // Backup might be encrypted; ask for passphrase via OverlayConfirm
                      const passphrase = await getPassphraseFromUser();
                      if (!passphrase) {
                        alert("Passphrase required to decrypt backup file.");
                        return;
                      }
                      const bytes = CryptoJS.AES.decrypt(backupText, passphrase);
                      backupText = bytes.toString(CryptoJS.enc.Utf8);
                      if (!backupText.startsWith("Private Key:")) {
                        alert("Failed to decrypt backup file. Incorrect passphrase or invalid file.");
                        return;
                      }
                    }
                    // Process plaintext backup
                    const lines = backupText.split('\n').map(line => line.trim());
                    const pkLine = lines.find(line => line.startsWith("Private Key:"));
                    if (pkLine) {
                      const pk = pkLine.replace("Private Key:", "").trim();
                      setPrivateKeyInput(pk);
                    } else {
                      alert("Backup file is missing required fields.");
                    }
                  }
                }}
              />
            </div>
          </>
        )}
        {option === 'new' && (
          <>
            {/* Advanced Options Wrapper */}
            <div 
              style={{ 
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                backgroundColor: "#f9f9f9", 
                padding: "10px", 
                marginBottom: "1rem",
              }}
            >
              <input
                type="checkbox"
                id="advancedOptions"
                checked={advancedOptions}
                onChange={() => setAdvancedOptions(!advancedOptions)}
                style={{ marginRight: "10px" }}
              />
              <label htmlFor="advancedOptions" style={{ cursor: "pointer" }}> Advanced Options </label>
              <label style={{ color: "#666" }}>
                {advancedOptions 
                  ? "You can now set 2-3 alphanumeric characters and the desired position in the wallet address, but be prepared to wait for a good while, with the browser open, until you get lucky for your address to be created."
                  : "Fancy a wallet with special characters inside the address, that means something to you? Try our Advanced options with our Vanity Wallet Generator."
                }
              </label>
            </div>
            {advancedOptions && (
              <>
                {/* Advanced inputs for chainkey */}
                <div className="mb-3">
                  <label htmlFor="chainkeyWordInput" className="form-label">
                    Fancy a special address? (Optional)
                  </label>
                  <input
                    type="text"
                    id="chainkeyWordInput"
                    className="form-control"
                    value={chainkeyWord}
                    onChange={(e) => setChainkeyWord(e.target.value)}
                    placeholder="Enter 2-3 alphanumeric characters"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="chainkeyPosition" className="form-label">
                    Position of Sequence
                  </label>
                  <select
                    id="chainkeyPosition"
                    className="form-select"
                    value={chainkeyPosition}
                    onChange={(e) => setChainkeyPosition(e.target.value)}
                  >
                    <option value="after1">Immediately after the initial '1'</option>
                    <option value="anywhere">Anywhere in the address</option>
                    <option value="end">At the end of the address</option>
                  </select>
                </div>
              </>
            )}
          </>
        )}
        <div className="tos-message">
          <input 
            type="checkbox" 
            id="tosCheck" 
            checked={tosAccepted} 
            onChange={handleTosChange} 
            style={{ marginRight: '0.5rem', marginBottom: '1rem' }}
          /> I have read and accept the <a href="/tos" target="_blank" rel="noopener noreferrer">ToS</a>.
        </div>
        <button type="submit" className="btn btn-primary w-100" disabled={!tosAccepted}>
          {option === 'new' ? 'Generate Keys' : 'Import Keys'}
        </button>
      </form>
      {showPassphrasePrompt && (
        // NEW: OverlayConfirm for passphrase
        <OverlayConfirm 
          confirmation={{ messages: ["Enter passphrase for encrypted backup:"], requiresInput: true }}
          optionsMsg={{ confirm: "Confirm", cancel: "Cancel" }}
          onComplete={(result) => {
            setShowPassphrasePrompt(false);
            if (passphraseCallback) {
              if (result.status && result.values && result.values[0]) {
                passphraseCallback(result.values[0].trim());
              } else {
                passphraseCallback(null);
              }
            }
          }}
        />
      )}
    </div>
  );
};

export default Authenticate;