import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import InitiateTransaction from '../components/InitiateTransaction';
import '../css/WalletCreator.css';
import { AuthContext } from '../context/AuthProvider';

const TransferPage = () => {
  const { wallet } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!wallet) {
      navigate('/auth');
    }
  }, [wallet, navigate]);

  if (!wallet) {
    return null; // or a loading indicator
  }

  return (
    <div className="m-0 p-3">
      <InitiateTransaction
          wallet={wallet}
          totalFunds={wallet.satoshis}
      />
    </div>
  );
};

export default TransferPage;