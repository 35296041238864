import React, { useEffect, useState, useContext } from 'react';
import devApiService from '../services/devApiService';
import { AuthContext } from '../context/AuthProvider';
import '../css/AdminApps.css';

const AdminApps = () => {
  const { wallet } = useContext(AuthContext);
  const [apps, setApps] = useState([]);
  const [updateSubmissions, setUpdateSubmissions] = useState([]);
  const [message, setMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState('apps'); // 'apps' or 'updates'

  // New helper to shorten long public keys for display
  const shortenKey = (key) => {
    if (!key) return 'Unknown';
    return key.length > 16 
      ? `${key.substring(0, 8)}...${key.substring(key.length - 8)}`
      : key;
  };

  useEffect(() => {
    if (wallet) {
      if (selectedTab === 'apps') {
        devApiService.fetchAppsAdmin(wallet)
          .then((res) => {
            setApps(Array.isArray(res) ? res : res.apps || []);
          })
          .catch((err) => setMessage(err.message || 'Error fetching apps'));
      } else if (selectedTab === 'updates') {
        devApiService.fetchUpdateSubmissions(wallet)
          .then((res) => {
            setUpdateSubmissions(Array.isArray(res) ? res : res.updateRequests || []);
          })
          .catch((err) => setMessage(err.message || 'Error fetching update submissions'));
      }
    }
  }, [wallet, selectedTab]);

  const updateStatus = async (appId, newStatus) => {
    if (!wallet) return;
    try {
      await devApiService.updateAppAdmin(wallet, appId, { reviewStatus: newStatus });
      setApps(apps.map(app =>
        app._id.toString() === appId ? { ...app, reviewStatus: newStatus } : app
      ));
      setMessage('Status updated.');
    } catch (err) {
      setMessage('Error updating app.');
    }
  };

  const deleteApp = async (appId) => {
    if (!wallet) return;
    try {
      await devApiService.deleteAppAdmin(wallet, appId);
      setApps(apps.filter(app => app._id !== appId));
      setMessage('App deleted successfully.');
    } catch (err) {
      setMessage('Error deleting app.');
    }
  };

  const handleUpdateDecision = async (appId, decision) => {
    if (!wallet) return;
    let reason = "";
    if(decision === "reject") {
      reason = prompt("Enter rejection reason:") || "";
    }
    try {
      await devApiService.decisionOnUpdateSubmission(wallet, appId, decision, reason);
      setUpdateSubmissions(updateSubmissions.filter(sub => sub.appId !== appId));
      setMessage(`Update ${decision}d successfully.`);
    } catch (err) {
      setMessage('Error processing decision.');
    }
  };

  return (
    <div className="container admin-apps-page">
      {message && <div className="alert alert-info">{message}</div>}

      {/* Tabs */}
      <div className="tabs mb-3">
        <button 
          className={`btn ${selectedTab === 'apps' ? 'btn-primary' : 'btn-outline-primary'} mx-1`}
          onClick={() => setSelectedTab('apps')}
        >
          New Apps
        </button>
        <button 
          className={`btn ${selectedTab === 'updates' ? 'btn-primary' : 'btn-outline-primary'} mx-1`}
          onClick={() => setSelectedTab('updates')}
        >
          Update Submissions
        </button>
      </div>

      {selectedTab === 'apps' && (
        <div className="card shadow-sm">
          <div className="card-body">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>App Name</th>
                  <th>Category</th>
                  <th>Developer</th>
                  <th>Status</th>
                  <th>Paused</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {apps.map(app => (
                  <tr key={app._id}>
                    <td>{app.appName}</td>
                    <td>{app.category}</td>
                    <td title={app.developer}>
                      <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '120px', display:'inline-block' }}>
                        {shortenKey(app.developer)}
                      </span>
                    </td>
                    <td>{app.reviewStatus}</td>
                    <td>{app.paused ? 'Paused' : 'Active'}</td>
                    <td className="text-center">
                      {app.reviewStatus !== 'approved' && (
                        <button className="btn btn-success btn-sm mx-1" onClick={() => updateStatus(app._id, 'approved')}>
                          Approve
                        </button>
                      )}
                      {app.reviewStatus !== 'blocked' && (
                        <button className="btn btn-danger btn-sm mx-1" onClick={() => updateStatus(app._id, 'blocked')}>
                          Block
                        </button>
                      )}
                      <button className="btn btn-outline-danger btn-sm mx-1" onClick={() => deleteApp(app._id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {selectedTab === 'updates' && (
        <div className="card shadow-sm">
          <div className="card-body">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>App ID</th>
                  <th>Developer</th>
                  <th>Submitted At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {updateSubmissions.map(sub => (
                  <tr key={sub._id}>
                    <td>{sub.appId}</td>
                    <td title={sub.developer}>
                      <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '120px', display:'inline-block' }}>
                        {shortenKey(sub.developer)}
                      </span>
                    </td>
                    <td>{new Date(sub.createdAt).toLocaleString()}</td>
                    <td className="text-center">
                      <button className="btn btn-success btn-sm mx-1" onClick={() => handleUpdateDecision(sub.appId, 'approve')}>
                        Approve
                      </button>
                      <button className="btn btn-danger btn-sm mx-1" onClick={() => handleUpdateDecision(sub.appId, 'reject')}>
                        Reject
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminApps;
