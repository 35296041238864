import { signPayload } from './apiOutCalls'; // Reuse canonical signPayload from apiOutCalls
import CryptoJS from 'crypto-js';

import {config} from '../config';

// Helper for GET/DELETE requests using config.API_URL
// It sends the JSON-stringified payload.data as a query parameter named "data".
const signedGet = (wallet, endpoint, dataObj = {}) => {
  const payload = { data: dataObj };
  // Sign the payload using our canonical function from apiOutCalls
  const { publicKey, signature } = signPayload(wallet.privateKey, payload);
  const query = encodeURIComponent(JSON.stringify(payload.data));
  const url = `${config.API_URL}${endpoint}?data=${query}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-signature": signature,
      "x-pubkey": publicKey,
    },
  }).then(res => res.json());
};

// Helper for non-GET (POST, PUT) requests using config.API_URL.
// Sends the payload in the body as { data: ... }.
const signedRequest = (wallet, method, endpoint, dataObj = {}) => {
  const payload = { data: dataObj };
  const { publicKey, signature, canonicalPayloadStr } = signPayload(wallet.privateKey, payload);

  return fetch(`${config.API_URL}${endpoint}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      "x-signature": signature,
      "x-pubkey": publicKey,
    },
    body: canonicalPayloadStr,
  }).then(res => res.json());
};

// New helper: sends FormData without setting Content-Type header.
export const signedFormDataRequest = (wallet, method, endpoint, formData) => {
  // For signing, we use an empty payload or minimal data.
  const payload = { data: {action: "submit_form_data"} };
  const { publicKey, signature } = signPayload(wallet.privateKey, payload);
  return fetch(`${config.API_URL}${endpoint}`, {
    method,
    headers: {
      "x-signature": signature,
      "x-pubkey": publicKey,
      // Do not set Content-Type; allow browser to set multipart boundary.
    },
    body: formData,
  }).then(res => res.json());
};

export const fetchAppsAdmin = (wallet) => {
  // GET request: data is passed as query parameter
  return signedGet(wallet, `${config.API_APPS_ADMIN}`, { action: "list_apps_admin" });
};

export const fetchAppsDev = (wallet) => {
  return signedGet(wallet, `${config.API_APPS_DEV}`, { action: "list_apps_dev" });
};

export const updateApp = (wallet, appId, updatedData) => {
  // POST request: data in body.data
  return signedRequest(wallet, "POST", `${config.API_APPS_DEV}/${appId}/update`, updatedData);
};

export const pauseToggleApp = (wallet, appId) => {
  return signedRequest(wallet, "POST", `${config.API_APPS_DEV}/${appId}/pause`, { appId });
};

export const deleteApp = (wallet, appId) => {
  // DELETE request using body.data
  return signedRequest(wallet, "DELETE", `${config.API_APPS_DEV}/${appId}`, { appId });
};

export async function findApp(wallet, appId) {
  // Use GET for individual app lookup
  return signedGet(wallet, `${config.API_APPS}/${appId}`);
}

export const getPublicApps = (wallet) => {
  // Uses the public API endpoint (i.e., GET "/api/apps") to fetch apps.
  return signedGet(wallet, `${config.API_APPS}`, {action:'list_public_apps'});
};

export const updateAppAdmin = (wallet, appId, updatedData) => {
	// Use PUT request on the admin route.
	return signedRequest(wallet, "PUT", `${config.API_APPS_ADMIN}/${appId}`, updatedData);
};

export const deleteAppAdmin = (wallet, appId) => {
	// Use DELETE request on the admin route.
	return signedRequest(wallet, "DELETE", `${config.API_APPS_ADMIN}/${appId}`, { appId });
};

export const submitApp = (wallet, appData) => {
	// If appData is a FormData instance, use the FormData request helper.
	if (appData instanceof FormData) {
		return signedFormDataRequest(wallet, "POST", `${config.API_APPS_DEV}`, appData);
	}
	return signedRequest(wallet, "POST", `${config.API_APPS_DEV}`, appData);
};

export const submitAppUpdate = (wallet, appId, updatedData) => {
	if (updatedData instanceof FormData) {
		return signedFormDataRequest(wallet, "POST", `${config.API_APPS_DEV}/${appId}/update`, updatedData);
	}
	return signedRequest(wallet, "POST", `${config.API_APPS_DEV}/${appId}/update`, updatedData);
};

// New helper: Retrieve update submissions (admin)
export const fetchUpdateSubmissions = (wallet) => {
  return signedGet(wallet, `${config.API_APPS_ADMIN}/app-updates`, { action: "list_update_submissions" });
};

// New helper: Retrieve update submissions for the developer
export const fetchMyUpdateSubmissions = (wallet) => {
  return signedGet(wallet, `${config.API_APPS_DEV}/app-update-submissions`, { action: "list_my_update_submissions" });
};

// New helper: Send decision on update submission (approve or reject)
// decision: "approve" or "reject"; reason is required only when decision is "reject"
export const decisionOnUpdateSubmission = (wallet, appId, decision, reason = "") => {
  const payload = decision === "reject" ? { decision, reason } : { decision };
  return signedRequest(wallet, "POST", `${config.API_APPS_ADMIN}/${appId}/update-decision`, payload);
};

// New helper: Upload file to IPFS using FormData (converts content to a File)
// UPDATED uploadFileToIpfs:
export const uploadFileToIpfs = async (wallet, fileData, fecthedConfig) => {
  try {
    const passphrase = fecthedConfig.CONTENT_PASSPHRASE;
    let file;
    if (fileData instanceof File) {
      // Read file as Data URL and then encrypt
      const dataUrl = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(fileData);
      });
      const encrypted = CryptoJS.AES.encrypt(dataUrl, passphrase).toString();
      const blob = new Blob([encrypted], { type: 'text/plain' });
      // Preserve original file name, but content is now encrypted
      file = new File([blob], fileData.name, { type: 'text/plain' });
    } else if (typeof fileData === 'string') {
      // Encrypt plain text
      const encrypted = CryptoJS.AES.encrypt(fileData, passphrase).toString();
      const blob = new Blob([encrypted], { type: 'text/plain' });
      file = new File([blob], "file.txt", { type: 'text/plain' });
    } else {
      throw new Error('Unsupported fileData format');
    }
    const formData = new FormData();
    formData.append("ipfsFile", file);
    return signedFormDataRequest(wallet, "POST", `${config.API_IPFS_UPLOAD}`, formData);
  } catch (error) {
    console.error("Error in uploadFileToIpfs:", error.message);
    throw new Error("Failed to upload file to IPFS. Please try again.");
  }
};

export const sendInvite = (wallet, inviteData) => {
  // inviteData should include receiver, meetingDateTime, timezone, description
  return signedRequest(wallet, "POST", `${config.API_INVITES}`, inviteData);
};

export const updateInvite = (wallet, inviteId, updatedData) => {
  return signedRequest(wallet, "PUT", `${config.API_INVITES}/${inviteId}`, updatedData);
};

export const deleteInvite = (wallet, inviteId) => {
  return signedRequest(wallet, "DELETE", `${config.API_INVITES}/${inviteId}`, { inviteId });
};

export const fetchInvites = (wallet, startWeek) => {
  return signedGet(wallet, `${config.API_INVITES}`, { action: "list_invites", startWeek });
};

// New service method to fetch pending invites paginated
export const fetchPendingInvites = (wallet, page = 1, limit = 20) => {
  return signedGet(wallet, `${config.API_INVITES}/pending`, { action: "list_pending_invites", page, limit });
};

export const followUser = (wallet, followee) => {
  return signedRequest(wallet, "POST", "/follows/follow", { followee });
};

export const unfollowUser = (wallet, followee) => {
  return signedRequest(wallet, "POST", "/follows/unfollow", { followee });
};

export const getFollows = (wallet, type, page = 1, limit = 10) => {
  return signedRequest(wallet, "POST", "/follows/list", { type, page, limit });
};

export const checkFollowStatus = (wallet, followee) => {
  return signedRequest(wallet, "POST", "/follows/status", { followee });
};



export default {
  fetchAppsAdmin,
  fetchAppsDev,
  // Renaming updateApp to submitAppUpdate for developer update requests:
  submitAppUpdate,
  pauseToggleApp,
  deleteApp,
  findApp,
  getPublicApps,
  updateAppAdmin,
  deleteAppAdmin,
  submitApp,
  fetchUpdateSubmissions,
  fetchMyUpdateSubmissions,
  decisionOnUpdateSubmission,
  uploadFileToIpfs,
  sendInvite,
  updateInvite,
  deleteInvite,
  fetchInvites,
  fetchPendingInvites,
  followUser,
  unfollowUser,
  getFollows,
  checkFollowStatus,
};