import React, { useState, useEffect } from 'react';
import stas from 'stas-sdk';
import { P2PKH } from '@bsv/sdk';
import '../css/TokenMintSTAS20.css';
import bsv from 'bsv';
import { dynamicApiCall } from '../services/apiOutCalls'; // added import for dynamicApiCall
import { config } from '../config';
import { consolidate,fetch_utxos } from '../utils/consolidateBSV';
import {capitalizeFirstLetter, convertCamelToCamelCase} from '../utils/stringUtils';
import { broadcastTransactions } from '../services/apiOutCalls'; // Import the broadcast method

import { useGlobalAlert } from './GlobalAlert';

/**
 * TokenMintSTAS20 Component
 *
 * Props:
 *  - wallet: an object containing wallet data (e.g. wallet.address, wallet.privateKey in WIF)
 *  - selectedUTXOs: an array of UTXOs coming from your UTXO table.
 *      The user must select 3 UTXOs:
 *        [0]: UTXO for the contract (token amount),
 *        [1]: UTXO to cover the contract fees,
 *        [2]: UTXO for the issuance transaction.
 *  - onTransactionComplete: callback invoked when minting succeeds.
 *  - onMintError: callback invoked when minting fails.
 *
 * This component uses the two-step process (contract then issuance) to mint STAS‑20 tokens.
 */
const TokenMintStas20 = ({
  wallet,
  onTransactionComplete,
  onMintError = (error) => { console.error(error); }
}) => {

  const { showAlert } = useGlobalAlert();
  
  // Form fields.
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [totalSupply, setTotalSupply] = useState('');
  const [satsPerToken, setSatsPerToken] = useState(1);
  const [decimals, setDecimals] = useState(0);
  const [imageBase64, setImageBase64] = useState('');
  const [recipient, setRecipient] = useState(wallet.address);
  const [txStatus, setTxStatus] = useState('');
  const [error, setError] = useState('');
  

    // NEW: Dynamic parameters
    const [tokenSchema, setTokenSchema] = useState({});
    const [feeContract, setFeeContract] = useState(null);
    const [feeIssuance, setFeeIssuance] = useState(null);
    const [minContract, setMinContract] = useState(null);
    const [totalFunds, setTotalFunds] = useState(null);

    
  // Dynamic properties state.
  const [dynamicProperties, setDynamicProperties] = useState({
    legal: {},
    issuer: {},
    meta: { schemaId: "STAS1.0" }
  });
  const [selectedProperty, setSelectedProperty] = useState("terms");
  const propertyMapping = {
    terms: "legal",
    licence: "legal",
    organisation: "issuer",
    governingLaw: "issuer",
    issuerCountry: "issuer",
    jurisdiction: "issuer",
    email: "issuer",
    website: "issuer"
  };

  // Reset state function.
  const resetState = () => {
    setName('');
    setSymbol('');
    setTotalSupply('');
    setSatsPerToken(1);
    setDecimals(0);
    setImageBase64('');
    setRecipient(wallet.address);
    setTxStatus('');
    setError('');
    setTokenSchema({});
    setFeeContract(null);
    setFeeIssuance(null);
    setMinContract(null);
    setTotalFunds(null);
    setDynamicProperties({
      legal: {},
      issuer: {},
      meta: { schemaId: "STAS1.0" }
    });
    setSelectedProperty("terms");
  };
  
  useEffect(() => {
    if (wallet && wallet.address) {
      dynamicApiCall(wallet,{ action: "getWalletBalance", address: wallet.address })
        .then(result => {
          if (result.success && result.data && result.data.confirmed !== undefined) {
            setTotalFunds(result.data.confirmed);
          }
        })
        .catch(err => console.error(err));
    }
  }, [wallet]);

  const getAvailableProperties = () => {
    const available = [];
    Object.keys(propertyMapping).forEach((propName) => {
      if (propName === "schemaId" && propertyMapping[propName] === "meta") return;
      if (!(propName in dynamicProperties[propertyMapping[propName]])) {
        available.push(propName);
      }
    });
    return available;
  };

  const addProperty = () => {
    if (!selectedProperty) return;
    const section = propertyMapping[selectedProperty];
    setDynamicProperties((prevProps) => ({
      ...prevProps,
      [section]: { ...prevProps[section], [selectedProperty]: "" }
    }));
    const available = getAvailableProperties().filter((p) => p !== selectedProperty);
    setSelectedProperty(available.length ? available[0] : "");
  };

  const removeProperty = (section, key) => {
    setDynamicProperties((prevProps) => {
      const { [key]: removed, ...rest } = prevProps[section];
      return { ...prevProps, [section]: rest };
    });
  };

  const updatePropertyValue = (section, key, value) => {
    setDynamicProperties((prevProps) => ({
      ...prevProps,
      [section]: { ...prevProps[section], [key]: value }
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageBase64(reader.result);
    };
    reader.readAsDataURL(file);
  };



    useEffect(() => {
      async function updateDynamicParams() {

        const totalSupplyInt = parseInt(totalSupply, 10);
        const satsPerTokenInt = parseInt(satsPerToken, 10);
        const decimalsInt = parseInt(decimals, 10);
        // Calculate token satoshis based on total supply, sats per token and decimals.
        const tokenSatoshis = totalSupplyInt * satsPerTokenInt * (decimalsInt > 0 ? decimalsInt*10 : 1);


        const newTokenSchema = {
          name,
          protocolId: 'STAS-20',
          symbol,
          totalSupply: totalSupplyInt,
          satsPerToken: satsPerTokenInt,
          properties: dynamicProperties,
        };

        if (imageBase64) {
          tokenSchema.image = imageBase64;
        }
        if (decimals) {
          tokenSchema.decimals = decimalsInt;
        }

        setTokenSchema(newTokenSchema);
  
        // If we have 3 UTXOs, update fees and symbol dynamically.
        try {
          const feeC = await stas.stasContract.feeEstimate(newTokenSchema, tokenSatoshis);
          const dummyTxid = "0000000000000000000000000000000000000000000000000000000000000000";
          const tokenSchemaHex = Buffer.from(JSON.stringify(newTokenSchema), 'utf8').toString('hex');
          const feeI = await stas.stasIssuance.feeEstimate(
            [{
              addr: wallet.address,
              satoshis: tokenSatoshis,
              data: []
            }],
            { satoshis: 1, vout: 0, txid: dummyTxid, script: tokenSchemaHex },
            false,
            newTokenSchema.symbol,
            "STAS-20"
          );
          setFeeContract(feeC);
          setFeeIssuance(feeI);
          setMinContract(tokenSatoshis);
        } catch (err) {
          console.error("Dynamic fee estimation error:", err);
        }
      }
      updateDynamicParams();
    }, [name, totalSupply, satsPerToken, decimals, imageBase64, dynamicProperties, wallet, symbol]);
  
    useEffect(() => {
      if (feeContract != null && feeIssuance != null && minContract != null && totalFunds!= null) {
  
        const totalRequired = feeContract + feeIssuance + minContract;
        if (totalRequired > totalFunds) {
          setError(`Not enough balance for this post. Required: ${totalRequired} sats, but you have only ${totalFunds} sats.`);
        } else {
          // Optionally clear error if it previously indicated balance issues.
          if (error && error.includes("Not enough balance")) {
            setError("");
          }
        }
      }
    }, [feeContract, feeIssuance, minContract, totalFunds]);
  
  

  const handleMint = async (e) => {
    e.preventDefault();
    try {
      setTxStatus("Preparing minting process...");
     
      // Create the required utxo values array.
      const requiredUTxO = [
        { role: 'contractUtxo', satoshis: minContract },
        { role: 'contractFeeUtxo', satoshis: feeContract },
        { role: 'issuanceUtxo', satoshis: feeIssuance }
      ];

      const selectedUTXOList = await fetch_utxos(wallet, requiredUTxO,config);
      if (selectedUTXOList.failed) {
        setTxStatus("Error: Unable to obtain enough satoshis");
      }

      // Prepare issueData.
      const issueData = [
        {
          addr: recipient,
          satoshis: minContract,
          data: []
        }
      ];
    
     const {consolidationTx, consolidatedUTxO} = await consolidate(wallet, selectedUTXOList, requiredUTxO,config);

      const contractUtxo = consolidatedUTxO[0];
      const contractFeeUtxo = consolidatedUTxO[1];
      const issuanceUtxo = consolidatedUTxO[2];

      const issuerPk = bsv.PrivateKey.fromWIF(wallet.privateKey);

      setTxStatus("Creating token contract transaction...");
      const contractTx = await stas.stasContract.signed(
        issuerPk,
        contractUtxo,
        contractFeeUtxo,
        issuerPk,
        tokenSchema,
        minContract,
        true
      );

      setTxStatus("Contract transaction created. Extracting contract UTXO...");
      const extractedContractUtxo = stas.utility.getUtxoFromTx(contractTx, 0);
      if (!extractedContractUtxo) {
        throw new Error("Failed to extract contract UTXO from contract transaction.");
      }

      setTxStatus("Creating issuance transaction...");
      const issuanceTx = await stas.stasIssuance.signed(
        issuerPk,
        issueData,
        extractedContractUtxo,
        issuanceUtxo,
        issuerPk,
        true,
        tokenSchema.symbol,
        "STAS-20",
        true
      );

      setTxStatus("Broadcasting transactions...");
      // Broadcast both transactions using the broadcastTransactions method.
      const txHexes = [consolidationTx.toHex(), contractTx.toString(), issuanceTx.toString()];
      const broadcastResults = await broadcastTransactions(wallet, txHexes);      
      let outcome;
      if (Array.isArray(broadcastResults) && broadcastResults.length > 0) {
        // Check if any broadcast failed.
        const failedResult = broadcastResults.find(result => result.error && result.error.code !== 0);
        if (failedResult) {
          outcome = { message: failedResult.error.message };
          showAlert(outcome.message, "danger", "Mint Error", false);

        } else {
          outcome = { txid: broadcastResults.map(result => result.txid).join(', ') };
          showAlert("Here are your Transaction IDs onchain for the Token you just minted: "+outcome.txid, "success", "Mint Done", false);
          resetState(); 
        }
      }
      setTxStatus("Broadcast complete.");
      onTransactionComplete(outcome);
    } catch (err) {
      console.error("Error minting STAS‑20 token:", err);
      setTxStatus("Error: " + err.message);
      onMintError(err);
    }
  };

  return (
    <div className="">
      <form onSubmit={handleMint}>
        <div className="form-group">
          <label>
            Total Supply:
            <input
              type="number"
              value={totalSupply}
              onChange={(e) => setTotalSupply(e.target.value)}
              required
              min={0}
            />
          </label>
          <small>Enter total supply of tokens.</small>
        </div>
{/* 
        <div className="form-group">
          <label>
            Decimals{' '}
          </label>
          <input
            type="number"
            value={decimals}
            onChange={(e) => setDecimals(e.target.value)}
            placeholder="Decimals"
            min="0"
            max="4"
          />
          <small>Enter a number between 0 and 4.</small>
        </div> */}

        <div className="form-group">
          <label>
            Satoshis Per Token{' '}
          </label>
          <input
            type="number"
            value={satsPerToken}
            onChange={(e) => setSatsPerToken(e.target.value)}
            placeholder="Satoshis Per Token"
            required
            min={1}
          />
          <small>Enter the number of satoshis per token.</small>
        </div>

        <div className="form-group">
          <label>
            Recipient{' '}
          </label>
          <input
            type="text"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            placeholder="Recipient Address"
            required
          />
          <small>Enter a valid recipient address.</small>
        </div>

        <div className="form-group">
          <label>
            Name{' '}
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Token Name"
            maxLength="30"
            required
          />
          <small>Name must be 30 characters or less.</small>
        </div>

        <div className="form-group">
          <label>
            Symbol{' '}
          </label>
          <input
            type="text"
            value={symbol}
            onChange={(e) => setSymbol(String(e.target.value).trim().toUpperCase())} 
            placeholder="Token Symbol"
            pattern="^[A-Za-z0-9]+$"
            title="Only letters and numbers without spaces or special characters"
            required
          />
          <small>Enter a symbol using only letters and numbers (no spaces or special characters).</small>
        </div>
        
        <div className="form-group">
          <label htmlFor="propertySelect">Add Property:</label>
          <div className="d-flex">
            <select
              id="propertySelect"
              className="form-control mr-2"
              value={selectedProperty}
              onChange={(e) => setSelectedProperty(e.target.value)}
            >
              {getAvailableProperties().map((propName) => (
                <option key={propName} value={propName}>
                  {convertCamelToCamelCase(propName)}
                </option>
              ))}
            </select>
            <button type="button" className="btn btn-primary" onClick={addProperty}>
              Add
            </button>
          </div>
          <small>Select a property to add extra token information.</small>
        </div>
        
        {Object.keys(dynamicProperties).map((section) =>
          Object.entries(dynamicProperties[section]).map(([key, value]) => {
            if (section === 'meta' && key === 'schemaId') return null;
            return (
              <div key={section + key} className="form-group">
                <label>
                  {capitalizeFirstLetter(key)} ({capitalizeFirstLetter(section)})
                </label>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control mr-2"
                    value={value}
                    onChange={(e) => updatePropertyValue(section, key, e.target.value)}
                  />
                  <button type="button" className="btn btn-danger" onClick={() => removeProperty(section, key)}>
                    X
                  </button>
                </div>
              </div>
            );
          })
        )}
        <div className="form-group d-flex justify-content-between mb-3">
          
          <button type="submit" className="btn btn-outline-dark">
            Mint New Token
          </button>
        </div>
      </form>
      {txStatus && <div className="alert">{txStatus}</div>}
      {error && <div className="error">{error}</div>}
     
    </div>
  );
};

export default TokenMintStas20;
