import React, { useState, useEffect, useRef, useLayoutEffect, useContext, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { initGlobalSignaler } from '../services/globalSignaler';
import OverlayConfirm from '../components/OverlayConfirm';
import DOMPurify from 'dompurify';
import CryptoJS from 'crypto-js'; // NEW import for encryption
import { AuthContext } from '../context/AuthProvider';
import { useGlobalAlert } from '../components/GlobalAlert';
import { sendInvite } from '../services/devApiService';
import moment from 'moment';
import {getAddressFromPKHash, getPKHashFromAddress} from '../utils/hash160'; // NEW
import '../css/Conversation.css';

const Conversation = () => {
  const { address } = useParams();
  const { wallet } = useContext(AuthContext);
  const myAddress = wallet ? wallet.address : null;
  
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);
  const prevMessagesRef = useRef([]); // new ref to store previous messages
  const [autoScroll, setAutoScroll] = useState(true);
  
  const globalSignaler = initGlobalSignaler(wallet);
  const navigate = useNavigate();

  // Track if the user (sender) has confirmed the P2P nature
  const [hasConfirmedP2P, setHasConfirmedP2P] = useState(false);
  const [showP2PConfirm, setShowP2PConfirm] = useState(false);

  const { showAlert } = useGlobalAlert();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteMeetingDateTime, setInviteMeetingDateTime] = useState('');
  const [inviteTimezone, setInviteTimezone] = useState(moment().utcOffset()/60);
  const [inviteDescription, setInviteDescription] = useState('');
  const [inviteReceiver, setInviteReceiver] = useState(''); // new state for friend's address
  // New: compute unique timezones for dropdown
  const uniqueTimezones = useMemo(() => {
    const offsets = new Set();
    const tzNames = moment.tz.names();
    tzNames.forEach(tz => {
      offsets.add(moment.tz(tz).utcOffset()/60);
    });
    const sortedOffsets = Array.from(offsets).sort((a,b) => a-b);
    return sortedOffsets.map(offset => {
      const label = `GMT${offset >= 0 ? '+' : ''}${offset}`;
      return { value: offset, label };
    });
  }, []);

  const [showClearChatConfirm, setShowClearChatConfirm] = useState(false);
  const [showClearRequestConfirm, setShowClearRequestConfirm] = useState(false);
  const [clearRequestFrom, setClearRequestFrom] = useState(null);

  // Function to update the active conversation from localStorage.
  const updateActiveConversation = () => {
    const conv = JSON.parse(localStorage.getItem(`conv_${address}`)) || [];
    setMessages(conv);
  };

  // Poll localStorage for updates on the active conversation.
  useEffect(() => {
    updateActiveConversation();
    const interval = setInterval(() => {
      updateActiveConversation();
    }, 1000);
    return () => clearInterval(interval);
  }, [address]);
  
  
  // Auto-scroll effect: scroll only if messages have truly changed.
  useLayoutEffect(() => {
    // Only auto-scroll if enabled
    if (
      autoScroll
    ) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, autoScroll]);
  
  // When active, mark conversation as read.
  useEffect(() => {
    globalSignaler.markConversationAsRead(address);
  }, [address, globalSignaler]);

  // clearChat with confirmation.
  const clearChat = () => {
    setShowClearChatConfirm(true);
  };
  
  // sendMessage always sends via globalSignaler.
  const sendMessage = (customInput = null) => {
    // If both input is empty and no custom input is provided, do nothing.
    if (input.trim() === '' && !customInput) return;
    if (!myAddress || !address) {
      showAlert("Please log in to send messages.", "danger");
      return;
    }
    // FIX: Use customInput if provided; otherwise, use input.
    if (!customInput) {
      customInput = input;
    }
    const msg = {
      id: new Date().getTime(),
      from: myAddress,
      to: address,
      content: customInput,
      timestamp: new Date().toISOString(),
      status: 'pending'
    };
    globalSignaler.storeMessage(address, msg);
    globalSignaler.sendMessage(address, 'conversation', { message: msg });
    setInput('');
  };

  const handleSendMessage = () => {
    sendMessage();
    
    // Only trigger confirmation if user hasn't confirmed P2P on their first message
    // if (!hasConfirmedP2P) {
    //   setShowP2PConfirm(true);
    // } else {
    //   sendMessage();
    // }
  };

  const handleP2PConfirmComplete = (confirmed) => {
    setShowP2PConfirm(false);
    if (confirmed) {
      setHasConfirmedP2P(true);
      // Proceed with sending the first message after confirmation
      sendMessage();
    }
  };


  // Updated: Export current conversation backup consistently with import.
  const handleExportConversation = () => {
    const convKey = `conv_${address}`;
    const convString = localStorage.getItem(convKey);
    if (!convString) {
      alert("No conversation history found to export.");
      return;
    }
    // Wrap conversation in an object for consistency.
    const backupObj = { [convKey]: convString };
    // Encrypt backup using wallet's privateKey
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(backupObj), wallet.privateKey).toString();
    // Format timestamp for filename (replace colons with dashes)
    const timestamp = new Date().toISOString().replace(/:/g, '-');
    const fileName = `${timestamp}_${address}_chat.txt`;
    const blob = new Blob([encrypted], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    
    // Fallback for mobile: open in new tab if needed
    setTimeout(() => {
      if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
        window.open(url, '_blank');
      }
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 500);
  };

  // When opening the invite modal, prefill the friend's address from the chat's address.
  const openInviteModal = () => {
    setInviteReceiver(address);
    setShowInviteModal(true);
  };

  const handleScheduleInvite = async () => {
    if (!inviteMeetingDateTime) {
      showAlert("Please select date and time.", "danger");
      return;
    }
    if (moment(inviteMeetingDateTime).isBefore(moment())) {
      showAlert("Meeting must be in the future.", "danger");
      return;
    }
    // Prepare composite time (e.g., "2025-03-26T22:00:00-03:00")
    const hours = Math.floor(Math.abs(inviteTimezone));
    const minutes = Math.round((Math.abs(inviteTimezone) - hours) * 60);
    const sign = inviteTimezone >= 0 ? '+' : '-';
    const tzHours = String(hours).padStart(2, '0');
    const tzMins = String(minutes).padStart(2, '0');
    const timezoneStr = `${sign}${tzHours}:${tzMins}`;
    const compositeTime = inviteMeetingDateTime + ":00" + timezoneStr;
    try {
      const res = await sendInvite(wallet, {
        receiver: getPKHashFromAddress(inviteReceiver), // use inviteReceiver instead of address
        meetingDateTime: compositeTime,
        description: inviteDescription
      });
      if (!res.success) { // NEW check response
        showAlert(res.error || "Failed to send invite", "danger");
        return;
      }
      // Also send an informational message about the invite
      sendMessage("Check P2P invite: " + compositeTime);
      showAlert("Invite sent!", "success");
      setShowInviteModal(false);
    } catch (err) {
      console.error(err);
      showAlert(err.message || "Failed to send invite", "danger");
    }
  };
  
  // Listen for clearRequest events from globalSignaler.
  useEffect(() => {
    const clearHandler = (e) => {
      // Only show overlay if the clear request is from the current chat partner.
      if (e.detail && e.detail.from === address) {
        setClearRequestFrom(e.detail.from);
        setShowClearRequestConfirm(true);
      }
    };

    const clearResponseHandler = (e) => {
      // Handle the response from the other party.
      if (e.detail && e.detail.from === address) {
        if (e.detail.response === 'accepted') {
          // Clear the chat locally if the other party accepted.
          localStorage.removeItem(`conv_${address}`);
          globalSignaler.conversations[address] = [];
          setMessages([]);
          showAlert("The other party has cleared the chat.", "info");
        } else if (e.detail.response === 'rejected') {
          showAlert("The other party declined to clear the chat.", "warning");
        }
      }
    };

    globalSignaler.addEventListener('clearRequest', clearHandler);
    globalSignaler.addEventListener('clearResponse', clearResponseHandler);

    return () => {
      globalSignaler.removeEventListener('clearRequest', clearHandler);
      globalSignaler.removeEventListener('clearResponse', clearResponseHandler);
    };
  }, [address, globalSignaler]);

  return (
    <>
      <div className="floating-buttons">
        <a href="#" onClick={clearChat} className="clear-chat-link">Clear Chat</a>
        <a href="#" onClick={handleExportConversation} className="export-chat-link">Export Chat</a>
        <div className="autoscroll-toggle">
           <input 
             type="checkbox" 
             checked={autoScroll} 
             onChange={e => setAutoScroll(e.target.checked)} 
             style={{ marginRight: '0.5rem' }}
           />
           Auto Scroll
        </div>
      </div>
      <div className="m-0 p-3">
        <div className="chat-container m-0 p-0">
          <div className="chat-messages">
            {messages.length === 0 && (
              <div className="no-messages-box">
                <p>This is a peer-to-peer conversation. Your messages are sent directly when you AND the recipient are BOTH ONLINE at the same time and are not stored on our servers. Messages remain undelivered locally waiting for both to connect.</p>
                
              </div>
            )}
            {messages.map((msg, idx) => (
              <div key={idx} className={`chat-message ${msg.from === myAddress ? 'sent' : 'received'}`}>
                <div className={`chat-bubble ${msg.from === myAddress ? 'sent' : 'received'}`}>
                  {(() => {
                    const sanitized = DOMPurify.sanitize(msg.content);
                    if(sanitized.includes("Check P2P invite")) {
                      return (
                        <a 
                          onClick={() => navigate("/conversations?tab=Invites")}
                          style={{ color: "#007bff", textDecoration: "underline", cursor:"pointer" }}
                        >
                          {sanitized}
                        </a>
                      );
                    }
                    return <p>{sanitized}</p>;
                  })()}
                  <small className="chat-timestamp">
                    {new Date(msg.timestamp).toLocaleTimeString()} {msg.from === myAddress ? (msg.status === 'delivered' ? '✔✔' : '✔') : ''}
                  </small>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-controls">
            <div className="chat-input-container">
            <button 
                className="btn btn-outline-secondary me-2" 
                onClick={openInviteModal}  // use new handler
              >
               <i className="fa fa-calendar"></i> <span className="d-none d-sm-inline">Schedule</span>
              </button>
              <button 
                className="btn btn-outline-secondary me-2" 
                onClick={() => {
                  navigate('/profile/'+address);
              }} 
              >
                 <i className="fa fa-user"></i> <span className="d-none d-sm-inline">Profile</span>
              </button>
              <input 
                type="text" 
                className="chat-input" 
                value={input} 
                onChange={e => setInput(e.target.value)} 
                placeholder="Type your message..." 
              />
              <button className="btn btn-outline-secondary ms-2" onClick={handleSendMessage}><i class="fa fa-paper-plane-o" aria-hidden="true"></i> <span className="d-none d-sm-inline">Send</span></button>
            </div>
          </div>
        </div>
        {showP2PConfirm && (
          <OverlayConfirm
          confirmation={{messages: [
              "If you lose your device, you will lose the chat history. Messages are stored locally only and remain undelivered until BOTH you and the recipient are online.",
            ]}}
            optionsMsg={{ confirm: "I understand, send", cancel: "Cancel" }}
            onComplete={handleP2PConfirmComplete}
          />
        )}
        {showInviteModal && (
          <div 
            className="modal show d-block" 
            tabIndex="-1" 
            role="dialog" 
            style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Schedule P2P - Invite</h5>
                  <button 
                    type="button" 
                    className="btn-close" 
                    onClick={() => setShowInviteModal(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  {/* New: Friend's Address field */}
                  <div className="mb-3">
                    <label className="form-label">Friend's Address</label>
                    <input 
                      type="text"
                      className="form-control" 
                      value={inviteReceiver} 
                      onChange={(e) => setInviteReceiver(e.target.value)}
                      required 
                      disabled
                    />
                  </div>
                  {/* Combined Date/Time & Timezone inline */}
                  <div className="mb-3">
                  <label className="form-label">Date &amp; Time:</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input 
                      type="datetime-local"
                      className="form-control"
                      value={inviteMeetingDateTime}
                      onChange={(e) => setInviteMeetingDateTime(e.target.value)}
                      min={moment().format("YYYY-MM-DDTHH:mm")}
                      required
                    />
                    <select 
                      className="form-select" 
                      value={inviteTimezone}
                      onChange={(e) => setInviteTimezone(Number(e.target.value))}
                      style={{ marginLeft: '10px', width: 'auto' }}
                      required
                    >
                      {uniqueTimezones.map(tz => (
                        <option key={tz.label} value={tz.value}>{tz.label}</option>
                      ))}
                    </select>
                  </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description (optional):</label>
                    <textarea 
                      className="form-control" 
                      value={inviteDescription}
                      onChange={(e) => setInviteDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="modal-footer">
                  <button 
                    type="button" 
                    className="btn btn-secondary" 
                    onClick={() => setShowInviteModal(false)}
                  >
                    Cancel
                  </button>
                  <button 
                    type="button" 
                    className="btn btn-primary" 
                    onClick={handleScheduleInvite}
                  >
                    Send Invite
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showClearChatConfirm && (
          <OverlayConfirm
            confirmation={{
              messages: [
                "This will erase all chat and will send a request to remove messages from the receiver's chat if they accept. There is no backup to recover the messages after the receiver also clears them. Are you sure you want to proceed?"
              ],
              requiresInput: false
            }}
            optionsMsg={{ confirm: "Yes, clear chat", cancel: "Cancel" }}
            onComplete={(result) => {
              setShowClearChatConfirm(false);
              if (result.status) {
                localStorage.removeItem(`conv_${address}`);
                globalSignaler.conversations[address] = [];
                setMessages([]);
                globalSignaler.dispatchEvent(new CustomEvent('conversationUpdate', { detail: { target: address, messages: [] } }));
                globalSignaler.sendMessage(address, 'clear', { message: { command: 'clear' } });
              }
            }}
          />
        )}
        {showClearRequestConfirm && (
          <OverlayConfirm
            confirmation={{
              messages: [
                "The other party has requested to clear the chat. If you accept, all messages will be permanently deleted. Do you want to proceed?"
              ],
              requiresInput: false
            }}
            optionsMsg={{ confirm: "Yes, clear chat", cancel: "No, keep chat" }}
            onComplete={(result) => {
              setShowClearRequestConfirm(false);
              if (result.status) {
                // Clear the chat locally and notify the other party.
                localStorage.removeItem(`conv_${address}`);
                globalSignaler.conversations[address] = [];
                setMessages([]);
                globalSignaler.sendMessage(address, 'clearResponse', { response: 'accepted' });
              } else {
                // Notify the other party that the request was declined.
                globalSignaler.sendMessage(address, 'clearResponse', { response: 'rejected' });
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export default Conversation;
