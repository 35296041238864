import React from 'react';
import TokenMintSTAS789 from '../components/TokenMintSTAS789';

const MintSTAS789 = () => {
  // Pass necessary props (e.g., wallet, params) to TokenMintSTAS789.
  return (
    <div>
      <TokenMintSTAS789 /* wallet, params etc. */ />
    </div>
  );
};

export default MintSTAS789;