import React, { useState, useEffect } from 'react';
import { convertCamelToCamelCase } from '../utils/stringUtils';
import { dynamicApiCall } from '../services/apiOutCalls';
import '../css/PropertiesOverlay.css';

const renderTree = (data) => {
  if (typeof data !== 'object' || data === null) return <span>{String(data)}</span>;
  return (
    <ul className="properties-tree">
      {Object.entries(data).map(([key, value]) => (
        <li key={key}>
          <strong>{convertCamelToCamelCase(key)}:</strong> {typeof value === 'object' ? renderTree(value) : String(value)}
        </li>
      ))}
    </ul>
  );
};

const PropertiesOverlay = ({ post, onClose, wallet }) => {
  const [properties, setProperties] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchProperties() {
      try {
        // Replace direct fetch with dynamicApiCall using "getProperties" action.
        const result = await dynamicApiCall(wallet, { action: "getNFTDetails", redeemAddr: post.tokenId, symbol: post.symbol });
        if (result.success) {
          setProperties(result.data.properties || {});
        } else {
          setProperties({ error: 'Failed to load properties' });
          console.error("getProperties call failed", result.error);
        }
      } catch (error) {
        console.error("Error fetching properties:", error);
        setProperties({ error: 'Failed to load properties' });
      } finally {
        setLoading(false);
      }
    }
    fetchProperties();
  }, [post, wallet]);

  return (
    <div className="properties-overlay">
      <div className="overlay-content">
        <div className="overlay-header-properties">
          <h4>NFT Properties</h4>
          <button className="close-btn" onClick={onClose}>
            <i className="fa fa-times-circle"></i>
          </button>
        </div>
        <div className="properties-tree-container">
          {loading ? (
            <div className="loader text-center">
              <i className="fa fa-spinner fa-pulse"></i>
            </div>
          ) : (
            renderTree(properties)
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertiesOverlay;