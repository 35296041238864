import React, { useState, useEffect, useRef } from 'react';
import { verifiedFetch } from '@helia/verified-fetch';
import CryptoJS from 'crypto-js';

const DecryptedVideo = ({ videoUrl, password }) => {
  const [videoSrc, setVideoSrc] = useState(null);
  const [error, setError] = useState('');
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [muted, setMuted] = useState(true); // auto-start as muted
  const videoRef = useRef(null);
  
  // NEW: Additional states for progress and duration.
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // NEW: Array of speed options
  const speedOptions = [0.5, 1, 1.5, 2];
  
  useEffect(() => {
    if (!videoUrl || !password) return;
    const ipfsUrl = videoUrl.startsWith('ipfs://') ? videoUrl : `ipfs://${videoUrl}`;
    
    verifiedFetch(ipfsUrl)
      .then(async response => {
        if (!response.ok) {
          throw new Error('Failed to fetch encrypted video');
        }
        const encryptedContent = await response.text();
        const bytes = CryptoJS.AES.decrypt(encryptedContent, password);
        const decryptedDataUrl = bytes.toString(CryptoJS.enc.Utf8);
        if (!decryptedDataUrl) {
          throw new Error('Decryption failed. Check your passphrase or file data.');
        }
        setVideoSrc(decryptedDataUrl);
      })
      .catch(err => setError(err.message));
  }, [videoUrl, password]);

  // NEW: On mount, force video muted (for all devices) and set up auto-play without pausing.
  useEffect(() => {
    const vid = videoRef.current;
    if (!vid) return;
    vid.muted = true;
    setMuted(true);
    // Set up duration and time update listener.
    const handleLoadedMetadata = () => {
      setDuration(vid.duration);
    };
    const handleTimeUpdate = () => {
      setCurrentTime(vid.currentTime);
    };
    vid.addEventListener('loadedmetadata', handleLoadedMetadata);
    vid.addEventListener('timeupdate', handleTimeUpdate);

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && vid.paused) {
            vid.play().catch(() => {});
          }
        });
      },
      { threshold: 0.5 }
    );
    observer.observe(vid);
    return () => {
      observer.disconnect();
      vid.removeEventListener('loadedmetadata', handleLoadedMetadata);
      vid.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [videoSrc]);

  // NEW: Attach event listeners to update play state based on video events.
  useEffect(() => {
    const vid = videoRef.current;
    if (!vid) return;
    const handlePlay = () => setPlaying(true);
    const handlePause = () => setPlaying(false);
    vid.addEventListener('play', handlePlay);
    vid.addEventListener('pause', handlePause);
    return () => {
      vid.removeEventListener('play', handlePlay);
      vid.removeEventListener('pause', handlePause);
    };
  }, [videoSrc]);

  // NEW: Handle progress change (seeking)
  const handleProgressChange = (e) => {
    const newTime = parseFloat(e.target.value);
    setCurrentTime(newTime);
    videoRef.current.currentTime = newTime;
  };

  // NEW: Format time in mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const togglePlay = () => {
    const vid = videoRef.current;
    if (vid.paused) {
      vid.play().catch(() => {});
    } else {
      vid.pause();
    }
  };

  const handleVolumeChange = e => {
    const vol = parseFloat(e.target.value);
    setVolume(vol);
    videoRef.current.volume = vol;
  };

  // NEW: Mute toggle works on all devices.
  const toggleMute = () => {
    const vid = videoRef.current;
    vid.muted = !vid.muted;
    setMuted(vid.muted);
  };

  // NEW: Cycle through predefined speeds.
  const cycleSpeed = () => {
    const currentIndex = speedOptions.indexOf(playbackRate);
    const nextIndex = (currentIndex + 1) % speedOptions.length;
    const newSpeed = speedOptions[nextIndex];
    setPlaybackRate(newSpeed);
    videoRef.current.playbackRate = newSpeed;
  };

  // NEW: Clicking the video area un-mutes it without pausing playback.
  const handleVideoClick = () => {
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
      setMuted(false);
    }
  };

  // Updated toggleFullScreen to support iOS fullscreen
  const toggleFullScreen = () => {
    const vid = videoRef.current;
    if (!vid) return;
    if (vid.webkitEnterFullscreen) {
      // Use iOS-specific fullscreen method
      vid.webkitEnterFullscreen();
    } else {
      const vidContainer = vid.parentElement;
      if (!document.fullscreenElement) {
        vidContainer.requestFullscreen().catch(err => console.error(err));
      } else {
        document.exitFullscreen();
      }
    }
  };

  if (error) return <div>Error: {error}</div>;
  if (!videoSrc)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </div>
    );

  return (
    <div className="video-player-container" style={{ backgroundColor: '#000', maxWidth: '100%' }}>
      <video
        ref={videoRef}
        src={videoSrc}
        preload="metadata"
        playsInline
        webkit-playsinline="true"
        onClick={handleVideoClick}  // Unmute on tap
        loop                     // Enable auto loop
        style={{ width: '100%' }}
      />
      {/* Moved custom controls below the video */}
      <div
        className="custom-controls p-2 bg-light"
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '-6px'
        }}
      >
        {/* Left: Play/Pause */}
        <div className="left-controls" style={{ flex: '0 0 auto' }}>
          <button onClick={togglePlay} className="btn btn-sm btn-light">
            {playing ? <i className="fa fa-pause"></i> : <i className="fa fa-play"></i>}
          </button>
        </div>
        {/* Center: Progress Bar */}
        <div className="center-controls" style={{ flex: '1 1 auto', margin: '0 10px', display: 'flex', alignItems: 'center' }}>
          <input
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleProgressChange}
            style={{ width: '100%' }}
          />
          <span 
            className="d-none d-md-inline-block" // NEW: Hide time on small screens
            style={{ color: '#000', marginLeft: '8px', fontSize: '0.9rem', whiteSpace: 'nowrap' }}
          >
            {formatTime(currentTime)} / {formatTime(duration)}
          </span>
        </div>
        {/* Right: Volume, Speed & Fullscreen */}
        <div className="right-controls" style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center' }}>
          <button onClick={toggleMute} className="btn btn-sm btn-light" style={{ marginRight: '8px' }}>
            {muted ? <i className="fa fa-volume-mute"></i> : <i className="fa fa-volume-up"></i>}
          </button>
          <div style={{ marginRight: '8px' }}>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              style={{ width: '80px' }}
            />
          </div>
          <button onClick={cycleSpeed} className="btn btn-sm btn-light" style={{ marginRight: '8px' }}>
            <i className="fa fa-tachometer"></i> {playbackRate}x
          </button>
          <button onClick={toggleFullScreen} className="btn btn-sm btn-light">
            <i className="fa fa-arrows-alt"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DecryptedVideo;
