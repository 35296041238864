import React, { useState } from 'react';
import { createOrdinals, oneSatBroadcaster } from 'js-1sat-ord';
import { PrivateKey, P2PKH } from '@bsv/sdk';
import { Buffer } from 'buffer';
import UTXOSelectorOverlay from './UTXOSelectorOverlay';
import '../css/TokenMintOrdinals1satNFT.css';
import { useGlobalAlert } from './GlobalAlert';
import { ConfigContext } from '../config';

const TokenMintOrdinals1satNFT = ({ wallet, totalFunds, onTransactionComplete }) => {
  const config = React.useContext(ConfigContext);
  const { showAlert } = useGlobalAlert();
  
  const [mainFile, setMainFile] = useState(null);
  const [txStatus, setTxStatus] = useState('');
  const [mintedSuccess, setMintedSuccess] = useState(false);
  const [mintedDetails, setMintedDetails] = useState(null);

  // New state for UTXO selection using overlay.
  const [selectedUTXOs, setSelectedUTXOs] = useState([]);
  const [showUTXOOverlay, setShowUTXOOverlay] = useState(false);

  const handleFileChange = (e) => {
    setMainFile(e.target.files[0]);
  };

  // Reads a File object and returns a promise that resolves with its DataURL.
  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const resetForm = () => {
    setMainFile(null);
    setTxStatus('');
    setMintedSuccess(false);
    setMintedDetails(null);
    setSelectedUTXOs([]);
  };

  const handleCopyTxid = async () => {
    try {
      await navigator.clipboard.writeText(mintedDetails.txid);
      alert('Transaction ID copied to clipboard.');
    } catch (err) {
      alert('Failed to copy Transaction ID.');
    }
  };

  const handleMint = async () => {
    const p2pkh = new P2PKH();
    const lockingScriptHex = p2pkh.lock(wallet.address).toHex();
    const lockingScriptBase64 = Buffer.from(lockingScriptHex, 'hex').toString('base64');

    // Map the internally selected UTXOs to the expected format:
    const formattedUTXOs = selectedUTXOs.map(utxo => ({
      satoshis: utxo.value,
      txid: utxo.tx_hash,
      vout: utxo.tx_pos,
      script: lockingScriptBase64,
    }));

    if (!mainFile) {
      alert("Please select a file for the NFT content.");
      return;
    }
    if (formattedUTXOs.length === 0) {
      alert("Please select UTXOs for minting using the 'Select UTXOs' button.");
      return;
    }
    setTxStatus("Reading main file...");
    try {
      const mainFileDataURL = await readFileAsBase64(mainFile);
      const parts = mainFileDataURL.split(',');
      if (parts.length < 2) {
        throw new Error("Invalid DataURL: could not split into MIME and base64 data.");
      }
      const mainPrefix = parts[0];
      const mainBase64 = parts[1];
      const mainContentTypeMatch = mainPrefix.match(/data:(.*?);base64/);
      if (!mainContentTypeMatch || mainContentTypeMatch.length < 2) {
        throw new Error("Could not determine content type from DataURL.");
      }
      const mainContentType = mainContentTypeMatch[1];

      setTxStatus("Creating inscription transaction...");
      const paymentPk = PrivateKey.fromWif(wallet.privateKey);

      const ordSchema = {
        utxos: formattedUTXOs,
        destinations: [{
          address: wallet.address,
          inscription: { 
            dataB64: mainBase64, 
            contentType: mainContentType,
            metaData: {
              "app": config.ORDI_APP_NAME,
              "type": "ord",
            }
          }
        }],
        paymentPk: paymentPk,
        changeAddress: wallet.address,
        satsPerKb: config.SATS_PER_KB_FEE_ORDINALS
      };

      const result = await createOrdinals(ordSchema);
      setTxStatus("Broadcasting transaction...");
      const broadcastResult = await result.tx.broadcast(oneSatBroadcaster());
      if (broadcastResult.status === 'success') {
        setMintedDetails(broadcastResult);
        setMintedSuccess(true);
        setTxStatus(`NFT minted successfully! TXID: ${broadcastResult.txid}`);
        if (onTransactionComplete) {
          showAlert("Here are your 1Sat Ordinal Transaction ID: "+broadcastResult.txid, "success", "1Sat Ordinal", false);

          onTransactionComplete({ txid: broadcastResult.txid, message: `NFT minted successfully! Transaction ID: ${broadcastResult.txid}` });
        }
        setMainFile(null);
      } else {
        setTxStatus(`Broadcast failed: ${broadcastResult.message}`);
      }
    } catch (err) {
      console.error(err);
      setTxStatus(`Error: ${err.message}`);
    }
  };

  if (mintedSuccess) {
    return (
      <div className="token-mint-outcome card shadow-sm p-4 mt-3">
        <div className="card-header header-token">
          <h3 className="mb-3">NFT Minted Successfully!</h3>
        </div>
        <div className="card-body outcome-body">
          <p className="outcome-message"><strong>Transaction ID:</strong></p>
          <div className="signed-hex-container">
            <pre>{mintedDetails.txid}</pre>
          </div>
          <div className="outcome-buttons">
            <button className="btn btn-outline-secondary copy-btn" onClick={handleCopyTxid}>
              Copy Transaction ID
            </button>
            <button className="btn btn-primary done-btn" onClick={resetForm}>
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="token-mint-form p-2 mt-3">
      <div className="mb-3">
        <label className="form-label">
          Select Main File (Image, HTML, Document or Text)
        </label>
        <input
          type="file"
          className="form-control"
          onChange={handleFileChange}
          accept="*/*"
          reject="video/*"
        />
      </div>
      <div className="d-flex justify-content-between mb-3">
        <button
          className="btn btn-warning"
          onClick={() => setShowUTXOOverlay(true)}
        >
          {selectedUTXOs.length > 0 ? `UTXOs Selected (${selectedUTXOs.length})` : 'Select UTXOs'}
        </button>
        <button
          className="btn btn-outline-dark"
          onClick={handleMint}
          disabled={!mainFile}
        >
          Mint 1SAT Ordinal
        </button>
      </div>
      {txStatus && <div className="alert alert-info mt-3">{txStatus}</div>}
      {showUTXOOverlay && (
        <UTXOSelectorOverlay 
          wallet={wallet}
          requiredUTXOs={[100]}
          // In minting, you may use allocatedTotal or requiredUTXOs as needed.
          // For simplicity we pass allocatedTotal as undefined so that the overlay works in manual selection mode.
          onSelectionComplete={(utxos) => {
            if (utxos && utxos.length > 0) {
              setSelectedUTXOs(utxos);
            }
            setShowUTXOOverlay(false);
          }}
          onClose={() => setShowUTXOOverlay(false)}
        />
      )}
    </div>
  );
};

export default TokenMintOrdinals1satNFT;
