import { config } from '../config';
import { lookupUser } from './apiOutCalls';
import { getPKHashFromAddress } from '../utils/hash160';
import { PrivateKey, PublicKey } from '@bsv/sdk';
import elliptic from 'elliptic';  // Replace your previous elliptic import with these two lines:
const EC = elliptic.ec;  // NEW: import EC constructor correctly

class GlobalSignaler extends EventTarget {

  constructor(wallet) {
    super();
    this.wallet = wallet;
    this.myAddress = wallet.address;
    this.socket = null;
    this.pendingQueue = []; // Queue payloads when socket is not open.
    // In-memory store: { target: [msg, ...] }
    this.conversations = {};
    // New: maintain a set of unread conversation IDs.
    this.unreadConvs = new Set();
    // NEW: approvedConnections: { address: { active: boolean, lastConfirmation: number } }
    this.approvedConnections = {};
    // Load existing conversations from localStorage:
    Object.keys(localStorage).forEach(key => {
      if(key.startsWith('conv_')) {
        const target = key.slice(5);
        this.conversations[target] = JSON.parse(localStorage.getItem(key)) || [];
      }
    });
    // NEW: Retrieve approvedConnections from localStorage if available.
    const storedApprovals = localStorage.getItem('approvedConnections');
    if (storedApprovals) {
      this.approvedConnections = JSON.parse(storedApprovals);
    }
    this.connect(); // Initialize signaling connection.
  }
  
  // NEW: Helper to persist approvals.
  persistApprovals() {
    localStorage.setItem('approvedConnections', JSON.stringify(this.approvedConnections));
  }
  
  // NEW helper: check if an address is approved and active.
  isApproved(address) {
    return this.approvedConnections[address] && this.approvedConnections[address].active;
  }
  
  // NEW helper: get approval info for an address.
  getApprovalInfo(address) {
    return this.approvedConnections[address] || null;
  }
  
  // NEW: set approval status to active and update lastConfirmation timestamp.
  approveConnection(address) {
    this.approvedConnections[address] = { active: true, lastConfirmation: Date.now() };
    this.persistApprovals();
    this.dispatchEvent(new CustomEvent('approvalUpdate', { detail: { approved: this.approvedConnections } }));
  }
  
  // NEW: update approval status.
  updateApprovalStatus(address, active) {
    if (this.approvedConnections[address]) {
      this.approvedConnections[address].active = active;
      this.approvedConnections[address].lastConfirmation = Date.now();
    } else if (active) {
      this.approvedConnections[address] = { active: true, lastConfirmation: Date.now() };
    }
    this.persistApprovals();
    this.dispatchEvent(new CustomEvent('approvalUpdate', { detail: { approved: this.approvedConnections } }));
  }
  
  // NEW: remove approval entirely.
  removeApproval(address) {
    delete this.approvedConnections[address];
    this.persistApprovals();
    this.dispatchEvent(new CustomEvent('approvalUpdate', { detail: { approved: this.approvedConnections } }));
  }
  // NEW: remove approval entirely.
  removeAllApprovals() {
    this.approvedConnections = {};
    this.persistApprovals();
    this.dispatchEvent(new CustomEvent('approvalUpdate', { detail: { approved: this.approvedConnections } }));
  }

  connect() {
    this.socket = new WebSocket(`${config.SIGNALING_URL}?address=${this.myAddress}`);
    this.socket.onopen = () => {
      // Connection has opened; dispatch open event and send queued messages.
      this.dispatchEvent(new Event('open'));
      this.pendingQueue.forEach(payload => this.send(payload));
      this.pendingQueue = [];
      if (!this.reattemptInterval) {
        // Start periodic reattempt of pending messages (every 10 seconds).
        this.reattemptInterval = setInterval(() => this.reattemptPendingMessages(), 10000);
      }
    };
    this.socket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.type === 'bulkStatus' && data.statuses) {
        this.dispatchEvent(new CustomEvent('bulkStatus', { detail: data.statuses }));
      } 
      // Modified clear branch: dispatch "clearRequest" event instead of auto clearing.
      else if (data.payload && data.payload.type === 'clear') {
        const sender = data.from;
        // Dispatch event so receiver can confirm before clearing.
        this.dispatchEvent(new CustomEvent('clearRequest', { detail: { from: sender, message: data.payload.message } }));
      }
      // NEW: Handle clearResponse messages.
      else if (data.payload && data.payload.type === 'clearResponse') {
        const sender = data.from;
        this.dispatchEvent(new CustomEvent('clearResponse', { detail: { from: sender, response: data.payload.response } }));
      }
      else if (data.payload && (data.payload.type === 'conversation' || data.payload.type === 'ack')) {
        // NEW: For incoming conversation messages, verify the signature before further processing.
        if (data.payload.type === 'conversation' && data.payload.message) {
          if (!this.verifyPayload(data.payload.message)) {
            // If verification fails, do not process further.
            return;
          }
        }
        const unified = {
          from: data.from,
          type: data.payload.type,
          message: data.payload.message || null,
          hash: data.payload.hash || null,
          ackTarget: data.payload.ackTarget || null, // new field for acks
          to: data.payload.to || null
        };
        // NEW: For incoming conversation messages, check approval.
        if (unified.type === 'conversation' && unified.message) {
          const info = this.getApprovalInfo(data.from);
          const eightHours = 8 * 60 * 60 * 1000;
          if (!info || !info.active) {
            // If a previous confirmation was made less than 8 hours ago, then ignore.
            if (info && Date.now() - info.lastConfirmation < eightHours) {
              return;
            }
            // Otherwise, update lastConfirmation and prompt confirmation.
            if (!info) {
              this.approvedConnections[data.from] = { active: false, lastConfirmation: Date.now() };
            } else {
              info.lastConfirmation = Date.now();
            }
            
            let username = null;

            try {
              lookupUser(this.wallet, { pubkeyhash: getPKHashFromAddress(data.from) })
                .then((res) => {
                  if (res && res.user && res.user.username) {
                    username = res.user.username;
                  }
                  // Dispatch a P2P connection request with lookup result.
                  this.dispatchEvent(new CustomEvent('p2pConnectionRequest', { detail: { from: data.from, username } }));
                });
            } catch (err) {
              this.dispatchEvent(new CustomEvent('p2pConnectionRequest', { detail: { from: data.from, username } }));
            }
            return;
          }
        }
        // Process incoming ack:
        if(unified.type === 'ack' && unified.hash && unified.ackTarget) {
          this.updateMessageStatus(unified.ackTarget, unified.hash, 'delivered');
        } else if(unified.type === 'conversation' && unified.message && unified.message.hash) {
          // On receiver side, sign and send ack message
          this.sendMessage(unified.from, 'ack', { hash: unified.message.hash, ackTarget: this.myAddress });
          this.storeMessage(unified.from, unified.message);
        }
        this.dispatchEvent(new CustomEvent('messagesUpdate', { detail: unified }));
      }
    };
    this.socket.onerror = (err) => {
      // Dispatch error event; removed console.debug statements.
      this.dispatchEvent(new CustomEvent('error', { detail: err }));
    };
    this.socket.onclose = () => {
      this.dispatchEvent(new Event('close'));
      // Reconnect after 3 seconds.
      setTimeout(() => this.connect(), 3000);
    };
  }
  
  send(payload) {
    if(this.socket && this.socket.readyState === WebSocket.OPEN){
      this.socket.send(JSON.stringify(payload));
    } else {
      this.pendingQueue.push(payload);
    }
  }
  
  sendMessage(target, messageType, messagePayload) {
    // NEW: If messageType is 'clear', immediately clear conversation state
    if (messageType === 'clear') {
      if (this.conversations[target]) {
        this.conversations[target] = [];
        localStorage.removeItem(`conv_${target}`);
        this.dispatchEvent(new CustomEvent('conversationUpdate', { detail: { target, messages: [] } }));
      }
    }
    // For conversation messages, sign before sending.
    if (messageType === 'conversation' && messagePayload.message && messagePayload.message.from === this.myAddress) {
      if (!this.isApproved(target)) {
        this.approveConnection(target);
      }
      let msg = messagePayload.message;
      if(!msg.timestamp) { msg.timestamp = new Date().toISOString(); }
      if(!msg.hash) { msg.hash = this.computeHash(msg); }
      if(!msg.status) { msg.status = 'pending'; }
      // SIGN outgoing message using HMAC
      msg.signature = this.signPayload(msg);
      messagePayload.message = msg;
      // Store message locally.
      this.storeMessage(target, msg);
    }
    // ...existing send call...
    this.send({
      target,
      payload: { type: messageType, ...messagePayload }
    });
  }
  
  bulkPing(addresses) {
    this.send({ type: 'bulkPing', addresses });
  }
  
  // Store a new message in conversations & sync with localStorage.
  storeMessage(target, msg) {
    if(!msg.timestamp) { msg.timestamp = new Date().toISOString(); }
    if(!msg.hash) { msg.hash = this.computeHash(msg); }
    // If conversation exists, check for duplicate by hash.
    if(this.conversations[target]) {
      const idx = this.conversations[target].findIndex(m => m.hash === msg.hash);
      if(idx > -1) {
        // For incoming messages, update receiverTimestamp.
        if(msg.from !== this.myAddress) {
          this.conversations[target][idx].receiverTimestamp = new Date().toISOString();
        }
        // Merge any new properties.
        this.conversations[target][idx] = { ...this.conversations[target][idx], ...msg };
        // Ensure chronological order.
        this.conversations[target].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        this.saveConversation(target);
        return;
      }
    } else {
      this.conversations[target] = [];
    }
    this.conversations[target].push(msg);
    this.conversations[target].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    // For incoming messages, mark conversation as unread.
    if (msg.from !== this.myAddress) {
      this.unreadConvs.add(target);
      this.dispatchEvent(new CustomEvent('unreadUpdate', { detail: Array.from(this.unreadConvs) }));
    }
    this.saveConversation(target);
  }
  
  // New helper: Compute hash from timestamp, message content and sender.
  computeHash(msg) {
    const input = `${msg.timestamp}${msg.message}${msg.from}`;
    let hash = 5381;
    for (let i = 0; i < input.length; i++) {
      hash = ((hash << 5) + hash) + input.charCodeAt(i);
    }
    return hash >>> 0;
  }
  
  // Update status of a message in a conversation.
  updateMessageStatus(target, messageHash, status) {
    if(!this.conversations[target]) return;
    this.conversations[target] = this.conversations[target].map(msg => 
      msg.hash === messageHash ? { ...msg, status, deliveryTimestamp: new Date().toISOString() } : msg
    );
    this.saveConversation(target);
  }
  
  // Add method to mark an entire conversation as read.
  markConversationAsRead(target) {
    if (!this.conversations[target]) return;
    this.conversations[target] = this.conversations[target].map(msg => ({ ...msg, read: true }));
    this.saveConversation(target);
    this.unreadConvs.delete(target);
    this.dispatchEvent(new CustomEvent('unreadUpdate', { detail: Array.from(this.unreadConvs) }));
  }

  // Save a conversation array into localStorage.
  saveConversation(target) {
    localStorage.setItem(`conv_${target}`, JSON.stringify(this.conversations[target]));
    // Dispatch update so any listening page can refresh.
    this.dispatchEvent(new CustomEvent('conversationUpdate', { detail: { target, messages: this.conversations[target] } }));
  }
  
  // Get conversation for given target.
  getConversation(target) {
    return this.conversations[target] || [];
  }
  
  // Reattempt pending messages regardless of active conversation.
  reattemptPendingMessages() {
    Object.keys(this.conversations).forEach(target => {
      this.conversations[target].forEach(msg => {
        if(msg.from === this.myAddress && msg.status === 'pending' &&
           (Date.now() - new Date(msg.timestamp).getTime() > 10000)) {
          // NEW: Update timestamp and re-sign the message
          msg.timestamp = new Date().toISOString();
          msg.signature = this.signPayload(msg);
          msg.hash = this.computeHash(msg);
          this.sendMessage(target, 'conversation', { message: msg });
        }
      });
    });
  }

  // New method: Remove all chats on logout.
  removeAllChats() {
    Object.keys(this.conversations).forEach(target => {
      // Remove chat history from localStorage.
      localStorage.removeItem(`conv_${target}`);
      // Clear in-memory conversation.
      this.conversations[target] = [];
      // Dispatch update so any listening component can refresh.
      this.dispatchEvent(new CustomEvent('conversationUpdate', { detail: { target, messages: [] } }));
    });

    if (this.socket) {
      this.socket.close(); // Close connection on logout
      this.socket = null;
    }
  }

  // Updated signPayload: Generate an ECDSA signature using the elliptic library.
  signPayload(payload) {
    // Create a new EC instance with secp256k1 curve.
    const ECInstance = new EC('secp256k1');
    const currentTimestamp = new Date().toISOString();
    payload.timestamp = currentTimestamp;            // Set timestamp in payload.
    payload.publicKey = this.wallet.pubKeyString;        // Attach public key for later verification.
    const signableString = payload.content + currentTimestamp;
    // Generate signature using the private key (converted from WIF).
    const key = ECInstance.keyFromPrivate(PrivateKey.fromWif(this.wallet.privateKey));
    const signature = key.sign(signableString);
    const derSign = signature.toDER('hex');              // Convert signature to hexadecimal DER format.
    return derSign;
  }

  // Updated verifyPayload: Verify the signature using the provided public key.
  verifyPayload(payload) {
    if (!payload.timestamp) {
      // If timestamp is missing, verification fails.
      return false;
    }
    const signableString = payload.content + payload.timestamp;
    if (!payload.signature) {
      // If signature is missing, verification fails.
      return false;
    }
    try {
      // Create a new EC instance for verification.
      const ECInstance = new EC('secp256k1');
      // Retrieve the public key from payload.
      const pubKey = ECInstance.keyFromPublic(payload.publicKey, 'hex');
      
      const validAddress = PublicKey.fromString(payload.publicKey).toAddress().toString() == payload.from;
      
      if (!validAddress){
        return false;
      }

      // Validate the signature against the signable string.
      const valid = pubKey.verify(signableString, payload.signature);
      if (!valid) {
        return false;
      }
      // Check that the payload timestamp is within the allowed time margin (5 minutes).
      const payloadTime = new Date(payload.timestamp);
      const now = new Date();
      const diff = Math.abs(now - payloadTime);
      const allowedMargin = 5 * 60 * 1000;
      if (diff > allowedMargin) {
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  }
}

let globalSignalerInstance = null;
export function initGlobalSignaler(myAddress) {
  if(!globalSignalerInstance) {
    globalSignalerInstance = new GlobalSignaler(myAddress);
  }
  return globalSignalerInstance;
}
export default globalSignalerInstance;
