import React, { useState, useEffect } from 'react';
import '../css/LandingPage.css';
import { NavLink, useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();
  const phrases = [
    "Own your Creativity.",
    "Own your Identity.",
    "Personal Sovereignty.",
    "Express Freely.",
    "Empower your Community.",
    "Monetize your Data."
  ];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPhraseIndex(prev => (prev + 1) % phrases.length);
    }, 2000);
    return () => clearInterval(intervalId);
  }, [phrases.length]);

  return (
    <div className="landing-container">
      <img src="/herocover.jpg" alt="Hero Image" className="hero-image" style={{ width: '100%' }} />
      <header className="landing-header">
        <h1>Internet 3.0</h1>
        <p>{phrases[currentPhraseIndex]}</p>
        <button className="cta-button" onClick={() => navigate('/auth')}>Become Metanet Ninja <i className='fa fa-user-ninja'></i></button>
      </header>
      <section className="landing-hero">
        <h2>Own • Earn • Control</h2>
        <p>A platform where you can feel in true community.</p>
      </section>
      <section className="landing-features">
        <div className="feature">
          <h2>Own Your Identity</h2>
          <p>Create and manage your content with absolute ownership.</p>
        </div>
        <div className="feature">
          <h2>Real-Time Messaging</h2>
          <p>Connect instantly with peers in a fully P2P environment.</p>
        </div>
        <div className="feature">
          <h2>Instant Monetization</h2>
          <p>Transform engagement into earnings seamlessly.</p>
        </div>
      </section>
        
      <footer className="landing-footer">
        <p>© {new Date().getFullYear()}. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
