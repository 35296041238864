import React from 'react';
import '../css/TransactionOutcome.css';

const TransactionOutcome = ({ outcome, onCopyOutcome, onResetOutcome }) => {
  return (
    <div className="">
     
      <div className="card-body outcome-body">
        {outcome.issuanceId && outcome.contractId ? (
          <>
            <p className="outcome-message">Contract Signed Transaction</p>
            <div className="signed-hex-container">
              <pre>{outcome.contractId}</pre>
            </div>
            <p className="outcome-message">Issuance Signed Transaction</p>
            <div className="signed-hex-container">
              <pre>{outcome.issuanceId}</pre>
            </div>
          </>
        ) : null}

        {outcome && (outcome.txid || outcome.signedHex) ? (
          <>
            <p className="outcome-message">
              {outcome.txid ? "Transaction ID:" : "Signed Transaction:"}
            </p>
            <div className="signed-hex-container">
              <pre>{outcome.txid ? outcome.txid : outcome.signedHex}</pre>
            </div>
            <div className="outcome-buttons">
              <button
                className="btn btn-outline-secondary copy-btn"
                onClick={onCopyOutcome}
              >
                {outcome.txid ? "Copy Transaction ID" : "Copy Signed Txn"}
              </button>
              <button
                className="btn btn-primary done-btn"
                onClick={onResetOutcome}
              >
                Done
              </button>
            </div>
          </>
        ) : (
          <p className="outcome-message">
            {outcome ? outcome.message : 'Transaction completed.'}
          </p>
        )}
      </div>
    </div>
  );
};

export default TransactionOutcome;