import React from 'react';
import { NavLink } from 'react-router-dom';
import { ConfigContext } from '../config';
import '../css/Developers.css';

const Developers = () => {
  const config = React.useContext(ConfigContext);
  return (
    <div className="p-3">
      {/* Moved header outside the container */}
      <header id="intro"  className="stunning-header p-1">
        <nav className="top-menu">
          <NavLink to="/apps" className="menu-item">Apps</NavLink>
          <NavLink to="/dev-apps/submit" className="menu-item">Submit</NavLink>
          <NavLink to="/dev-apps/manage" className="menu-item">Manage</NavLink>
          <NavLink to="/admin-apps" className="menu-item">Admin</NavLink>
        </nav>
      </header>
      <div className="stunning-developers-page">
        {/* Sidebar Navigation */}
        <aside className="stunning-sidebar">
          <nav>
            <ul>
              <li><a href="#intro">Introduction</a></li>
              <li><a href="#sdk-overview">Developer SDK Overview</a></li>
              <li><a href="#detailed-usage">Detailed Messaging Flow</a></li>
              {/* NEW: Sidebar menu item for Commands Overview */}
              <li><a href="#commands-overview">Commands & Payloads</a></li>
              <li><a href="#stas-789">STAS-789 Posts</a></li>  {/* NEW: Sidebar menu entry */}
              <li><a href="#use-cases">Use Cases & Examples</a></li>
              <li><a href="#response-codes">Response Codes</a></li>
              <li><a href="#notes">Important Notes</a></li>
            </ul>
          </nav>
        </aside>
        {/* Main Content */}
        <main className="stunning-content">
          <section className="section-box">
            <h1 className="section-header">Developer SDK Documentation</h1>
            <p>
              This documentation focuses on using the Developer SDK to post messages and listen for responses from the host platform.
              The SDK provides a streamlined interface for sending commands (such as "platform-connected", "pay" or "create-post") and receiving status notifications.
              It abstracts away internal APIs, letting you concentrate on integrating these functionalities into your app.
            </p>
          </section>
          
          <section id="sdk-overview" className="section-box">
            <h2 className="section-header">Developer SDK Overview</h2>
            <p>
              The Developer SDK provides two fundamental methods:
            </p>
            <ul>
              <li>
                <strong>sendCommand(commandObj)</strong> – Use this method to send a command to the host platform. 
                The <code>commandObj</code> must contain a  <code>command: ninja-app-command</code> property as well as a <code>type</code> property (e.g. "pay", "create-post") and related info.
              </li>
              <li>
                <strong>onCommand(callback)</strong> – Use this method to register a callback that listens for incoming platform messages.
              </li>
            </ul>
            <p>
              Internally, messages are dispatched via <code>window.parent.postMessage</code> with a command of <code>"ninja-app-command"</code>. 
              The SDK also dispatches a custom event so that your app can listen synchronously.
            </p>
          </section>
          
          <section id="detailed-usage" className="section-box">
            <h2 className="section-header">Detailed SDK Messaging Flow</h2>
            <p>
              The messaging flow follows these steps:
            </p>
            <ol>
              <li>
                <strong>Sending a Command:</strong> When you call <code>sendCommand</code> with a command object, it automatically posts a message to the parent window.
                For example, a "pay" command must include a unique <code>ref</code> to correlate responses.
              </li>
              <li>
                <strong>Dispatching Local Event:</strong> After sending the message, a custom event <code>platform-command</code> is dispatched internally so that any listeners registered via <code>onCommand</code> can process the command immediately.
              </li>
              <li>
                <strong>Receiving a Response:</strong> The host platform listens for these messages and sends back responses (like "pay-response" or "create-post") with a matching <code>command</code> field and additional data like <code>responseCode</code>.
              </li>
              <li>
                <strong>Processing the Response:</strong> Your app can listen through a <code>window.addEventListener("message", ...)</code> and also via the SDK’s <code>onCommand</code> method.
              </li>
            </ol>
            <pre>{`<script type="module">
const eventTarget = new EventTarget();

const sendCommand = (commandObj) => {
  try {
    // The command Object must include a ref when sending a "pay" command.
    window.parent.postMessage({ command: "ninja-app-command", detail: commandObj }, "*");
    eventTarget.dispatchEvent(new CustomEvent('platform-command', { detail: commandObj }));
  } catch (err) {
    console.error('Error sending command:', err);
    throw err;
  }
};

const onCommand = (listener) => {
  eventTarget.addEventListener('platform-command', (e) => {
    listener(e.detail);
  });
};

const platformSDK = { sendCommand, onCommand };
</script>
`}</pre>
            <p>
              This code demonstrates both sending and receiving messages. The <code>sendCommand</code> function posts the message and dispatches a custom event,
              while <code>onCommand</code> registers a callback for that event.
            </p>
          </section>
          
          {/* Insert new section for commands overview between "Detailed Messaging Flow" and "Use Cases" */}
          <section id="commands-overview" className="section-box">
            <h2 className="section-header">Commands & Payloads Overview</h2>
            <p>This section details the expected request and response structures for communication between your app and the platform.</p>
            <ul>
              <li>
                <strong>platform-connected</strong> (Platform → App)
                <ul>
                  <li>
                    <em>Payload Structure:</em>
                    <br /><pre style={{ backgroundColor: '#f0f0f0', color: 'var(--bs-code-color)' }}>{`{
  command:"ninja-app-command",
  type: "platform-connected",
  wallet: {
    address: string,
    signature: string
  },
  timestamp: ISOString,
  appId: string
}`}</pre>
                  </li>
                  <li>
                    The <code>signature</code> is computed as a signature of the object <code>{`{ timestamp, appId }`}</code>.
                  </li>
                </ul>
              </li>
              <li>
                <strong>pay</strong> (App → Platform)
                <ul>
                  <li>
                    <em>Request Structure:</em>
                    <br /><pre style={{ backgroundColor: '#f0f0f0', color: 'var(--bs-code-color)' }}>{`{
  command:"ninja-app-command",
  type: "pay",
  address: "recipient_address",
  value: number,
  ref: unique_reference
}`}</pre>
                  </li>
                </ul>
              </li>
              <li>
                <strong>pay-response</strong> (Platform → App)
                <ul>
                  <li>
                    <em>Successful Response Structure:</em>
                    <br /><pre style={{ backgroundColor: '#f0f0f0', color: 'var(--bs-code-color)' }}>{`{
  command: "ninja-app-command",
  type: "pay-response",
  payload: {
    ref: unique_reference,
    success: true,
    txid: "transaction_id",
    signature: "dummy_signature",
    responseCode: "OK_SUCCESS"
  }
}`}</pre>
                  </li>
                  <li>
                    <em>Failure Response Structure:</em>
                    <br /><pre style={{ backgroundColor: '#f0f0f0', color: 'var(--bs-code-color)' }}>{`{
  command: "ninja-app-command",
  type: "pay-response",
  payload: {
    ref: unique_reference,
    success: false,
    txid: null,
    message: "Error message",
    signature: "dummy_signature",
    responseCode: "ERR_ABORTED"
  }
}`}</pre>
                  </li>
                </ul>
              </li>
              <li>
                <strong>create-post</strong> (App → Platform)
                <ul>
                  <li>
                    <em>Request Structure:</em>
                    <br /><pre style={{ backgroundColor: '#f0f0f0', color: 'var(--bs-code-color)' }}>{`{
  command:"ninja-app-command",
  type: "create-post",
  payload: {
    contractTx: "contract_transaction_hash",
    issueTx: "issuance_transaction_hash"
  }
}`}</pre>
                  </li>
                </ul>
              </li>
              <li>
                <strong>create-post-response</strong> (Platform → App)
                <ul>
                  <li>
                    <em>Successful Response Structure:</em>
                    <br /><pre style={{ backgroundColor: '#f0f0f0', color: 'var(--bs-code-color)' }}>{`{
  command:"ninja-app-command",
  type: "create-post-response",
  payload: {
    contractTx: "contract_transaction_hash",
    success: true,
    responseCode: "OK_SUCCESS"
  }
}`}</pre>
                  </li>
                  <li>
                    <em>Failure Response Structure:</em>
                    <br /><pre style={{ backgroundColor: '#f0f0f0', color: 'var(--bs-code-color)' }}>{`{
  command:"ninja-app-command",
  type: "create-post-response",
  payload: {
    contractTx: "contract_transaction_hash",
    success: false,
    error: "Error details",
    responseCode: "ERR_POST_EXCEPTION"
  }
}`}</pre>
                  </li>
                </ul>
              </li>
            </ul>
          </section>

          <section id="stas-789" className="section-box">
            <h2 className="section-header">STAS‑789 Posts</h2>
            <p>
              STAS‑789 Posts are onchain NFTs created on the Bitcoin SV network using our preset token schema.
              The create‑post command uses this schema to generate your post on Metanet.
              Note that the command does not return separate issuance or contract transactions.
            </p>
            <p>
              The preset schema is defined as follows and must not be altered:
            </p>
            <pre>{`{
  protocolId: "STAS-789",
  totalSupply: 1,
  satsPerToken: ${config.SATOSHIS_PER_NFT},
  properties: {
    legal: {
      licence: "${config.STAS789_LICENSE_ID}",
      terms: "${config.STAS789_TERMS}"
    },
    issuer: {
      schemaId: "${config.STAS789_ISSUER_SCHEMA}",
      organisation: "${config.STAS789_ORG}"
    },
    meta: {
      schemaId: "STAS1.0"
    }
  }
}`}</pre>
            <p>
              In addition to the preset values above that MUST BE SET AS IS for us to index the post (you can set more satsPerToken if you want the NFT to have more locked, but not less), you also provide:
            </p>
            <ul>
            <li>
                <strong>description</strong>: Obligatory field. A short description (max length defined in {config.MAX_DESC_CHARS}).
              </li>
              <li>
                <strong>name</strong>: A headline for the post (optional, subject to a maximum length defined in {config.MAX_HEAD_CHARS}).
              </li>
              <li>
                <strong>extraContent</strong>: An array of content objects. Each object should have the following structure:
                <br /><pre>{`[{
  cid: "IPFS content CID",
  type: "text|image|video",
  mimetype: "MIME type",
  ext: "file extension"
}...{}]`}</pre>
                <br />Here, <code>cid</code> is the IPFS-hosted content identifier.
              </li>
              <li>
                <strong>tags</strong>: An array of keywords (e.g., [ "news", "tech", "blockchain" ]) that help index your post.
              </li>
              <li>
                <strong>developer</strong>: An object with your developer details, for example: <pre>{`{
  name: "Your Developer Name",
  appId: "Your App ID"
}`}</pre>
              </li>
            </ul>
            <p>
              The only mandatory field you must fill in is <code>description</code>; all other fields are optional.
              By following this schema and these guidelines, posts grant full ownership to the creator while {config.BRAND_NAME} retains the right to manage and distribute the content on this platform.
            </p>
          </section>

          <section id="use-cases" className="section-box">
            <h2 className="section-header">Use Cases & Examples</h2>
            <h3>Example 1: Receiving Platform Connection Details</h3>
            <p>
              When your app loads inside the iframe, the platform sends a <code>platform-connected</code> message containing your wallet details.
            </p>
            <pre>{`// Example: Receiving the platform-connected message
window.addEventListener("message", (event) => {
  if (event.data && event.data.type === "platform-connected") {
    console.log("Platform connected, wallet details:", event.data.wallet);
    // event.data.timestamp and event.data.appId are also provided.
  }
});
`}</pre>
            <h3>Example 2: Sending a Payment Command</h3>
            <p>
              When the user initiates a payment, send a <code>pay</code> command with a unique reference.
            </p>
            <pre>{`// Example: Sending a pay command
const paymentRef = Date.now();
platformSDK.sendCommand({
  command:"ninja-app-command",
  type: "pay",
  address: "1BS5gz1HSHKguziL7iW9xaEhRYipys4qcS",
  value: 100,
  ref: paymentRef
});
`}</pre>
            <h3>Example 3: Handling a Payment Response</h3>
            <p>
              The platform replies with a <code>pay-response</code> message indicating whether the payment was successful.
            </p>
            <pre>{`// Example: Handling a pay-response message
window.addEventListener("message", (event) => {
  if (event.data && event.data.type === "pay-response" && event.data.command === "ninja-app") {
    const payload = event.data.payload;
    if (payload.success) {
      console.log("Payment successful, txid:", payload.txid);
    } else {
      console.error("Payment failed:", payload.message);
    }
  }
});
`}</pre>
            <h3>Example 4: Sending a Create-Post Command & Handling its Response</h3>
            <p>
              To post new content, send a <code>create-post</code> command. Later, the platform returns a <code>create-post-response</code> message.
            </p>
            <pre>{`// Example: Sending a create-post command
platformSDK.sendCommand({
  command: "create-post",
  payload: {
    contractTx: "yourContractTxHash",
    issueTx: "yourIssueTxHash"
  }
});

// Example: Handling create-post-response
window.addEventListener("message", (event) => {
  if (event.data && event.data.type === "create-post-response") {
    const payload = event.data.payload;
    if (payload.success) {
      console.log("Post created successfully, contractTx:", payload.contractTx);
    } else {
      console.error("Post creation failed:", payload.error);
    }
  }
});
`}</pre>
          </section>
          
          
          {/* NEW: Response Codes Section */}
          <section id="response-codes" className="section-box">
            <h2 className="section-header">Response Codes & Meanings</h2>
            <ul>
              <li><code>OK_SUCCESS</code>: Operation was successful.</li>
              <li><code>ERR_POST_FAIL</code>: Post creation failed due to invalid input or transaction error.</li>
              <li><code>ERR_POST_EXCEPTION</code>: An exception occurred during post creation.</li>
              <li><code>ERR_ABORTED</code>: The operation was aborted by the user.</li>
              <li><code>ERR_UNKNOWN</code>: An unknown error occurred.</li>
              <li><code>ERR_REJECTED</code>: Request was rejected (e.g., confirmation rejected).</li>
            </ul>
          </section>

          <section id="notes" className="section-box">
            <h2 className="section-header">Important Notes</h2>
            <ul>
              <li>
                Always include mandatory fields. For "pay" commands, a unique <code>ref</code> is required.
              </li>
              <li>
                The host platform appends a <code>responseCode</code> to every response. Use this field to determine success or error conditions.
              </li>
              <li>
                Use <code>onCommand</code> to centralize your message handling logic.
              </li>
              <li>
                Try our <strong>testApp.html</strong> example to simulate platform commands. <a href="TestApp.html" download="TestApp.html" >Download Test App</a>
              </li>
            </ul>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Developers;
