import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import OverlayConfirm from './../components/OverlayConfirm';
import { initGlobalSignaler } from '../services/globalSignaler';
import Stas789NFTs from '../components/Stas789NFTs';  // Import the NFT component
import Holdings from '../components/Holdings';
import { linkUsername, lookupUser, unlinkUsername, getUserComments } from '../services/apiOutCalls';
import { getPKHashFromAddress } from '../utils/hash160';
import { useGlobalAlert } from '../components/GlobalAlert';
import OverlayFollows from '../components/OverlayFollows';
import EmbeddedPost from '../components/EmbeddedPost'; // NEW
import '../css/WalletCreator.css';

const Profile = () => {
  const { wallet, walletSwitch, switchWallet, logout, setWallet, updateWalletSwitch } = useContext(AuthContext);
  const { showAlert } = useGlobalAlert();
  const [activeModule, setActiveModule] = useState(null);
  const [totalFunds, setTotalFunds] = useState(0);
  const [nftHoldings, setNftHoldings] = useState([]);
  const [loadingNFTs, setLoadingNFTs] = useState(true);
  const navigate = useNavigate();
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [username, setUsername] = useState(null);
  const [loadingUsername, setLoadingUsername] = useState(true);
  const [showUsernamePrompt, setShowUsernamePrompt] = useState(false);
  const [showFollowsOverlay, setShowFollowsOverlay] = useState(false);
  // NEW state for unlink confirmation
  const [showUnlinkConfirm, setShowUnlinkConfirm] = useState(false);
  // NEW: add states for tabs and comments
  const [activeTab, setActiveTab] = useState("posts");
  const [profileComments, setProfileComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  // NEW state for showing profiles modal
  const [showProfilesModal, setShowProfilesModal] = useState(false);
  const [showSwitchConfirm, setShowSwitchConfirm] = useState(false);
  // Add a new state to hold the pending wallet selection.
  const [pendingWallet, setPendingWallet] = useState(null);

  useEffect(() => {
    if (!wallet) {
      navigate('/');
    } else {
      async function fetchUsername() {
        try {
          const res = await lookupUser(wallet);
          if (res.success && res.user && res.user.username) {
            // Update local username state
            setUsername(res.user.username);
            // Create an updated wallet with the username and update walletSwitch using our new method.
            const updatedWallet = { ...wallet, username: res.user.username };
            updateWalletSwitch(updatedWallet);
          }
        } catch (err) {
          console.error("Error fetching username:", err);
        }
        setLoadingUsername(false);
      }
      fetchUsername();
    }
  }, [wallet]);

  useEffect(() => {
    loadNFTHoldings();
  }, [wallet]);

  const loadNFTHoldings = () => {
    setLoadingNFTs(true);
    // Simulate asynchronous fetch (replace this with real NFT fetch logic as needed)
    setTimeout(() => {
      setNftHoldings([]); 
      setLoadingNFTs(false);
    }, 800);
  };

  const onLogout = () => {
    setShowLogoutConfirm(true);
  };

  const handleLogoutConfirmComplete = ({ status }) => {
    setShowLogoutConfirm(false);
    if (status && wallet) {
      const globalSignaler = initGlobalSignaler(wallet);
      globalSignaler.removeAllChats();
      // Delay logout to avoid concurrent rendering errors
      setTimeout(() => {
        logout();
        navigate('/auth');
      }, 0);
    }
  };

  // NEW: Confirm before unlinking username.
  const handleUnlinkConfirm = async (result) => {
    setShowUnlinkConfirm(false);
    if (result.status) {
      try {
        const response = await unlinkUsername(wallet);
        if(response.success) {
          setUsername(null);
          const updatedWallet = { ...wallet };
          delete updatedWallet.username;
          updateWalletSwitch(updatedWallet);
          showAlert("Username unlinked successfully", "warning", "Success");
        } else {
          showAlert(response.error || "Failed to unlink username", "danger", "Error");
        }
      } catch(err) {
        showAlert(err.message, "danger", "Error");
      }
    }
  };

  // Modified handleUnlink now shows the confirmation overlay.
  const handleUnlink = () => {
    setShowUnlinkConfirm(true);
  };

  // NEW: Fetch comments when "comments" tab is active, using pubkeyhash of wallet.address
  useEffect(() => {
    const fetchComments = async () => {
      if (activeTab === "comments" && wallet) {
        setLoadingComments(true);
        try {
          const pkhash = getPKHashFromAddress(wallet.address);
          const result = await getUserComments(wallet, pkhash);
          if (result.success) {
            setProfileComments(result.comments);
          } else {
            setProfileComments([]);
          }
        } catch (err) {
          console.error("Error fetching comments:", err);
          setProfileComments([]);
        }
        setLoadingComments(false);
      }
    };
    fetchComments();
  }, [activeTab, wallet]);

  // Modify handleChangeProfile to update the username state based on the selected wallet.
  const handleChangeProfile = (type, value) => {
    // Hide the profiles modal
    setShowProfilesModal(false);
    if (type === "logout") {
      onLogout(); // Trigger logout confirmation overlay
    } else if (type === "switch") {
      navigate('/auth'); // Navigate to authenticate when switching wallets
    } else if (type === "select") {
      // Instead of immediately switching, store the selected wallet and show a confirmation overlay.
      setPendingWallet(value);
      setShowSwitchConfirm(true);
    }
  };

  return (
    <div className="m-0 p-3">
      {/* Updated Minimalistic Menu */}
      <div className="recipient-card p-2 mb-2 border rounded d-flex justify-content-between align-items-center w-100">
        <div>
          {loadingUsername ? (
            <span className="text-muted">
              <i className="fa fa-spinner fa-spin me-1"></i> Loading...
            </span>
          ) : username ? (
            <span className="text-dark">
              <i className="fa fa-user me-1"></i> @{username}
              <button onClick={handleUnlink} className="btn btn-link p-0 ms-2" title="Unlink username">
                <i className="fa fa-unlink"></i>
              </button>
            </span>
          ) : (
            <span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => setShowUsernamePrompt(true)}>
              <i className="fa fa-user me-1"></i> Set Username
            </span>
          )}
        </div>
       
        <button
          className="btn text-dark p-0"
          onClick={() => setShowFollowsOverlay(true)}
        >
          <i className="fa fa-users me-1"></i> Follows
        </button>
        {/* Replace the previous select dropdown with a Profiles button */}
        <button className="btn text-dark p-0" onClick={() => setShowProfilesModal(true)}>
          Profiles
        </button>
      </div>
      
      {/* NEW: Profiles Popover Modal */}
      {showProfilesModal && (
	<div 
		className="modal-overlay" 
		onClick={() => setShowProfilesModal(false)}
		style={{
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0,0,0,0.6)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 1000,
		}}
	>
		<div 
			className="modal-content"
			onClick={e => e.stopPropagation()}
			style={{
				backgroundColor: '#fff',
				padding: '20px',
				borderRadius: '4px',
				maxWidth: '600px',
				width: '90%',
			}}
			>
            <div className="modal-header" style={{ fontWeight: 'bold', fontSize: '1.25rem', marginBottom: '10px', textAlign: 'center' }}>
                Select Profile
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
					{walletSwitch.map(w => (
						<button 
							key={w.address} 
              disabled={w.address === wallet.address}
							className="btn btn-primary border w-100"
							onClick={() => handleChangeProfile("select", w)}
						>
							{w.username ? '@'+w.username : w.address}
						</button>
					))}
			</div>
        
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem' }}>
				<button 
					className="btn btn-outline-primary"
					onClick={() => handleChangeProfile("switch")}
				>
					Add Profile
				</button>
				
				<button 
					className="btn btn-outline-danger"
					onClick={() => handleChangeProfile("logout")}
				>
					Logout All
				</button>
			</div>
		</div>
	</div>
)}
      {showUsernamePrompt && (
        <OverlayConfirm 
          confirmation={{ messages: ['Enter your preferred username. Remember your username will end with '+  new Date().getFullYear()+' and look like "@username.'+  new Date().getFullYear()], requiresInput: true }}
          optionsMsg={{ confirm: "Confirm", cancel: "Cancel" }}
          onComplete={async (result) => {
            setShowUsernamePrompt(false);
            if (result.status && result.values && result.values[0]) {
              const newUsername = result.values[0].trim();
              try {
                const response = await linkUsername(wallet, newUsername);
                if (response.success) {
                  const updatedUsername = `${newUsername}.${new Date().getFullYear()}`;
                  setUsername(updatedUsername);
                  // Build updated wallet object including username.
                  const updatedWallet = { ...wallet, username: updatedUsername };
                  updateWalletSwitch(updatedWallet);
                  showAlert("Username linked successfully", "success", "Success");
                } else {
                  showAlert(response.error || "Username already taken", "danger", "Error");
                }
              } catch (err) {
                showAlert(err.message, "danger", "Error");
              }
            }
          }}
        />
      )}
      {/* NEW: Two tabs for Posts and Comments */}
      <div className="tabs mt-3 mb-0">
        <button 
          className={`btn ${activeTab === "posts" ? "btn-primary" : "btn-outline-primary"} me-2`}
          onClick={() => setActiveTab("posts")}
        >
          NFT Posts
        </button>
        <button 
          className={`btn ${activeTab === "comments" ? "btn-primary" : "btn-outline-primary"}`}
          onClick={() => setActiveTab("comments")}
        >
          NFT Comments
        </button>
      </div>
      
      
      {activeTab === "comments" ? (
        <div className="mt-4">
          {loadingComments ? (
            <div className="spinner">Loading Comments...</div>
          ) : profileComments.length > 0 ? (
            profileComments.map((comment, idx) => (
              <div style={{marginBottom:'20px', border:'2px solid #ccc'}}><EmbeddedPost parentEmbed={true} wallet={wallet} meta={comment.reaction} />
              <EmbeddedPost isReply={true} wallet={wallet} meta={comment} post={comment.reaction} />
              </div>
            ))
          ) : (
            <div>No comments found.</div>
          )}
        </div>
      ) : (
        <div className="m-0 p-0 mt-4">
          {loadingNFTs ? (
            <div className="spinner">Loading NFT Holdings...</div>
          ) : (
            <Stas789NFTs
              ownProfile={true}
              style={{ width: '100%' }}
              wallet={wallet}
              onNFTDataChange={(data) => {
                setNftHoldings(data.nfts);
              }}
            />
          )}
        </div>
      )}
      {showLogoutConfirm && (
        <OverlayConfirm 
          confirmation={{
            messages: [
              "Have you backed up your private key? We cannot recover your account.",
              "Are you absolutely sure you want to logout? P2P chats will be cleared and lost.",
              "There is no go back. Last chance. Have you backed up your wallet?"
            ],
            requiresInput: false
          }}
          optionsMsg={{ confirm: "Yes, Logout", cancel: "No, Stay" }}
          onComplete={handleLogoutConfirmComplete}
        />
      )}
      {showUnlinkConfirm && (
        <OverlayConfirm 
          confirmation={{
            messages: [
              "Are you sure you want to unlink your username? This will remove the association between your username and your address, and someone else may claim it."
            ],
            requiresInput: false
          }}
          optionsMsg={{ confirm: "Yes, Unlink", cancel: "Cancel" }}
          onComplete={handleUnlinkConfirm}
        />
      )}
      {showFollowsOverlay && (
        <OverlayFollows
          wallet={wallet}
          onClose={() => setShowFollowsOverlay(false)}
        />
      )}
      {showSwitchConfirm && (
        <OverlayConfirm 
          confirmation={{
            messages: [
              "Switching profiles will clear your current P2P conversations and messages. We do not keep any backup for you. Make sure you do if you want to keep them. Are you sure you want to Switch?"
            ],
            requiresInput: false
          }}
          optionsMsg={{ confirm: "Yes, Switch", cancel: "Cancel" }}
          onComplete={(result) => {
            setShowSwitchConfirm(false);
            if (result.status && pendingWallet) {
              switchWallet(pendingWallet);
              setUsername(pendingWallet.username ? pendingWallet.username : null);
              setPendingWallet(null);
            } else {
              setPendingWallet(null);
            }
          }}
        />
      )}
    </div>
  );
};

export default Profile;