import React, { useState, useEffect, useRef } from 'react';
import BSVIcon from '../assets/bsv-icon.svg';
import PlaceholderIcon from '../assets/placeholder-icon.svg';
import '../css/Holdings.css';
import OneSatOrdinals from './OneSatOrdinals';
import { NavLink, useNavigate } from 'react-router-dom';
// Use dynamicApiCall instead of fetchData.
import { dynamicApiCall } from '../services/apiOutCalls';

const Holdings = ({ wallet }) => {
  const navigate = useNavigate();

  // BSV balance state.
  const [bsvBalance, setBsvBalance] = useState(null);
  // Tokens state from API.
  const [tokens, setTokens] = useState([]);
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [loadingTokens, setLoadingTokens] = useState(true);
  const fetchStartedRef = useRef(false);
  
  // Tabs state.
  const [activeTab, setActiveTab] = useState('tokens');

  // Fetch BSV balance using dynamicApiCall.
  useEffect(() => {
    setLoadingBalance(true);
    dynamicApiCall(wallet,{ action: "getWalletBalance", address: wallet.address })
      .then(result => {
        if (result.success && result.data) {
          setBsvBalance(result.data.confirmed);
        }
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingBalance(false));
  }, [wallet.address]);

  // Fetch token balances using dynamicApiCall.
  useEffect(() => {
    async function loadTokenBalances() {
      setLoadingTokens(true);
      try {
        const tokensData = await dynamicApiCall(wallet,{ action: "getTokensBalance", address: wallet.address });
        // Assume tokensData.data.holdings returns an array of token objects.
        setTokens(tokensData.data);
      } catch (err) {
        console.error(err);
      }
      setLoadingTokens(false);
    }
    loadTokenBalances();
  }, [wallet.address]);

  return (
    <div className="mt-4">
      {/* <div className="tab-header mb-3">
        <button 
          className={`btn btn-info ${activeTab === 'tokens' ? 'active' : ''}`}
          onClick={() => setActiveTab('tokens')}
        >
          Tokens
        </button>
        <button 
          className={`btn btn-outline-info ${activeTab === 'nfts' ? 'active' : ''}`}
          onClick={() => setActiveTab('nfts')}
        >
          1sat Ordinals
        </button>
      </div> */}
      
      {activeTab === 'tokens' && (
        <div className="tokens-section p-0 mb-4">
          <div className="holdings-container">
            <div className="holding-item d-flex align-items-center justify-content-between p-2 border rounded mb-2">
              {/* Left Column: Icon and Symbol */}
              <div className="holding-col d-flex align-items-center" style={{width: '33%'}}>
                <img src={BSVIcon} alt="BSV" className="holding-icon me-2" style={{width: '24px', height: '24px'}} />
                <span className="holding-token">BSV</span>
              </div>
              {/* Middle Column: Balance */}
              <div className="holding-col text-center" style={{width: '33%'}}>
                <span className="holding-balance">
                  {bsvBalance !== null ? bsvBalance : 'N/A'} <br></br>satoshis
                </span>
              </div>
              {/* Right Column: Action Button */}
              <div className="holding-col text-end" style={{width: '33%'}}>
                <NavLink to="/transactions" className="btn btn-primary btn-sm w-100">
                  Show History
                </NavLink>
              </div>
            </div>
            {tokens.filter(token => token.protocol === 'STAS-20').map(token => (
              <div key={token.symbol} className="holding-item d-flex align-items-center justify-content-between p-2 border rounded mb-2">
                {/* Left Column: Icon and Symbol */}
                <div className="holding-col d-flex align-items-center" style={{width: '33%'}}>
                  {/* <img
                    src={token.image ? token.image : PlaceholderIcon}
                    alt={token.symbol}
                    className="holding-icon me-2"
                    style={{width: '24px', height: '24px'}}
                  /> */}
                  <span className="holding-token">{token.symbol}</span>
                  </div>
                {/* Middle Column: Balance */}
                <div className="holding-col text-center" style={{width: '33%'}}>
                  <span className="holding-balance">{token.balance}</span>
                </div>
                {/* Right Column: Action Button */}
                <div className="holding-col text-end" style={{width: '33%'}}>
                  <button 
                    className="btn btn-primary btn-sm w-100"
                    onClick={() => navigate(`/transfer/${token.redeemAddr}/${token.symbol}?splittable=true`)}
                  >
                    Send
                  </button>
                </div>
              </div>
            ))}
            {loadingTokens && (
              <div className="spinner">Loading token balances...</div>
            )}
          </div>
        </div>
      )}
      
      {activeTab === 'nfts' && (
        <div className="onesat-section">
          <OneSatOrdinals wallet={wallet} />
        </div>
      )}
    </div>
  );
};

export default Holdings;