import React, { useState,useContext } from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // changed import
import { AuthContext } from '../context/AuthProvider'; // changed import
const ReceiveQRCodeOverlay = ({ walletAddress, onClose }) => {
  const [copied, setCopied] = useState(false); // added state for copy feedback
  const { wallet } = useContext(AuthContext);

  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div
      style={{
        position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        zIndex: 1000,
        padding:'20px'
      }}
    >
      <div style={{
        backgroundColor: '#fff',
        borderRadius: '8px',
        maxWidth: '600px',
        minWidth: '350px',
        overflow: 'hidden', // removes overall padding in container
        position: 'relative',
        boxShadow: '0 2px 6px rgba(0,0,0,0.2)'
      }}>
        <div style={{ 
            backgroundColor: '#f2f2f2', 
            padding: '10px', 
            borderBottom: '1px solid #ccc', 
            textAlign: 'center', 
            fontWeight: 'bold', 
            fontSize: '16px'
          }}>
          Receiving Address
        </div>
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <QRCodeCanvas value={walletAddress} size={128} />
          <div
            onClick={handleCopy}
            style={{
              marginTop: '10px',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              cursor: 'pointer',
              userSelect: 'all',
              backgroundColor: '#fafafa',
              position: 'relative',
              fontSize: 'clamp(10px, 2vw, 16px)'
            }}
          >
            {walletAddress}
            {copied && (
              <div style={{
                position: 'absolute',
                top: '-25px',
                right: '0',
                backgroundColor: '#4caf50',
                color: '#fff',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '12px'
              }}>
                Copied!
              </div>
            )}
          </div>
        </div>
        <div style={{ 
            backgroundColor: '#f2f2f2', 
            padding: '10px', 
            borderTop: '1px solid #ccc', 
            textAlign: 'justify',
            fontSize: '14px',
            color: '#555'
          }}>
          ONLY send Bitcoin SV or STAS-20 tokens. {wallet && wallet.address==walletAddress ? "Backup, logout and login again to ensure your backup works, BEFORE you send any money. Metanet.ninja is NOT a wallet provider but a Web3.0- payments enabled -platform. Check our TOS and FAQ for more details.":"Transactions are non refundable."}
        </div>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            border: 'none',
            background: 'transparent',
            fontSize: '20px',
            cursor: 'pointer',
            lineHeight: '1'
          }}
        >
          ×
        </button>
      </div>
    </div>
  );
};

export default ReceiveQRCodeOverlay;
