import React, { useState, useEffect } from 'react';
import { ConfigContext } from '../config';
import { dynamicApiCall } from '../services/apiOutCalls';
import moment from 'moment';

const ShowBalance = ({ wallet, onFundsChange }) => {
  const config = React.useContext(ConfigContext);
  const [totalFunds, setTotalFunds] = useState(0);

  useEffect(() => {
    if (wallet && wallet.address) {
      dynamicApiCall(wallet,{ action: "getConfirmedUnspent", address: wallet.address })
        .then(result => {
          if (result.success && result.data) {
            const funds = result.data.reduce((sum, utxo) => sum + utxo.value, 0);
            setTotalFunds(funds);
            if (onFundsChange) onFundsChange(funds);
          }
        })
        .catch(err => console.error(err));
    }
  }, [wallet]);

  const FX_BSV = config.FX_BSV;
  const converted = totalFunds / 100000000;
  const dollarValue = converted * config.FX_BSV;
  const formatted = converted.toFixed(4);
  const formattedUSD = dollarValue.toFixed(4);
  const balanceDisplay = formatted.endsWith("0000") ? `${totalFunds} sats @ $${config.FX_BSV}/BSV` : `$BSV ${formatted} @ $${config.FX_BSV}/BSV`;
  const usdBalanceDisplay = formattedUSD.endsWith("0000") ? `$US ${formattedUSD}` : `$US ${formattedUSD}`;

  return (
    <>
      {FX_BSV > 0 ? (
        <div
          className="alert alert-light p-2"
          style={{
            margin: '0px',
            marginBottom: '1rem',
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textAlign: 'left'
          }}
        >
          Balance: {balanceDisplay} ({usdBalanceDisplay} on {moment(config.fxUpdatedAt).format('D/M/YY, HH:mm')}. USD rate may vary extremely for many factors, verify before spending)
        </div>
      ) : (
        <div
          className="alert alert-warning p-2"
          style={{
            margin: '0px',
            marginBottom: '1rem',
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textAlign: 'left'
          }}
        >
          Non-accurate balance: BSV price is not currently retrieved. Please contact support if the problem persists.
        </div>
      )}
    </>
  );
};

export default ShowBalance;
