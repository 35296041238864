import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFollows, followUser, unfollowUser } from '../services/devApiService'; // Updated import
import { lookupPubkeyhash } from '../services/apiOutCalls'; // Updated import
import { getAddressFromPKHash } from '../utils/hash160';

const OverlayFollows = ({ wallet, onClose }) => {
  const [view, setView] = useState('following'); // Default to "Following" tab
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]); // For filtered results
  const [searchQuery, setSearchQuery] = useState(''); // Search query
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadFollows(view, 1);
  }, [view]);

  const loadFollows = async (type, newPage) => {
    setLoading(true);
    try {
      const res = await getFollows(wallet, type, newPage);
      if (res.success) {
        setItems(res.items);
        setFilteredItems(res.items); // Initialize filtered items
        setPage(res.page);
        setLimit(res.limit);
        setTotal(res.total);
      }
    } catch (err) {
      console.error("Error loading follows:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleUnfollow = async (followee) => {
    const res = await unfollowUser(wallet, followee);
    if (res.success) {
      setItems(items.map(i => 
        i.followee === followee ? { ...i, unfollowed: true } : i
      ));
      setFilteredItems(filteredItems.map(i => 
        i.followee === followee ? { ...i, unfollowed: true } : i
      ));
    }
  };

  const handleFollow = async (followee) => {
    const res = await followUser(wallet, followee);
    if (res.success) {
      setItems(items.map(i => 
        i.followee === followee ? { ...i, unfollowed: false } : i
      ));
      setFilteredItems(filteredItems.map(i => 
        i.followee === followee ? { ...i, unfollowed: false } : i
      ));
    }
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setFilteredItems(items); // Reset to full list if search query is empty
      return;
    }

    setLoading(true);
    try {
      let pubkeyhash = null;

      // Check if the input is a Bitcoin address
      if (/^(1|3)[a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(searchQuery)) {
        pubkeyhash = getAddressFromPKHash(searchQuery);
      } else {
        // Otherwise, treat it as a username and look up the pubkeyhash
        const res = await lookupPubkeyhash(wallet, searchQuery);
        if (res.success && res.pubkeyhash) {
          pubkeyhash = res.pubkeyhash;
        }
      }

      if (pubkeyhash) {
        const filtered = items.filter(i =>
          view === 'following' ? i.followee === pubkeyhash : i.follower === pubkeyhash
        );
        setFilteredItems(filtered);
      } else {
        setFilteredItems([]); // No match found
      }
    } catch (err) {
      console.error("Error searching follows:", err);
      setFilteredItems([]); // No match found
    } finally {
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(total / limit);

  const truncateAddress = (address) => {
    return address.length > 15 ? `${address.substring(0, 6)}...${address.substring(address.length - 6)}` : address;
  };

  return (
    <div className="modal show d-block">
      <div className="modal-backdrop show"></div>
      <div className="modal-dialog" style={{ zIndex: '1200' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="m-0">Follows</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <div className="btn-group d-flex">
              <button
                className={`btn ${view === 'following' ? 'btn-primary' : 'btn-outline-primary'} w-50`}
                onClick={() => setView('following')}
              >
                Following
              </button>
              <button
                className={`btn ${view === 'followers' ? 'btn-primary' : 'btn-outline-primary'} w-50`}
                onClick={() => setView('followers')}
              >
                Followers
              </button>
            </div>
            <div className="mt-2 text-left bg-light p-2 rounded border">
              <small className="text-muted">Total: {total}</small>
            </div>
            <div className="mt-3 d-flex">
              <input
                type="text"
                className="form-control me-2"
                placeholder="Search by username or address"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button
                className="btn btn-primary"
                onClick={handleSearch}
                disabled={loading}
              >
                Search
              </button>
            </div>
            {loading && <div className="spinner mb-2">Loading...</div>}
            <ul className="list-group mt-3">
              {filteredItems.map((i, idx) => {
                const address = view === 'following'
                  ? getAddressFromPKHash(i.followee)
                  : getAddressFromPKHash(i.follower);
                const displayName = i.username || truncateAddress(address); // Use username if available
                return (
                  <li
                    className={`list-group-item d-flex justify-content-between align-items-center p-1`}
                    key={idx}
                  >
                    <span
                      style={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      onClick={() => navigate(`/profile/${address}`)}
                    >
                      {displayName}
                    </span>
                    <div className="d-flex align-items-center">
                      <button
                        className="btn btn-sm btn-info p-1 ps-2 pe-2 me-1"
                        onClick={() => navigate(`/message/${address}`)}
                      >
                        Message
                      </button>
                      {view === 'following' && (
                        i.unfollowed ? (
                          <button
                            className="btn btn-outline-success p-1 ps-2 pe-2"
                            onClick={() => handleFollow(i.followee)}
                          >
                            Follow
                          </button>
                        ) : (
                          <button
                            className="btn btn-outline-danger p-1 ps-2 pe-2"
                            onClick={() => handleUnfollow(i.followee)}
                          >
                            Unfollow
                          </button>
                        )
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <button
                className="btn btn-secondary btn-sm"
                disabled={page <= 1 || loading}
                onClick={() => loadFollows(view, page - 1)}
              >
                Back
              </button>
              <small className="text-muted">
                Page {page} of {totalPages}
              </small>
              <button
                className="btn btn-secondary btn-sm"
                disabled={page >= totalPages || loading}
                onClick={() => loadFollows(view, page + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayFollows;
