import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { initGlobalSignaler } from '../services/globalSignaler';
import { AuthContext } from '../context/AuthProvider';
import { sendInvite, fetchInvites, updateInvite, fetchPendingInvites } from '../services/devApiService';
import moment from 'moment';
import 'moment-timezone';  // ensure moment-timezone is installed
import { useGlobalAlert } from '../components/GlobalAlert'; // NEW
import OverlayConfirm from '../components/OverlayConfirm';
import { lookupUser, lookupUsersBulk } from '../services/apiOutCalls';
import {getAddressFromPKHash, getPKHashFromAddress} from '../utils/hash160'; // NEW
import CryptoJS from 'crypto-js';
import '../css/Conversations.css';

const Conversations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialTab = new URLSearchParams(location.search).get("tab") || "Chats";
  const { wallet } = useContext(AuthContext);
  const myAddress = wallet ? wallet.address : ''; // <-- changed from getPKHashFromAddress(wallet.address)
  const { showAlert } = useGlobalAlert(); // NEW

  const [conversations, setConversations] = useState([]);
  const [invites, setInvites] = useState([]);
  const [friendAddress, setFriendAddress] = useState('');
  const [activeTab, setActiveTab] = useState(initialTab); // Now three values: "Chats", "Schedule", "Invites"
  const [weekOffset, setWeekOffset] = useState(0);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteReceiver, setInviteReceiver] = useState('');
  const [meetingDateTime, setMeetingDateTime] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState(moment().utcOffset()/60);
  const [description, setDescription] = useState('');
  const [invitesGroups, setInvitesGroups] = useState([]); // groups returned from backend
  const [showProposeAltModal, setShowProposeAltModal] = useState(false);
  const [currentInviteIdForAlt, setCurrentInviteIdForAlt] = useState(null);
  const [altMeetingDateTime, setAltMeetingDateTime] = useState('');
  const [altTimezone, setAltTimezone] = useState('');
  const [startWeek, setStartWeek] = useState(moment().startOf('week').toISOString());
  const weeksCount = 4;
  const [pendingInvites, setPendingInvites] = useState([]);
  const [pendingPage, setPendingPage] = useState(1);
  const [pendingTotalPages, setPendingTotalPages] = useState(1);
  const [pendingCount, setPendingCount] = useState(0);
  const [chatSubTab, setChatSubTab] = useState("Conversations");
  const [approvedList, setApprovedList] = useState({});
  const [showClearApprovals, setShowClearApprovals] = useState(false);
  const [approvedUserMap, setApprovedUserMap] = useState({}); // NEW

  const updateConversations = () => {
    const convs = [];
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith('conv_')) {
        const otherAddress = key.replace('conv_', '');
        const historyConv = JSON.parse(localStorage.getItem(key)) || [];
        const lastMsg = historyConv[historyConv.length - 1] || { content: '' };
        const unread = historyConv.some(msg => msg.from !== myAddress && !msg.read);
        convs.push({ address: otherAddress, lastMessage: lastMsg.content, timestamp: lastMsg.timestamp, unread });
      }
    });
    convs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    
    // --- New bulk lookup logic with validation ---
    // Combine conversation addresses with approved addresses:
    const convAddresses = [...new Set(convs.map(c => c.address))];
    const approvedAddresses = Object.keys(approvedList);
    const allAddresses = [...new Set([...convAddresses, ...approvedAddresses])];
    const pubkeyhashes = allAddresses.map(addr => getPKHashFromAddress(addr));
    
    lookupUsersBulk(wallet, pubkeyhashes)
      .then(res => {
        if (res.success && res.users) {
          const lookupMap = {};
          res.users.forEach(u => {
            lookupMap[u.pubkeyhash] = u.username;
          });
          const updatedConvs = convs.map(c => {
            const pkh = getPKHashFromAddress(c.address);
            return { ...c, displayName: lookupMap[pkh] || c.address };
          });
          setConversations(updatedConvs);
        } else {
          const updatedConvs = convs.map(c => ({ ...c, displayName: c.address }));
          setConversations(updatedConvs);
        }
      })
      .catch(err => {
        console.error("Bulk lookup failed:", err);
        const updatedConvs = convs.map(c => ({ ...c, displayName: c.address }));
        setConversations(updatedConvs);
      });
    // --- End new bulk lookup logic ---
  };

  const updateInvites = async () => {
    try {
      const res = await fetchInvites(wallet, startWeek);
      if (res.success) {
        setInvitesGroups(res.groups);
      }
    } catch (err) {
      console.error("Error fetching invites", err);
    }
  };

  const updatePendingInvites = async () => {
    try {
      const res = await fetchPendingInvites(wallet, pendingPage, 20);
      if (res.success) {
        setPendingInvites(res.pendingInvites);
        setPendingCount(res.total);
        setPendingTotalPages(res.totalPages);
      }
    } catch(err) {
      console.error("Error fetching pending invites", err);
    }
  };

  useEffect(() => {
    if (myAddress!='' && myAddress){
      updateConversations();
      updateInvites();
    }
  }, [myAddress, startWeek]);

  useEffect(() => {
    const globalSignaler = initGlobalSignaler(wallet);
    const updateHandler = () => {
      updateConversations();
      updateInvites();
    };
    globalSignaler.addEventListener('conversationUpdate', updateHandler);
    return () => {
      globalSignaler.removeEventListener('conversationUpdate', updateHandler);
    };
  }, [myAddress]);

  useEffect(() => {
    if (wallet) updateInvites();
  }, [wallet]);

  useEffect(() => {
    if (activeTab === "Invites" && wallet) updatePendingInvites();
  }, [activeTab, wallet, pendingPage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if(tab && tab !== activeTab) {
      setActiveTab(tab);
    }
  }, [location.search]);

  useEffect(() => {
    if (myAddress) {
      const globalSignaler = initGlobalSignaler(wallet);
      setApprovedList({ ...globalSignaler.approvedConnections });
      const approvalListener = (e) => {
        setApprovedList({ ...e.detail.approved });
      };
      globalSignaler.addEventListener('approvalUpdate', approvalListener);
      return () => {
        globalSignaler.removeEventListener('approvalUpdate', approvalListener);
      };
    }
  }, [myAddress]);

  useEffect(() => {
    if (wallet && Object.keys(approvedList).length > 0) {
      const addresses = Object.keys(approvedList);
      const pubkeyhashes = addresses.map(addr => getPKHashFromAddress(addr));
      lookupUsersBulk(wallet, pubkeyhashes)
        .then(res => {
          if (res.success && res.users) {
            const map = {};
            res.users.forEach(u => {
              addresses.forEach(addr => {
                if(getPKHashFromAddress(addr) === u.pubkeyhash){
                  map[addr] = u.username;
                }
              });
            });
            setApprovedUserMap(map);
          } else {
            setApprovedUserMap({});
          }
        })
        .catch(err => {
          console.error("Bulk lookup for approvals failed:", err);
          setApprovedUserMap({});
        });
    } else {
      setApprovedUserMap({});
    }
  }, [approvedList, wallet]);

  const handleStartConversation = async (e) => {
    e.preventDefault();
    const input = friendAddress.trim();
    if (!input) return;
    if (/^(1|3)[a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(input)) {
      navigate(`/message/${input}`);
    } else {
      try {
        const res = await lookupUser(wallet, { username: input });
        if (res.success && res.user && res.user.pubkeyhash) {
          const usernameAddress = getAddressFromPKHash(res.user.pubkeyhash);
          if (usernameAddress!=wallet.address){
            navigate(`/message/${usernameAddress}`);
          } else {
            showAlert("The username you chose appears to be linked to your address. You can't chat with yourself.", "danger");
          }
        } else {
          showAlert("Username not found", "danger");
        }
      } catch(err) {
        console.error(err);
        showAlert("Error looking up username", "danger");
      }
    }
  };


  // Add a standalone method for submitting the invite:
  const submitInvite = async () => {
    if (!meetingDateTime || selectedTimezone === null) {
      showAlert("Please set both date and timezone.", "danger");
      return;
    }
    if (moment(meetingDateTime).isBefore(moment())) {
      showAlert("Meeting must be in the future.", "danger");
      return;
    }
    let receiverValue = "";
    // Determine if inviteReceiver is a valid BSV address
    if (/^(1|3)[a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(inviteReceiver)) {
      receiverValue = getPKHashFromAddress(inviteReceiver);
    } else if (inviteReceiver.startsWith('@') || !/^(1|3)/.test(inviteReceiver)) {
      // Treat input as a username
      const usernameInput = inviteReceiver.startsWith('@') ? inviteReceiver.slice(1) : inviteReceiver;
      try {
        const res = await lookupUser(wallet, { username: usernameInput });

        if (res.success && res.user && res.user.pubkeyhash) {
          const usernameAddress = getAddressFromPKHash(res.user.pubkeyhash);
          if (usernameAddress!=wallet.address){
            receiverValue = res.user.pubkeyhash;
          } else {
            showAlert("The username you chose appears to be linked to your address. You can't invite yourself.", "danger");
            return;
          }
        } else {
          showAlert("Username not found", "danger");
          return;
        }
      } catch (err) {
        console.error(err);
        showAlert("Error looking up username", "danger");
        return;
      }
    } else {
      showAlert("Invalid receiver input", "danger");
      return;
    }
    try {
      // Convert selectedTimezone to hours:minutes
      const hours = Math.floor(Math.abs(selectedTimezone));
      const minutes = Math.round((Math.abs(selectedTimezone) - hours) * 60);
      const sign = selectedTimezone >= 0 ? '+' : '-';
      const tzHours = String(hours).padStart(2, '0');
      const tzMins = String(minutes).padStart(2, '0');
      const timezoneStr = `${sign}${tzHours}:${tzMins}`;
      const compositeTime = meetingDateTime + ":00" + timezoneStr;
      await sendInvite(wallet, {
        receiver: receiverValue,
        meetingDateTime: compositeTime,  // e.g., "2025-03-26T22:00:00-03:00"
        description
      });
      setShowInviteModal(false);
      setInviteReceiver('');
      setMeetingDateTime('');
      // Reset selectedTimezone to current offset.
      setSelectedTimezone(moment().utcOffset()/60);
      setDescription('');
      updateInvites();  // Refresh your invites list.
      showAlert("Invite sent!", "success");
    } catch (err) {
      console.error("Error sending invite", err);
      showAlert(err.message || "Failed to send invite", "danger");
    }
  };

  // New: Handle invite action updates (approve, reject, propose_alt)
  const handleInviteAction = async (inviteId, action) => {
    try {
      const res = await updateInvite(wallet, inviteId, { actionType: action });
      if (!res.success) { // NEW check response
        showAlert(res.error || "Failed to update invite", "danger");
        return;
      }
      // Remove the updated invite from local pendingInvites
      setPendingInvites(prev => prev.filter(inv => inv._id !== inviteId));
      showAlert(`Invite ${action} succeeded`, "success");
      updateInvites();
    } catch (err) {
      console.error("Error updating invite", err);
      showAlert(err.message || "Failed to update invite", "danger");
    }
  };

  // New: Handle Propose Alt action by opening modal
  const openProposeAltModal = (inviteId) => {
    let foundInvite;
    invitesGroups.forEach(group => {
      group.invites.forEach(invite => {
        if (invite._id === inviteId) {
          foundInvite = invite;
        }
      });
    });

    if (foundInvite) {
      const mOriginal = moment.parseZone(fixTimezone(foundInvite.meetingDateTime));
      setAltMeetingDateTime(mOriginal.format("YYYY-MM-DDTHH:mm"));
      setAltTimezone(Number((mOriginal.utcOffset() / 60).toFixed(2)));
    } else {
      setAltMeetingDateTime('');
      setAltTimezone(0);
    }
    setCurrentInviteIdForAlt(inviteId);
    setShowProposeAltModal(true);
  };

  // New: Submit proposed alternative time using updateInvite with actionType "propose_alt"
  const submitProposeAlt = async () => {
    // Validate new date and timezone are set.
    if (!altMeetingDateTime || altTimezone === '') {
      showAlert("Please set both a new date and timezone for rescheduling.", "danger");
      return;
    }
    if (moment(altMeetingDateTime).isBefore(moment())) {
      showAlert("Meeting must be in the future.", "danger");
      return;
    }
    try {
      const padAlt = (num) => Math.abs(num).toString().padStart(2, '0');
      const timezoneAltStr = `${altTimezone >= 0 ? '+' : '-'}${padAlt(altTimezone)}:00`;
      const compositeAltTime = altMeetingDateTime + ":00" + timezoneAltStr;
      const res = await updateInvite(wallet, currentInviteIdForAlt, { 
        meetingDateTime: compositeAltTime, 
        actionType: "propose_alt" 
      });
      if (!res.success) { // NEW check response
        showAlert(res.error || "Failed to propose alternative time", "danger");
        return;
      }
      setShowProposeAltModal(false);
      setCurrentInviteIdForAlt(null);
      setAltMeetingDateTime('');
      setAltTimezone('');
      updateInvites();
      showAlert("Invitation rescheduled!", "success");
    } catch (err) {
      console.error("Error proposing alternative", err);
      showAlert(err.message || "Failed to propose alternative time", "danger");
    }
  };

  // Replace the previous allTimezones definition with:
  const uniqueTimezones = React.useMemo(() => {
    const offsets = new Set();
    const tzList = moment.tz.names();
    tzList.forEach(tz => {
      const offset = moment.tz(tz).utcOffset() / 60;
      offsets.add(offset);
    });
    const sortedOffsets = Array.from(offsets).sort((a, b) => a - b);
    return sortedOffsets.map(offset => {
      const offsetStr = `GMT${offset >= 0 ? '+' : ''}${offset}`;
      return { value: offset, label: offsetStr };
    });
  }, []);

  // UPDATED renderInvites() with GMT± formatting:
  const renderInvites = () => {
    if (!invitesGroups || invitesGroups.length === 0)
      return <p className="alert alert-warning">No scheduled P2P nor pending invites</p>;
      
    return invitesGroups.map(group => {
      // ...existing group header code...
      const groupHeaderStyle = {
        background: moment(new Date(group._id)).isSame(moment(), 'week') ? "#FFD700" : "#f1f1f1",
        color: "#333",
        padding: "4px",
        borderRadius: "4px",
        marginBottom: "10px",
        textAlign: "center",
        borderBottom: "1px solid #333"
      };
      return (
        <div key={group._id} className="mb-4">
          <div style={groupHeaderStyle}>
            Week {moment(new Date(group._id)).format("ddd Do MMM, YYYY")}
          </div>
          {group.invites.map(invite => {
            const friendPubKey = invite.creator === getPKHashFromAddress(myAddress) ? invite.receiver : invite.creator;
            // Use strict ISO parsing instead of parseZone:
            const mOriginal = moment.parseZone(fixTimezone(invite.meetingDateTime));
            if (!mOriginal.isValid()) {
              console.error("Invalid meetingDateTime:", invite.meetingDateTime);
            }
            const storedOffset = mOriginal.utcOffset() / 60;
            const offsetLabel = `GMT${storedOffset > 0 ? '+' + storedOffset : (storedOffset === 0 ? '' : storedOffset)}`;
            const originalTime = mOriginal.format("ddd Do MMM, YYYY @ HH:mm") + ` (${offsetLabel})`;
            
            const mLocal = mOriginal.clone().local();
            const localOffset = mLocal.utcOffset() / 60;
            const localOffsetLabel = `GMT${localOffset > 0 ? '+' + localOffset : (localOffset === 0 ? '' : localOffset)}`;
            const localTime = mLocal.format("ddd Do MMM, YYYY @ HH:mm") + ` (${localOffsetLabel})`;
            // Updated condition using moment for future check.
            const meetingDateIsFuture = mOriginal.isAfter(moment());
            return (
              <div 
                key={invite._id} 
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  marginBottom: "10px",
                  padding: "8px",
                  background: "#f8f9fa"
                }}
              >
                <div style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "4px" }}>
                  Local: {localTime}
                </div>
                {localTime !== originalTime && (  
                <div style={{ fontSize: "0.8rem", color: "#777", marginBottom: "6px" }}>
                  Original: {originalTime}
                </div>
                )}
                <div style={{ marginBottom: "6px" }}>
                  <div style={{ fontSize: "0.9rem", color: "#333" }}>
                    {invite.description || "No description"}
                  </div>
                  <div style={{ fontSize: "0.8rem", color: "#555" }}>
                    <NavLink to={`/profile/${getAddressFromPKHash(friendPubKey)}`} style={{ textDecoration: "none", color: "#007bff" }}>
                    {invite.friendUsername?invite.friendUsername:getAddressFromPKHash(friendPubKey)}
                    </NavLink>
                  </div>
                </div>
                <div>
                  {invite.status === "pending" && meetingDateIsFuture && (
                    <>
                      {invite.lastupdateby !== getPKHashFromAddress(myAddress) ? ( // Do not show Approve link if current user was lastupdateby
                        <><a 
                          onClick={() => handleInviteAction(invite._id, "approved")} 
                          style={{ marginRight: '10px', color: "#007bff", cursor: "pointer", textDecoration: "underline" }}
                        >
                          Approve
                        </a>
                        <a 
                        onClick={() => handleInviteAction(invite._id, "rejected")} 
                        style={{ marginRight: '10px', color: "#007bff", cursor: "pointer", textDecoration: "underline" }}
                        >
                        Can't Make It
                        </a>
                        </>
                      ):(

                        <a 
                        onClick={() => handleInviteAction(invite._id, "cancelled")} 
                        style={{ marginRight: '10px', color: "#007bff", cursor: "pointer", textDecoration: "underline" }}
                        >
                        Cancel
                        </a>

                      )}
                      
                      <a 
                        onClick={() => openProposeAltModal(invite._id)} 
                        style={{ marginRight: '10px', color: "#007bff", cursor: "pointer", textDecoration: "underline" }}
                      >
                        Reschedule
                      </a>
                    </>
                  )}
                  {invite.status === "approved" && meetingDateIsFuture && (
                    <a 
                      onClick={() => handleInviteAction(invite._id, "cancelled")} 
                      style={{ color: "#007bff", cursor: "pointer", textDecoration: "underline" }}
                    >
                      Cancel Meet
                    </a>
                  )}
                  {invite.status === "rejected" && (
                     <span style={{ color: "#6c757d" }}>
                     Couldn't make it
                   </span>
                  )}
                  {(invite.status === "cancelled") && (
                    <span style={{ color: "#6c757d" }}>
                      {invite.status.charAt(0).toUpperCase() + invite.status.slice(1)}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  // New functions to navigate weeks:
  const goPrevWeek = () => {
    const newStart = moment(startWeek).subtract(1, 'week').toISOString();
    setStartWeek(newStart);
  };

  const goNextWeek = () => {
    const newStart = moment(startWeek).add(1, 'week').toISOString();
    setStartWeek(newStart);
  };

  const handleTabClick = (tabName) => {
    if (activeTab === tabName) {
      if (tabName === "Chats") {
        updateConversations();
      } else if (tabName === "Schedule") {
        updateInvites();
      } else if (tabName === "Invites") {
        updatePendingInvites();
      }
    }
    setActiveTab(tabName);
  };

  const handleImportConversations = async (e) => {
    const files = Array.from(e.target.files);
    if (!files.length) return;
    const globalSignaler = initGlobalSignaler(wallet);
    for (const file of files) {
      const text = await file.text();
      try {
        const bytes = CryptoJS.AES.decrypt(text, wallet.privateKey);
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        if (!decrypted) throw new Error("Invalid backup");
        const importedConvs = JSON.parse(decrypted);
        Object.entries(importedConvs).forEach(([key, importedValue]) => {
          let importedMsgs = JSON.parse(importedValue);
          const existingValue = localStorage.getItem(key);
          let merged;
          if (existingValue) {
            let existingMsgs = JSON.parse(existingValue);
            merged = [...existingMsgs, ...importedMsgs].filter((msg, index, self) =>
              index === self.findIndex(m => m.id === msg.id)
            );
            merged.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
          } else {
            merged = importedMsgs;
          }
          localStorage.setItem(key, JSON.stringify(merged));
          const target = key.replace('conv_', '');
          globalSignaler.conversations[target] = merged;
        });
      } catch (err) {
        alert(`Import failed for file "${file.name}": invalid backup file or wrong key`);
      }
    }
    updateConversations();
    e.target.value = '';
    alert("Import completed");
  };

  return (
    <div className="m-0 p-3">
      <div className="conversations-container card m-0 p-0">
        <div className="card-header p-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="tabs-header">
            <button onClick={() => handleTabClick("Chats")} className={activeTab==="Chats" ? "active-tab p-1" : "p-1"}>Chats</button>
            <button onClick={() => handleTabClick("Schedule")} className={activeTab==="Schedule" ? "active-tab p-1" : "p-1"}>Schedule</button>
            <button onClick={() => handleTabClick("Invites")} className={activeTab==="Invites" ? "active-tab p-1" : "p-1"}>
              Invites {pendingCount > 0 && <span style={{backgroundColor: 'red', borderRadius: '50%', color: 'white', padding: '2px 6px', marginLeft: '5px', fontSize: '0.8rem'}}>{pendingCount}</span>}
            </button>
          </div>
          <div>
            <button 
              type="button" 
              className="btn btn-sm btn-outline-primary"
              onClick={() => { setInviteReceiver(friendAddress); setShowInviteModal(true); }}
              title="Invite P2P Chat"
            >
              <i className="fa fa-calendar"></i> <span className="d-none d-sm-inline">Schedule </span>P2P
            </button>
            
          </div>
        </div>
        <div className="card-body">
          {activeTab==="Chats" && (
            <>
              <div className="start-conversation mb-3">
                <form onSubmit={handleStartConversation}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="Friend's BSV address or @username"
                      value={friendAddress}
                      onChange={(e) => setFriendAddress(e.target.value)}
                      className="form-control mb-2"
                    />
                  </div>
                 <div className="d-flex justify-content-between align-items-center">
                    <button type="submit" className="btn btn-primary">
                        Start Conversation
                    </button>
                    <button 
                        type="button" 
                        className="btn btn-sm btn-outline-primary" 
                        title="Import Conversations" 
                        onClick={() => document.getElementById('importFileInput').click()}
                    >
                        <i className="fa fa-upload"></i> Import
                    </button>
                </div>
                  {/* Hidden file input */}
                  <input
                    id="importFileInput"
                    type="file"
                    style={{ display: 'none' }}
                    accept=".txt"
                    onChange={handleImportConversations}
                    multiple
                  />
                  
                </form>
              </div>
              <div className="sub-tabs-header" style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
                <button 
                  onClick={() => setChatSubTab("Conversations")}
                  className={chatSubTab==="Conversations" ? "active-sub-tab btn btn-outline-secondary btn-sm" : "sub-tab btn btn-outline-secondary btn-sm"}
                >
                  Conversations
                </button>
                <button 
                  onClick={() => setChatSubTab("Approvals")}
                  className={chatSubTab==="Approvals" ? "active-sub-tab btn btn-outline-secondary btn-sm" : "sub-tab btn btn-outline-secondary btn-sm"}
                >
                  Approvals
                </button>
              </div>
              {chatSubTab === "Conversations" ? (
                <ul className="conversations-list" style={{ marginTop: '10px' }}>
                  {conversations.length === 0 && <li className="conversation-item">No conversations yet.</li>}
                  {conversations.map((conv, idx) => (
                    <li key={idx} className={`conversation-item ${conv.unread ? 'unread-conversation' : ''}`}>
                      <NavLink to={`/message/${conv.address}`} className="conversation-link">
                        <div className="conversation-details">
                          <span className="conversation-address">{conv.displayName}</span>
                          <div className="conversation-snippet">{conv.lastMessage}</div>
                        </div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ) : (
                // Render the approved connections list.
                <div style={{ marginTop: '10px', border: '1px solid #ddd', borderRadius: '4px', padding: '15px' }}>
                  <h5 style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    Approved Connections
                    <button 
                      className="btn btn-outline-danger btn-sm" 
                      onClick={() => setShowClearApprovals(true)}
                    >
                      Clear All
                    </button>
                  </h5>
                  {Object.keys(approvedList).length === 0 ? (
                    <p>No approved addresses.</p>
                  ) : (
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                      {Object.entries(approvedList).map(([addr, info]) => (
                        <li key={addr} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          <span className='me-2 bg-light p-2 rounded border' onClick={()=>navigate('/profile/'+addr)} style={{ flexGrow: 1, cursor: 'pointer' }}>
                            {approvedUserMap[addr] ? `@${approvedUserMap[addr]}` :  addr.slice(0, 8) + '....' + addr.slice(-8)}
                          </span>
                          <label style={{ marginRight: '10px' }}>
                            <input
                              type="checkbox"
                              checked={info.active}
                              onChange={() => window.globalSignaler.updateApprovalStatus(addr, !info.active)}
                            /> Active
                          </label>
                          <button
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => window.globalSignaler.removeApproval(addr)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </>
          )}
          {activeTab==="Schedule" && (
            <>
              {/* Full width pagination for Schedule */}
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
                width: '100%'
              }}>
                <button className="btn btn-outline-secondary p-1" onClick={goPrevWeek}>
                  Back ({moment(startWeek).subtract(1, 'week').format("MMM D")})
                </button>
                <div className='border rounded p-1 bg-light me-2 ms-2' style={{ textAlign: 'center', flexGrow: 1 }}>
                  <strong>Weekly</strong>
                </div>
                <button className="btn btn-outline-secondary p-1" onClick={goNextWeek}>
                  Next ({moment(startWeek).add(weeksCount, 'weeks').format("MMM D")})
                </button>
              </div>
              {renderInvites()}
            </>
          )}
          {activeTab==="Invites" && (
            <>
              {/* Simple pagination for Pending Invites */}
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '10px'
              }}>
                <button className="btn btn-outline-secondary" disabled={pendingPage <= 1} onClick={() => setPendingPage(p => p - 1)}>
                  Prev
                </button>
                <div>{`Page ${pendingPage} of ${pendingTotalPages}`}</div>
                <button className="btn btn-outline-secondary" disabled={pendingPage >= pendingTotalPages} onClick={() => setPendingPage(p => p + 1)}>
                  Next
                </button>
              </div>
              <ul className="invites-list" style={{ listStyle: 'none', padding: 0 }}>
                {pendingInvites.length === 0 ? <div className='alert alert-warning'>No pending invites</div> :
                  pendingInvites.map(invite => {
                    const mOriginal = moment.parseZone(fixTimezone(invite.meetingDateTime));
                    if (!mOriginal.isValid()) { console.error("Invalid meetingDateTime:", invite.meetingDateTime); }
                    const storedOffset = mOriginal.utcOffset() / 60;
                    const offsetLabel = `GMT${storedOffset > 0 ? '+' + storedOffset : (storedOffset === 0 ? '' : storedOffset)}`;
                    const originalTime = mOriginal.format("MMM D, h:mm A") + ` (${offsetLabel})`;
                    const mLocal = mOriginal.clone().local();
                    const localOffset = mLocal.utcOffset() / 60;
                    const localOffsetLabel = `GMT${localOffset > 0 ? '+' + localOffset : (localOffset === 0 ? '' : localOffset)}`;
                    const localTime = mLocal.format("MMM D, h:mm A") + ` (${localOffsetLabel})`;
                    const meetingDateIsFuture = mOriginal.isAfter(moment());
                    return (
                      <li key={invite._id} style={{ border: "1px solid #ddd", borderRadius: "4px", marginBottom: "10px", padding: "8px", background: "#f8f9fa" }}>
                        <div style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "4px" }}>
                          Local: {localTime}
                        </div>
                        <div style={{ fontSize: "0.8rem", color: "#777", marginBottom: "6px" }}>
                          Original: {originalTime}
                        </div>
                        <div style={{ marginBottom: "6px" }}>
                          <div style={{ fontSize: "0.9rem", color: "#333" }}>
                            {invite.description || "No description"}
                          </div>
                          <div style={{ fontSize: "0.8rem", color: "#555" }}>
                          <NavLink to={`/profile/${(getAddressFromPKHash(invite.creator)==wallet.address ? getAddressFromPKHash(invite.receiver) : getAddressFromPKHash(invite.creator))}`} style={{ textDecoration: "none", color: "#007bff" }}>
                          {invite.friendUsername?invite.friendUsername:(getAddressFromPKHash(invite.creator)==wallet.address ? getAddressFromPKHash(invite.receiver) : getAddressFromPKHash(invite.creator))}
                          </NavLink>
                          </div>
                        </div>
                        <div>
                          {invite.status === "pending" && meetingDateIsFuture && (
                            <>
                              {invite.lastupdateby !== myAddress && (
                                <a onClick={() => handleInviteAction(invite._id, "approved")}
                                   style={{ marginRight: '10px', color: "#007bff", cursor: "pointer", textDecoration: "underline" }}>
                                  Approve
                                </a>
                              )}
                              <a onClick={() => handleInviteAction(invite._id, "rejected")}
                                 style={{ marginRight: '10px', color: "#007bff", cursor: "pointer", textDecoration: "underline" }}>
                                Reject
                              </a>
                              <a onClick={() => openProposeAltModal(invite._id)}
                                 style={{ color: "#007bff", cursor: "pointer", textDecoration: "underline" }}>
                                Reschedule
                              </a>
                            </>
                          )}
                        </div>
                      </li>
                    );
                  })
                }
              </ul>
            </>
          )}
        </div>
      </div>
      {showInviteModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Send Invite</h5>
                <button type="button" className="btn-close" onClick={() => setShowInviteModal(false)}></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <input 
                    type="text"
                    className="form-control" 
                    value={inviteReceiver} 
                    onChange={(e) => setInviteReceiver(e.target.value)}
                    placeholder="Friend's Address or @username" 
                    required 
                  />
                </div>
                {/* Combined Date/Time & Timezone inline */}
                <div className="mb-3">
                  <label className="form-label">Date &amp; Time:</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input 
                      type="datetime-local"
                      className="form-control"
                      value={meetingDateTime}
                      onChange={(e) => setMeetingDateTime(e.target.value)}
                      min={moment().format("YYYY-MM-DDTHH:mm")}
                      required
                      style={{ flexGrow: 1, marginRight: '10px' }}
                    />
                    <select 
                      className="form-select" 
                      value={selectedTimezone}
                      onChange={(e) => setSelectedTimezone(Number(e.target.value))}
                      style={{ width: 'auto' }}
                    >
                      {uniqueTimezones.map(tz => (
                        <option key={tz.label} value={tz.value}>{tz.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Remove separate timezone field */}
                <div className="mb-3">
                  <label className="form-label">Description (optional)</label>
                  <textarea 
                    className="form-control" 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Meeting details" 
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowInviteModal(false)}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={submitInvite}>Send Invite</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showProposeAltModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Propose Alternative Time</h5>
                <button type="button" className="btn-close" onClick={() => setShowProposeAltModal(false)}></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">New Date &amp; Time:</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={altMeetingDateTime}
                      onChange={(e) => setAltMeetingDateTime(e.target.value)}
                      min={moment().format("YYYY-MM-DDTHH:mm")}
                      required
                      style={{ flexGrow: 1, marginRight: '10px' }}
                    />
                    <select 
                      className="form-select"
                      value={altTimezone}
                      onChange={(e) => setAltTimezone(Number(e.target.value))}
                      required 
                      style={{ width: 'auto' }}
                    >
                      <option value="">Select Timezone</option>
                      {uniqueTimezones.map(tz => (
                        <option key={tz.value} value={tz.value}>{tz.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowProposeAltModal(false)}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={submitProposeAlt}>Submit Alternative</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showClearApprovals && (
        <OverlayConfirm
          confirmation={{ messages: ["Are you sure you want to clear all approvals?"] }}
          optionsMsg={{ confirm: "Yes, clear all", cancel: "Cancel" }}
          onComplete={(result) => {
            setShowClearApprovals(false);
            if (result.status) {
              window.globalSignaler.removeAllApprovals();
            }
          }}
        />
      )}
    </div>
  );
};

export default Conversations;


function fixTimezone(dateStr) {
  return dateStr.replace(/([+-])(\d{1,2})(:\d{2})$/, (_, sign, hour, minute) => {
    return `${sign}${hour.padStart(2, '0')}${minute}`;
  });
}
