import React, { useEffect, useState, useContext } from 'react';
import '../css/Transactions.css';
import { dynamicApiCall } from '../services/apiOutCalls';
import { AuthContext } from '../context/AuthProvider';

const Transactions = () => {
  // Remove local state for wallet and use AuthContext
  const { wallet } = useContext(AuthContext);
  const [pages, setPages] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [tokenPages, setTokenPages] = useState([]);
  const [tokenCurrentPageIndex, setTokenCurrentPageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [tokenLoading, setTokenLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("BSV");

  useEffect(() => {
    if (wallet) {
      fetchHistory(wallet);
    } else {
      setLoading(false);
    }
  }, [wallet]);

  const fetchHistory = async (walletData, pgkey = null) => {
    const address = walletData.address;
    try {
      const txns_per_page = 7;
      const query = pgkey 
        ? { action: "getTransactionHistory", address, pgkey, limit: txns_per_page }
        : { action: "getTransactionHistory", address, limit: txns_per_page };
      const result = await dynamicApiCall(walletData, query);
      const pageData = {
        history: result.data.history,
        pgkey: result.data.pgkey
      };
      setPages(prev => [...prev, pageData]);
    } catch (error) {
      console.error("Failed to fetch transaction history:", error);
    }
    setLoading(false);
  };

  const fetchTokenHistory = async (address) => {
    setTokenLoading(true);
    try {
      const limit = 10;
      const from = tokenPages.length * limit;
      const query = { action: "getTokenHistory", address, from, limit };
      const result = await dynamicApiCall(wallet, query);
      setTokenPages(prev => [...prev, result.data]);
    } catch (error) {
      console.error("Failed to fetch token history:", error);
    }
    setTokenLoading(false);
  };

  const handlePageClick = (index) => {
    setCurrentPageIndex(index);
  };

  const handleNextPage = () => {
    if (pages[currentPageIndex]?.pgkey) {
      setLoading(true);
      fetchHistory(wallet, pages[currentPageIndex].pgkey);
      setCurrentPageIndex(prev => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(prev => prev - 1);
    }
  };

  const handleTokenNextPage = () => {
    if (tokenPages[tokenCurrentPageIndex]?.length === 10) {
      if (!tokenPages[tokenCurrentPageIndex + 1]) {
        fetchTokenHistory(wallet.address);
      }
      setTokenCurrentPageIndex(prev => prev + 1);
    }
  };

  const handleTokenPrevPage = () => {
    if (tokenCurrentPageIndex > 0) {
      setTokenCurrentPageIndex(prev => prev - 1);
    }
  };

  const switchTab = (tab) => {
    setActiveTab(tab);
    if (tab === "TOKEN" && tokenPages.length === 0 && wallet) {
      fetchTokenHistory(wallet.address);
    }
  };

  const currentHistory = pages[currentPageIndex]?.history || [];
  const currentTokenHistory = tokenPages[tokenCurrentPageIndex] || [];

  return (
    <div className="m-0 p-3">
      <div className="home-container card m-0">
        <div className="tabs mb-3">
          <button 
            className={activeTab === "BSV" ? "btn btn-primary active-tab" : "btn btn-outline-primary"}
            onClick={() => switchTab("BSV")}
          >
            BSV Transactions
          </button>
          <button 
            className={activeTab === "TOKEN" ? "btn btn-primary active-tab" : "btn btn-outline-primary"}
            onClick={() => switchTab("TOKEN")}
          >
            Token Transactions
          </button>
        </div>
        
        {wallet ? (
          <div>
            <div className="wallet-info">
              <span className="wallet-label">Wallet Address:</span>
              <span className="wallet-address">{wallet.address}</span>
            </div>
            {activeTab === "BSV" ? (
              loading ? (
                <div className="loader">Loading...</div>
              ) : (
                <>
                  <div className="history-list">
                    {currentHistory.length > 0 ? (
                      currentHistory.map((tx, idx) => (
                        <div key={idx} className={tx.blockheight < 0 ? "tx-card pending" : "tx-card"}>
                          <div className="tx-row">
                            <span className="tx-label">TxID:</span>
                            <span className="tx-value">{tx.txid}</span>
                          </div>
                          <div className="tx-row">
                            <span className="tx-label">Input:</span>
                            <span className="tx-value">{tx.inputSatoshis}</span>
                          </div>
                          <div className="tx-row">
                            <span className="tx-label">Output:</span>
                            <span className="tx-value">{tx.outputSatoshis}</span>
                          </div>
                          <div className="tx-row">
                            <span className="tx-label">Time:</span>
                            <span className="tx-value">{new Date(tx.time * 1000).toLocaleString()}</span>
                          </div>
                          <div className="tx-row">
                            <span className="tx-label">Block:</span>
                            <span className="tx-value">{tx.blockheight < 0 ? "Pending" : tx.blockheight}</span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-history">No transaction history available</div>
                    )}
                  </div>
                  <div className="pagination-controls">
                    <button className="page-btn" onClick={handlePrevPage} disabled={currentPageIndex === 0}>
                      Previous
                    </button>
                    <button className="page-btn" onClick={handleNextPage} disabled={!pages[currentPageIndex]?.pgkey}>
                      Next
                    </button>
                  </div>
                </>
              )
            ) : (
              tokenLoading ? (
                <div className="loader">Loading...</div>
              ) : (
                <>
                  <div className="history-list">
                    {currentTokenHistory.length > 0 ? (
                      currentTokenHistory.map((tx, idx) => (
                        <div key={idx} className="tx-card">
                          <div className="tx-row">
                            <span className="tx-label">TxID:</span>
                            <span className="tx-value">{tx.txid}</span>
                          </div>
                          <div className="tx-row">
                            <span className="tx-label">Token ID:</span>
                            <span className="tx-value">{tx.tokenId}</span>
                          </div>
                          <div className="tx-row">
                            <span className="tx-label">Symbol:</span>
                            <span className="tx-value">{tx.symbol}</span>
                          </div>
                          <div className="tx-row">
                            <span className="tx-label">Input:</span>
                            <span className="tx-value">{tx.inputSatoshis}</span>
                          </div>
                          <div className="tx-row">
                            <span className="tx-label">Output:</span>
                            <span className="tx-value">{tx.outputSatoshis}</span>
                          </div>
                          <div className="tx-row">
                            <span className="tx-label">Time:</span>
                            <span className="tx-value">{new Date(tx.time * 1000).toLocaleString()}</span>
                          </div>
                          <div className="tx-row">
                            <span className="tx-label">Block:</span>
                            <span className="tx-value">{tx.blockheight < 0 ? "Pending" : tx.blockheight}</span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-history">No token transaction history available</div>
                    )}
                  </div>
                  <div className="pagination-controls">
                    <button className="page-btn" onClick={handleTokenPrevPage} disabled={tokenCurrentPageIndex === 0}>
                      Previous
                    </button>
                    <button className="page-btn" onClick={handleTokenNextPage} disabled={currentTokenHistory.length < 10}>
                      Next
                    </button>
                  </div>
                </>
              )
            )}
          </div>
        ) : (
          <div className="no-wallet">
            <p>No wallet found. Please authenticate.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Transactions;