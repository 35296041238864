import React from 'react';
import '../css/InfoPages.css';
import { QRCodeCanvas } from 'qrcode.react';
import { ConfigContext } from '../config';

const Donate = () => {
  const config = React.useContext(ConfigContext);
  return (
    <div className="info-page">
      <p>
        Your support fuels {config.BRAND_NAME}—a platform where you can express your creativity, ideas, and passions. Here, you own everything you post, seamlessly connect with others, and monetize where possible. Your generous support helps us improve features, detect mistakes, and enhance the overall experience.
      </p>
      <section>
        <h2>Support The Mission</h2>
        <p>
          In this early stage, we keep fees low (or even free) so everyone can try and help us refine {config.BRAND_NAME}. By donating, you become a valued collaborator in building Internet 3.0—a future where you are free to express, own, and share your ideas, all while enjoying a seamless payments experience. To put into perspective, currently 0.10 BSV is merely a cup of coffee (as of 12/03/2025 - let's see how this ages), yet it would be a significant contribution to the mission.
        </p>
      </section>
      <section style={{ textAlign: 'center', marginTop: '20px' }}>
        <h2>Scan to Donate</h2>
        <p>($BSV, MNEE $USD)</p>
        <QRCodeCanvas text={config.DONATIONS_WALLET} />
        <p style={{ marginTop: '10px' }}>
          <strong>{config.DONATIONS_WALLET}</strong>
          
        </p>
      </section>
      <section style={{ marginTop: '20px' }}>
        <p>
          Join us in revolutionizing the internet. Your support helps {config.BRAND_NAME} grow into a robust ecosystem where you can freely express your creativity and connect with like-minded individuals. Thank you for your generous support!
        </p>
      </section>
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">A Bright Future</h5>
          <p className="card-text">
            Although we make no promises, contributors may be pleasantly surprised by our evolving platform. Please ensure you keep a secure backup of your wallet keys, as the keys you donate with might prove valuable in the future. We appreciate and value every bit of support.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Donate;
