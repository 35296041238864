import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // replaced useHistory with useNavigate
import devApiService from '../services/devApiService';
import { AuthContext } from '../context/AuthProvider';
import '../css/AppEdit.css'; // added css import
// ...existing imports...

const EditApp = () => {
  const { appId } = useParams();
  const navigate = useNavigate(); // useNavigate hook instead of useHistory
  const { wallet } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    appName: '',
    appIcon: '',
    appCover: '',
    category: ''
  });
  const [packageFile, setPackageFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  // Load app details
  useEffect(() => {
    if (wallet && appId) {
      devApiService.findApp(wallet, appId)
        .then(response => {
          if(response.success && response.app) {
            const app = response.app;
            setFormData({
              appName: app.appName || '',
              appIcon: app.appIcon || '',
              appCover: app.appCover || '',
              category: app.category || ''
            });
          } else {
            setMessage('Error fetching app data.');
          }
          setLoading(false);
        })
        .catch(() => {
          setMessage('Error fetching app data.');
          setLoading(false);
        });
    }
  }, [wallet, appId]);

  const handleChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handlePackageFileUpload = (e) => {
    const file = e.target.files[0];
    if(file) setPackageFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data;
    if(packageFile) {
      
    data = new FormData();
    data.append('appName', formData.appName);
    data.append('appIcon', formData.appIcon);
    data.append('appCover', formData.appCover);
    data.append('category', formData.category);
      data.append('packageFile', packageFile);
    } else {
      data = {
        appName: formData.appName,
        appIcon: formData.appIcon,
        appCover: formData.appCover,
        category: formData.category
      };
    }

    devApiService.submitAppUpdate(wallet, appId, data)
      .then(response => {
        if(response.success) {
          setMessage('Update request submitted successfully.');
          // Optionally redirect back after a delay:
          setTimeout(() => navigate('/dev-apps/manage'), 1500);
        } else {
          setMessage('Failed to submit update request.');
        }
      })
      .catch(() => setMessage('Failed to submit update request.'));
  };

  if(loading) return <div>Loading app data...</div>;

  return (
    <div className="app-container p-3">
      <div className="card appedit-card">
        <h2 className="appedit-title">Edit App</h2>
        { message && <div>{message}</div> }
        <form onSubmit={handleSubmit} className="appedit-form">
          <div className="form-group mb-2 appedit-form-group">
            <label>App Name</label>
            <input
              type="text"
              value={formData.appName}
              onChange={(e) => handleChange('appName', e.target.value)}
              className="form-control appedit-form-control"
            />
          </div>
          <div className="form-group mb-2 appedit-form-group">
            <label>Icon URL</label>
            <input
              type="text"
              value={formData.appIcon}
              onChange={(e) => handleChange('appIcon', e.target.value)}
              className="form-control appedit-form-control"
            />
          </div>
          <div className="form-group mb-2 appedit-form-group">
            <label>Cover URL</label>
            <input
              type="text"
              value={formData.appCover}
              onChange={(e) => handleChange('appCover', e.target.value)}
              className="form-control appedit-form-control"
            />
          </div>
          <div className="form-group mb-2 appedit-form-group">
            <label>Category</label>
            <input
              type="text"
              value={formData.category}
              onChange={(e) => handleChange('category', e.target.value)}
              className="form-control appedit-form-control"
            />
          </div>
          <div className="form-group mb-2 appedit-form-group">
            <label>Package File (optional)</label>
            <input 
              type="file" 
              accept=".html,.htm,.js" 
              onChange={handlePackageFileUpload} 
              className="form-control"
            />
          </div>
          <button type="submit" className="btn btn-primary appedit-submit-btn">
            Submit Update Request
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditApp;
