import React, { useEffect, useState, useContext } from 'react';
import devApiService from '../services/devApiService';
import { AuthContext } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom'; // replaced useHistory with useNavigate
import '../css/DeveloperApps.css'; // new CSS import

const DeveloperApps = () => {
  const [apps, setApps] = useState([]);
  const [updateSubmissions, setUpdateSubmissions] = useState([]);
  const [selectedTab, setSelectedTab] = useState('apps'); // 'apps' or 'updates'
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const { wallet } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (wallet) {
      if(selectedTab === 'apps'){
        devApiService.fetchAppsDev(wallet)
          .then(response => {
            if (response.success) {
              setApps(response.apps);
            } else {
              setMessage('Error fetching apps');
            }
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      } else if(selectedTab === 'updates'){
        // New API call: fetchMyUpdateSubmissions should return submissions with a status of pending or rejected
        devApiService.fetchMyUpdateSubmissions(wallet)
          .then(response => {
            if(response.success) {
              setUpdateSubmissions(response.updateRequests);
            } else {
              setMessage('Error fetching update submissions');
            }
          })
          .catch(() => setMessage('Error fetching update submissions'));
      }
    }
  }, [wallet, selectedTab]);

  const handleSubmitUpdate = (appId) => {
    navigate(`/dev-apps/edit/${appId}`);
  };

  const handlePauseToggle = (appId) => {
    devApiService.pauseToggleApp(wallet, appId)
      .then(response => {
        if(response.success) {
          // Update the local state with the new paused status returned from the endpoint.
          setApps(prevApps => prevApps.map(app => 
            app._id === appId ? { ...app, paused: response.paused } : app
          ));
        } else {
          alert(response.error || 'Toggle failed.');
        }
      })
      .catch(() => alert('Toggle failed.'));
  };

  const handleDelete = (appId) => {
    devApiService.deleteApp(wallet, appId)
      .then(() => setApps(apps.filter(app => app._id !== appId)))
      .catch(() => setMessage('Delete failed.'));
  };

  if (loading) return <div className="developer-apps-loading">Loading apps...</div>;

  return (
    <div className="developer-apps">
      <h1>Your Apps</h1>
      {/* New Tabs */}
      <div className="tabs mb-3">
        <button 
          className={`btn ${selectedTab === 'apps' ? 'btn-primary' : 'btn-outline-primary'} mx-1`}
          onClick={() => setSelectedTab('apps')}
        >
          My Apps
        </button>
        <button 
          className={`btn ${selectedTab === 'updates' ? 'btn-primary' : 'btn-outline-primary'} mx-1`}
          onClick={() => setSelectedTab('updates')}
        >
          My Update Submissions
        </button>
      </div>

      { message && <div className="developer-apps-message">{message}</div> }

      { selectedTab === 'apps' && (
        <>
          { apps.map(app => (
            <div key={app._id} className="card app-card shadow-sm" style={{ position: 'relative', overflow: 'hidden' }}>
              {/* Cover image as card background */}
              <img 
                src={app.appCover} 
                alt={`${app.appName} Cover`} 
                style={{ width: '100%', height: '200px', objectFit: 'cover' }} 
              />
              {/* Overlay container with semi-transparent white background */}
              <div className="card-overlay" style={{ 
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                padding: '10px',
                backgroundColor: 'rgba(255,255,255,0.9)',
                borderBottom: '1px solid #ccc'
              }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img 
                    src={app.appIcon} 
                    alt={`${app.appName} Icon`} 
                    style={{ width: '60px', height: '60px', objectFit: 'cover', borderRadius: '4px' }} 
                  />
                  <div>
                    <h3 style={{ margin: '0 0 5px 0' }}>{app.appName}</h3>
                    <p style={{ margin: 0 }}><strong>Category:</strong> {app.category}</p>
                    <p style={{ margin: 0 }}><strong>Status:</strong> {app.reviewStatus || 'None'}</p>
                  </div>
                </div>
                <div className="button-group" style={{ marginTop: '10px' }}>
                  <button onClick={() => handleSubmitUpdate(app._id)}>Submit Update Request</button>
                  <button onClick={() => handlePauseToggle(app._id)}>
                    {app.paused ? 'Unpause' : 'Pause'}
                  </button>
                  <button onClick={() => handleDelete(app._id)}>Delete</button>
                </div>
              </div>
            </div>
          ))}
        </>
      )}

      { selectedTab === 'updates' && (
        <div className="card shadow-sm">
          <div className="card-body">
            <h2 className="mb-3">My Update Submissions</h2>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>App ID</th>
                  <th>Status</th>
                  <th>Submitted At</th>
                  <th>Rejection Reason</th>
                </tr>
              </thead>
              <tbody>
                {updateSubmissions.map(sub => (
                  <tr key={sub._id}>
                    <td>{sub.appId}</td>
                    <td>{sub.status}</td>
                    <td>{new Date(sub.createdAt).toLocaleString()}</td>
                    <td>{sub.status==='rejected' ? (sub.rejectionReason || 'No reason provided') : '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeveloperApps;
