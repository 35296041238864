import React, { createContext, useState, useEffect } from 'react';

export const ConfigContext = createContext(null);

export const config = {
  ACCEPTABLE_DUST_FEE: 20,
  API_URL:'https://api.metanet.ninja',
	SIGNALING_URL: "wss://signaler.metanet.ninja/ws/signaling",
  API_APPS: "/api/apps",
	API_INVITES: "/api/invites",
	API_APPS_DEV: "/api/apps/dev",
	API_APPS_ADMIN: "/api/apps/admin",
	API_IPFS_UPLOAD: "/ipfs/upload",
}


export const ConfigProvider = ({ children }) => {
  const [contextConfig, setContextConfig] = useState(null);

  useEffect(() => {
    async function fetchConfig() {
      try {
        const res = await fetch(config.API_URL+'/config');
        const data = await res.json();
        if (data.success){setContextConfig(data);}
      } catch (err) {
        console.error("Failed to fetch config:", err);
      }
    }
    // Initial fetch
    fetchConfig();
    
    // Re-fetch config every 5 minutes (300000ms)
    const intervalId = setInterval(() => {
      fetchConfig();
    }, 300000);
    
    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  if (!contextConfig)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: '#f8f9fa'
        }}
      >
        <div
          className="spinner-border"
          role="status"
          style={{ width: '3rem', height: '3rem' }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
        <h2 style={{ marginTop: '20px', color: '#6c757d' }}>
          Loading configuration…
        </h2>
      </div>
    );

  return (
    <ConfigContext.Provider value={contextConfig}>
      {children}
    </ConfigContext.Provider>
  );
};

